import React, { useState } from 'react';
import './AccordionSection.css';
import { useTranslation } from 'react-i18next';

const AccordionSection: React.FC = () => {
    const { t } = useTranslation()
    const [openAccordions, setOpenAccordions] = useState<{ [key: number]: boolean }>({});

    const toggleAccordion = (index: number) => {
        setOpenAccordions(prevOpenAccordions => ({
            ...prevOpenAccordions,
            [index]: !prevOpenAccordions[index]
        }));
    };

    const accordionData = [
        { title: "home.actionCards.firstItem.actionDataTitle", content: "home.actionCards.firstItem.actionDataContent" },
        { title: "home.actionCards.secondItem.actionDataTitle", content: "home.actionCards.secondItem.actionDataContent" },
        { title: "home.actionCards.thirdItem.actionDataTitle", content: "home.actionCards.thirdItem.actionDataContent" },
        // Add more accordion data here
    ];

    return (
        <div className="accordion-section">
            <h2 className="accordion-title">{t("home.accordingSectionTitle")}</h2>
            {accordionData.map((item, index) => (
                <div key={index} className="accordion-item" onClick={() => toggleAccordion(index)}>
                    <h3 className="accordion-header">
                        <span>{t(item.title)}</span>
                        <span className="accordion-arrow">{openAccordions[index] ? '↑' : '↓'}</span>
                    </h3>
                    {openAccordions[index] && (
                        <div className="accordion-content">
                            {t(item.content)}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AccordionSection;
