import { useTranslation } from 'react-i18next';
import './ConfirmModal.css';

interface ConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

const ConfirmModal = ({ isOpen, onClose, onConfirm, message }: ConfirmModalProps) => {
    const { t: translate } = useTranslation();
 
    return (
        <div className={`confirm-modal ${isOpen ? 'show' : ''}`}>
            <div className="confirm-modal-content">
            <p>{translate(message)}</p>
            <div className="button-container">
                <button id='cancel' className="cancel-button" onClick={onClose}>
                    {translate('privateArea.bookingDetails.cancel')}
                </button>
                <button id='confirm' className="confirm-button" onClick={onConfirm}>
                    {translate('privateArea.bookingDetails.confirm')}
                </button>
            </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
