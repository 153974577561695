import axios from 'axios';
import { API_BASE_URL, customFetch } from '../api/axios';
import { CareGiverType } from '../components/BookingForm/formSteps/careGiver';

export const createBooking = async (
  travelPackageId: string,
  travelId: string,
  travelOptionIds: string[],
  beneficiaryID: string,
  caregivers: CareGiverType[],
  beneficiaryStartWith: string,
  voucherId?: string,
  document: string = 'idcard',
) => {
  try {
    const response = await customFetch.post('/booking', {
      travelPackageId,
      travelId,
      travelOptionIds,
      travelers: [
        { id: beneficiaryID, startWith: beneficiaryStartWith, voucherId, document, caregivers},
      ],
      bookingState: "Requested"
    });

    if (response && response?.status === 200) {
      return true;
    } else {
      console.error('Network response was not ok');
      return false;
    }
  } catch (error) {
    console.error("Error creating booking:", error);
    throw error; // Re-throw the error to be handled by the calling code
  }
};

export const getUserBookings = async () => {
  try {
    const response = await customFetch.get('/booking/list');
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export type BookingStatusType = 'None' | 'Draft'  | 'Requested' | 'Failed' | 'Refused' | 'Deleted' | 'WaitingForUserAction' | 'ApprovedByAgency' | 'ApprovedByUser' | 'Completed';

export type BookingStatusPayload = {
  bookingState: BookingStatusType;
  bookingId: string;
  caregivers: CareGiverType[];
}

export const updateBookingStatus = async ({bookingState, bookingId, caregivers}: BookingStatusPayload) => {
  try {
    const response = await customFetch.put('/booking', {
      bookingState,
      bookingId,
      caregivers
    });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export type BookingCaregiversPayload = {
  caregivers: CareGiverType[];
  bookingId: string;
}

export const updateBookingCaregivers = async ({caregivers, bookingId}: BookingCaregiversPayload) => {
  try {
    const response = await customFetch.put('/booking', {
      caregivers,
      bookingId
    });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const getBookingById = async (bookingId: string) => {
  try {
    const response = await customFetch.get(`/booking?id=${bookingId}&language=it`);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

type PaymentMethodType = {
  bookingId: string;
  paymentMethod?: string;
  installmentsNumber: number;
}

export const createPaymentMethod = async (parameters: PaymentMethodType) => {
  try {
    const response = await customFetch.post('/booking/set-payment-method', {
      ...parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

type UploadContractType = {
  formData: FormData;
  token?: string;
}

export const uploadContract = async ({formData, token}: UploadContractType) => {
  try {
    const response = axios.post(`${API_BASE_URL}/booking/upload-signed-contract`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
      })
    return response;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

type SignedContract = {
  signedContractUri: string;
  accessToken: string;
}

export const getSignedContract = ({signedContractUri, accessToken}: SignedContract) => {
  axios.get(signedContractUri, {
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    }
  })
    .then((response) => {
      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let filename = 'downloaded_file';

      if (contentDisposition && filenameRegex.test(contentDisposition)) {
        filename = contentDisposition.match(filenameRegex)[1];
        filename = filename.replace(/['"]/g, ''); // Remove quotes if any
      }

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
    }).catch((error) => console.log(error));
}

type TGetInvoicePDF = {
  invoiceUrl: string;
  accessToken: string;
}

export const getInvoicePDF = ({invoiceUrl, accessToken}:TGetInvoicePDF) => {
    axios.get(invoiceUrl, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    })
      .then((response) => {
        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let filename = 'downloaded_file';
  
        if (contentDisposition && filenameRegex.test(contentDisposition)) {
          filename = contentDisposition.match(filenameRegex)[1];
          filename = filename.replace(/['"]/g, ''); // Remove quotes if any
        }
  
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }).catch((error) => console.log(error));
  }

  export const getInvoiceNumber = async (bookingId: string) => {
    try {
      const response = await customFetch.get(`/booking/get-invoice-number/${bookingId}`);
      const responseData = await response.data;
  
      return responseData;
    } catch (error) {
    }
};

type UploadInvoiceType = {
    data: any,
    token?: string,
    bookingId: string,
}

export const uploadInvoice = async ({data, token, bookingId}: UploadInvoiceType) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/booking/upload-invoice?bookingId=${bookingId}`, data, {
        headers: {
          'Content-Type': 'text/plain; charset=utf-8',
          'Authorization': `Bearer ${token}`
        }
        })
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
};
