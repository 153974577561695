import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdentityDocument } from "./childForm";
import { getDatabaseFormatedDate, getFormatedDate } from "../../../utils/date";

export type IdentityType = 'idcard' | 'passport';

export type CareGiverType = {
    name: string;
    surname: string;
    fiscalCode: string;
    identityDocuments: IdentityDocument;
}

type OmitA = Omit<CareGiverType, "identityDocuments">;
type CaregiverKeyType = keyof OmitA;
type CaregiverDocumentKeyType = keyof IdentityDocument;

interface CareGiverProps {
    index: number;
    data: CareGiverType;
    onCaregiverDataChange: (object: CareGiverType) => void;
}

const CareGiverForm = ({index, data, onCaregiverDataChange}: CareGiverProps) => {
    const { t: translate } = useTranslation();
    const [caregiverData, setCaregiverData] = useState(data);

    useEffect(() => {
        onCaregiverDataChange(caregiverData);
    }, [caregiverData])

    const onValueChange = (name: CaregiverKeyType, value: string) => {
        setCaregiverData({ ...caregiverData, [name]: value });
    }

    const onDocumentValueChange = (name: CaregiverDocumentKeyType, value: string) => {
        setCaregiverData({ ...caregiverData, identityDocuments: { ...caregiverData.identityDocuments, [name]: value } });
    }

    return (
        <React.Fragment key={index}>
            <div className="col-6 pb-6 column">
                <label className="input-label">{translate("form.secondStepForm.name")}*</label>
                <input className="field input-field" type="text"
                    placeholder={translate("form.secondStepForm.name")} required
                    value={caregiverData.name} onChange={({target: {value}}) => onValueChange('name', value)}  />
            </div>
            <div className="col-6 pb-6 column">
                <label className="input-label">{translate("form.secondStepForm.surname")}*</label>
                <input className="field input-field" type="text"
                    placeholder={translate("form.secondStepForm.surname")} required
                    value={caregiverData?.surname} onChange={({target: {value}}) => onValueChange('surname', value)}  />
            </div>
            <div className="col-12 pb-6">
                <div className="column">
                    <label className="input-label">{translate("form.firstStepForm.fiscalCode")}</label>
                    <input className="field input-field" type="fiscalCode" name="fiscalCode" placeholder={translate("form.firstStepForm.fiscalCode")}
                        value={caregiverData.fiscalCode} onChange={({target: {value}}) => onValueChange('fiscalCode', value)}  required/>
                </div>
            </div>

            <div className="col-12 d-flex mx-8 pb-6">
                <h4 className="identity-card">{translate('form.firstStepForm.idCard')}</h4>
            </div>

            <div className="col-6 pb-6">
                <div className="column">
                    <label className="input-label">{translate("form.firstStepForm.identityDocumentId")}*</label>
                    <input className="field input-field" type="text" name="identityDocumentId"
                        placeholder={translate("form.firstStepForm.identityDocumentId")}
                        value={caregiverData?.identityDocuments?.identityDocumentId ?? ""} required
                        onChange={({ target: { value} }) => onDocumentValueChange('identityDocumentId', value)}  />
                </div>
            </div>

            <div className="col-6 pb-6">
                <div className="column">
                    <label className="input-label">{translate("form.firstStepForm.validUntilDate")}*</label>
                    <input
                        type="date"
                        className="field input-field"
                        name="validUntilDate" required
                        placeholder={translate("form.firstStepForm.validUntilDate")}
                        value={getFormatedDate(caregiverData?.identityDocuments?.validUntilDate ?? '')}
                        onChange={({ target: { value} }) => onDocumentValueChange('validUntilDate', getDatabaseFormatedDate(value))}
                    />
                </div>
            </div>

            <div className="col-12 pb-6">
                <div className="column">
                    <label className="input-label">{translate("form.secondStepForm.releasedBy")}*</label>
                    <input className="field input-field" type="text" name="releasedBy" placeholder={translate("form.secondStepForm.releasedBy")} required
                        value={caregiverData?.identityDocuments?.releasedBy ?? ""}
                        onChange={({ target: { value} }) => onDocumentValueChange('releasedBy', value)} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default CareGiverForm;
