import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UserLogo from "../../../assets/user_logo.png"
import { createUser, getUserById, updateUser } from "../../../services/userService";
import { deepClone } from "../../../utils/object";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { getDatabaseFormatedDate, getFormatedDate } from "../../../utils/date";
import Spinner from "../../Spinner/spinner";
import { isStringDefined } from "../../../utils/string";
import CareGiverForm, { CareGiverType } from "./careGiver";
import { AiOutlineDelete } from "react-icons/ai";

export type IdentityDocument = {
    identityDocumentId: string;
    validUntilDate: string;
    identityDocumentType: 'idcard' | 'passport' | string;
    releasedBy: string;
    releasedDate?: string;
    nameOnDocument?: string;
    surnameOnDocument?: string;
}

type ChildData = {
    id?: string;
    name: string;
    surname: string;
    email: string;
    username?: string;
    gender: string;
    address: string;
    province: string;
    city?: string;
    mobile?: string;
    destination?: string;
    dateOfBirth: string;
    birthPlace?: string;
    startWith: string;
    fiscalCode: string;
    additionalInfo?: string;
    allergies?: string;
    disability: number;
}

export type ChildFormData = ChildData & { identityDocuments: IdentityDocument };

export const childInitialState: ChildData = {
    id: "",
    name: '',
    surname: '',
    email: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    province: '',
    birthPlace: '',
    startWith: '',
    fiscalCode: '',
    disability: 0,
}

export const documentInitialState: IdentityDocument = {
    identityDocumentId: '',
    validUntilDate: '',
    identityDocumentType: 'idcard',
    releasedBy: '',
    releasedDate: '',
    nameOnDocument: '',
    surnameOnDocument: ''
}

export const initialCaregiverData: CareGiverType = {
    name: '',
    surname: '',
    fiscalCode: '',
    identityDocuments: {
        identityDocumentId: '',
        validUntilDate: '',
        releasedBy: '',
        identityDocumentType: 'idcard',
    }
}

interface ChildFormProps {
    childInfo: ChildFormData;
    userInfo: any;
    isEditForm?: boolean;
    onPreviousClick?: (item: ChildFormData) => void;
    onNextClick: (item: ChildFormData, caregivers: CareGiverType[]) => void;
    onEditClick?: () => void;
}

const ChildForm = ({
    childInfo,
    userInfo,
    isEditForm,
    onPreviousClick,
    onNextClick,
    onEditClick
}: ChildFormProps) => {
    const { t: translate } = useTranslation();
    const [childData, setChildData] = useState<ChildData>(childInfo)
    const [identityDocuments, setIdentityDocuments] = useState<IdentityDocument>(childInfo?.identityDocuments)
    const [newChildAction, setNewChildAction] = useState({
        isNewChild: childData.name || isEditForm? false : true,
        isLoading: false
    });
    const [ hasDateOfBirthError, setHasDateOfBirthError] = useState<boolean>(false)
    const userInfoRef = useRef(userInfo);
    const [areDataChanged, setAreDataChanged] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState({
        isLoading: false,
        childId: -1
    });
    const [childButtonClicked, setChildButtonClicked] = useState(false);
    const [caregivers, setCaregivers] = useState<CareGiverType[]>([]);

    useEffect(() => {
        userInfoRef.current = userInfo;
    }, [userInfo])

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (event && event.target) {
            console.log(event.target)
            setAreDataChanged(true);
            const inputName = event.target.name as keyof ChildData;
            setChildData({...childData, [inputName]: event.target.value})
        }
    }

    const onDocumentChange = (name: string, value: string) => {
        setAreDataChanged(true);
        setIdentityDocuments({...identityDocuments, [name]: value})
    }

    const onSelectChild = (child: ChildData, index: number) => {
        if (child && child.id) {
            setLoadingInfo({
                isLoading: true,
                childId: index
            })

            let childInfo = child;
            getUserById(child.id).then((response) => {
                if (response) {
                    childInfo = {...response, dateOfBirth: getFormatedDate(response.dateOfBirth)};
                }
                setNewChildAction({...newChildAction, isNewChild: false});
                setLoadingInfo({
                    isLoading: false,
                    childId: -1
                })
                setIdentityDocuments(response.identityDocuments &&
                    response.identityDocuments.length? {...response.identityDocuments[response.identityDocuments.length - 1],
                    validUntilDate: getFormatedDate(response.identityDocuments[response.identityDocuments.length - 1].validUntilDate)} : {})
    
                setChildData({...childInfo});
                }).catch(() => {
                    setLoadingInfo({
                        isLoading: false,
                        childId: -1
                    })
            })
        }
    }

    const onAddNewChildClick = () => {
        setNewChildAction({...newChildAction, isNewChild: true});
        setAreDataChanged(false);
        const newUserChild = deepClone(childInitialState);
        setChildData(newUserChild);
        setIdentityDocuments(documentInitialState);
    }

    const setChildToUser = (newChild: any) => {
        const currentUser = JSON.parse(localStorage.getItem('userInfo') ?? '');
        
        if (currentUser && currentUser?.children) {
            currentUser['children'].push(newChild);
            userInfo?.children?.push(newChild);
            localStorage.setItem('userInfo', JSON.stringify(currentUser));
        }
    }

    const addNewCaregiver = () => {
        let newArray = [...caregivers];
        newArray.push(initialCaregiverData);
        setCaregivers(newArray)
    }

    const deleteCaregiver = (index: number) => {
        setCaregivers(caregivers => caregivers.filter((item, i) => (i != index)))
    }

    const onCaregiverDataChange = (object: CareGiverType, index: number) => {
        let newArray = [...caregivers];
        newArray[index] = object; 

        setCaregivers(newArray);
    }

    const getBeneficaryEmail = useMemo(() => {
        return childData?.email === userInfo.email || childData.email === '' ? null : childData.email
    }, [userInfo.email, childData.email])

    const onSaveNewChildClick = (event: FormEvent) => {
        event.preventDefault();
        try {
            setNewChildAction({isNewChild: true, isLoading: true});
            createUser({
                ...childData,
                username: getBeneficaryEmail,
                email: getBeneficaryEmail,
                gender: childData.gender === "2" ? 2 : 1,
                fiscalCode: childData.fiscalCode ?? '',
                dateOfBirth: getDatabaseFormatedDate(childData.dateOfBirth),
                city: childData.city ?? '',
                identityDocuments: [{
                    ...identityDocuments,
                    validUntilDate: getDatabaseFormatedDate(identityDocuments.validUntilDate),
                    identityDocumentType: 'idcard',
                }]
            }).then((response) => {
                if (response) {
                    setChildToUser(response);
                    setChildData({...response, dateOfBirth: getFormatedDate(response.dateOfBirth)});

                    const newIdentityDocument = response.identityDocuments && response.identityDocuments.length > 0 ?
                        response.identityDocuments[response.identityDocuments.length - 1] : documentInitialState;

                    setIdentityDocuments({
                        ...newIdentityDocument,
                        validUntilDate: getFormatedDate(newIdentityDocument.validUntilDate)
                    });

                    toast.success(translate('form.secondStepForm.succesMessage'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setNewChildAction({isNewChild: true, isLoading: false});
            });
        } catch (error) {
            setNewChildAction({isNewChild: true, isLoading: false});
            console.error("Error creating user:", error);
            throw error;
        }
    }

    const onNextStepClick = (event: FormEvent) => {
        event.preventDefault();
        setHasDateOfBirthError(!childData.dateOfBirth);

        if (childData.dateOfBirth) {
            if (areDataChanged && !newChildAction.isNewChild) {
                updateUser({
                    ...childData,
                    username: childData.username ?? '',
                    gender: childData.gender === "2" ? 2 : 1,
                    fiscalCode: childData.fiscalCode ?? '',
                    dateOfBirth: getDatabaseFormatedDate(childData.dateOfBirth),
                    city: childData.city ?? '',
                    identityDocuments: [{
                        ...identityDocuments,
                        validUntilDate: getDatabaseFormatedDate(identityDocuments.validUntilDate),
                        identityDocumentType: 'idcard',
                }]}).then((response) => {
                    if (response) {
                        toast.success(translate('shared.updateMessage'), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        onNextClick({ ...childData, identityDocuments }, caregivers);
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                onNextClick({ ...childData, identityDocuments }, caregivers);
            }
            onNextClick({ ...childData, identityDocuments }, caregivers);
        }
    }

    const onDisabilityChange = () => {
        setAreDataChanged(true);
        const isChecked = childData.disability === 0? 1 : 0;
        setChildData({...childData, disability: isChecked })
    }

    return (
        <form className="personal-information-form"
            onSubmit={(event) => ((!newChildAction.isNewChild || childData?.id) || isEditForm) ? onNextStepClick(event) : onSaveNewChildClick(event)}>
            {!isEditForm && <h3>{translate("form.secondStepForm.title")}</h3>}
            {
                !isEditForm &&
                <>
                    <div className='persons-wrapper justify-center'>
                        <div className="row justify-center">
                            {userInfo?.children?.map((child: ChildData, index: number) => {
                                return(
                                    <div key={`${child.id}-${index}`} className="col-4 mx-8" onClick={() => {
                                        setChildButtonClicked(true);
                                        return onSelectChild(child, index);
                                    }}>
                                        <div className="add-user-button">
                                            <img src={UserLogo} alt='user-logo'/>
                                            {child?.username}
                                            <Spinner isLoading={loadingInfo.isLoading && loadingInfo.childId === index} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="add-user-button background-blue row col-4 justify-center" onClick={() => {
                                setChildButtonClicked(true);
                                return onAddNewChildClick();
                            }}>
                            <FaPlus strokeWidth={2}/>
                            <div style={{ paddingLeft: '8px' }}>
                                {translate('form.secondStepForm.addNewChild')} 
                            </div>
                        </div>
                        <div className="child-info justify-center">
                            <span>{translate("form.secondStepForm.clickButtonMessage")}</span>
                            <span>{translate("form.secondStepForm.clickButtonMessage2")}</span>
                        </div>
                    </div>
                </>
            }
            {(isEditForm || childButtonClicked) && <>
                
                <div className="user-form row">
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.name")}*</label>
                            <input className="field input-field" type="text" name="name" placeholder={translate("form.secondStepForm.name")}
                                value={childData.name} onChange={onValueChange}  required/>
                        </div>
                    </div>
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.surname")}*</label>
                            <input className="field input-field" type="text" name="surname" placeholder={translate("form.secondStepForm.surname")}
                                value={childData.surname} onChange={onValueChange}  required/>
                        </div>
                    </div>
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.dateOfBirth")}*</label>
                            <input
                                className="field input-field"
                                type="date"
                                name="dateOfBirth"
                                placeholder={translate("form.secondStepForm.dateOfBirth")}
                                value={childData.dateOfBirth ?? null}
                                onChange={onValueChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.gender")}</label>
                             <select className="field input-field" name="gender" value={childData.gender} onChange={onValueChange}>
                                <option value="2">{translate("form.firstStepForm.genderValue.female")}</option>
                                <option value="1">{translate("form.firstStepForm.genderValue.male")}</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.province")}</label>
                             <input className="field input-field" type="text" name="province" placeholder={translate("form.secondStepForm.province")}
                                value={childData.province} onChange={onValueChange} />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.municipalityOfBirth")}</label>
                            <input className="field input-field" type="text" name="birthPlace" placeholder={translate("form.secondStepForm.municipalityOfBirth")}
                                value={childData.birthPlace} onChange={onValueChange}/>
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.email")}</label>
                            <input className="field input-field" type="email" name="email" placeholder={translate("form.secondStepForm.email")}
                                value={childData.email} onChange={onValueChange}/>
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.fiscalCode")}</label>
                            <input className="field input-field" type="fiscalCode" name="fiscalCode" placeholder={translate("form.firstStepForm.fiscalCode")}
                                value={childData.fiscalCode} onChange={onValueChange} required/>
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.destination")}</label>
                            <input className="field input-field" type="text" name="destination" placeholder={translate("form.firstStepForm.destination")}
                                value={userInfo?.destination} onChange={(event) => onValueChange(event)} readOnly  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.startWith")}</label>
                            <input className="field input-field" type="text" name="startWith" placeholder={translate("form.secondStepForm.startWith")}
                                value={childData.startWith} onChange={onValueChange} />
                        </div>
                    </div>

                    <div className="col-12 d-flex mx-8 pb-6">
                        <h4 className="identity-card">{translate('form.firstStepForm.idCard')}</h4>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.identityDocumentId")}*</label>
                            <input className="field input-field" type="text" name="identityDocumentId" placeholder={translate("form.firstStepForm.identityDocumentId")}
                                value={identityDocuments?.identityDocumentId ?? ""} required
                                onChange={({target}) => onDocumentChange('identityDocumentId', target.value)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.validUntilDate")}*</label>
                            <input
                                type="date"
                                className="field input-field"
                                name="validUntilDate" required
                                placeholder={translate("form.firstStepForm.validUntilDate")}
                                value={identityDocuments?.validUntilDate ?? ''}
                                onChange={({target}) => onDocumentChange('validUntilDate', target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-12 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.releasedBy")}*</label>
                            <input className="field input-field" type="text" name="releasedBy" placeholder={translate("form.secondStepForm.releasedBy")} required
                                value={identityDocuments?.releasedBy ?? ""} onChange={({target}) => onDocumentChange('releasedBy', target.value)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.allergies")}</label>
                            <textarea className='field textarea-field' name="allergies"
                                value={childData.allergies ?? ''} onChange={(event) => onValueChange(event)}
                                placeholder={translate("form.secondStepForm.allergies")}></textarea>
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.secondStepForm.info")}</label>
                            <textarea className='field textarea-field' name="additionalInfo"
                                value={childData.additionalInfo ?? ''} onChange={(event) => onValueChange(event)}
                                placeholder={translate("form.secondStepForm.info")}></textarea>
                        </div>
                    </div>

                    <div className="col-12 d-flex mx-8 pb-6">
                        <div className="disability-section">
                            <input type="checkbox" id="disabled" name="disability"
                                onChange={() => onDisabilityChange()} checked={childData.disability === 1}/>
                            <label htmlFor="disabled">{translate("form.secondStepForm.types.secondItem")}</label><br />
                        </div>
                    </div>

                    {childData.disability === 1 && !isEditForm &&
                        <>
                            <div className="col-12 d-flex mx-8 pt-24 pb-6">
                                <h4 className="identity-card">{translate('form.secondStepForm.caregivers')}</h4>
                            </div>
                            
                        {caregivers && caregivers.map((item, index) =>
                            <React.Fragment key={index} >
                                <div className="col-12 care-givers-container row">
                                    <div className="col-12 px-8 pt-12 row space-between">
                                         <h4 className="identity-card">{translate(`form.secondStepForm.caregiver${index + 1}`)}</h4>
                                        <AiOutlineDelete size={20} style={{fill: '#ff5d10'}} onClick={() => deleteCaregiver(index)}/>
                                    </div>
                                    <CareGiverForm index={index} data={item} onCaregiverDataChange={(object) => onCaregiverDataChange(object, index)} />
                                </div>
                            </React.Fragment>
                        )}
                        {
                            caregivers && caregivers.length < 3 &&
                            <>
                                <div className="col-12 pb-6 row justify-start">
                                    <button type="button" onClick={() => addNewCaregiver()}
                                        className="button primary-button background-blue">
                                        <FaPlus strokeWidth={2} style={{marginBottom: '-2px', marginRight: '4px'}}/>
                                        {translate("form.secondStepForm.addCaregivers")}
                                    </button>
                                </div>
                                <div className="col-12 pb-6">
                                    <label className="input-label">{translate("form.secondStepForm.caregiversNote")}</label>
                                </div>
                            </>
                        }
                        </>
                    }
                </div>

                {
                    newChildAction.isNewChild && !isStringDefined(childData.id) &&
                    <div className="form-field" style={{flexDirection: "row", justifyContent: 'flex-end', marginBottom: '8px'}}>
                        <button disabled={newChildAction.isLoading} type="submit"
                            style={{width: '48%'}}>{translate("form.save")}
                            <Spinner size={10} isLoading={newChildAction.isLoading} />
                        </button>
                    </div>
                }
                </>
            }

            {
                hasDateOfBirthError && 
                <div className="form-field" style={{justifyContent: 'center'}}>
                    <p className="error-text">{translate('form.secondStepForm.dateOfBirthNotEmpty')}</p>
                </div>
            }

            <div className="user-form row px-24 space-between">
                <div className="col-6">
                    {
                        isEditForm ?
                        <button type="button" style={{width: '98%'}} className="button primary-button mb-8"
                            onClick={() => onEditClick && onEditClick()}>{translate("form.previous")}</button>
                        :
                        <button type="button" style={{width: '98%'}} className="button primary-button mb-8"
                            onClick={() => onPreviousClick && onPreviousClick({...childData, identityDocuments})}>{translate("form.previous")}</button>
                        }
                </div>
                <div className="col-6">
                    <button id="selectDateAndDeparture" type="submit" style={{width: '98%'}} className="button primary-button mb-8"
                        disabled={(newChildAction.isNewChild && !isStringDefined(childData.id)) || !childData.name || (isEditForm && !areDataChanged) ? true : false}>
                            {translate(isEditForm ? 'form.save' : "form.next")}</button>
                </div>
            </div>
        </form>
    );
}

export default ChildForm;
