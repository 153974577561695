import jsPDF from "jspdf";
import {
  addSimpleCell,
  addTableCell,
  addTableHeader,
  black,
  font,
  orangeText,
  resetFontSize,
} from "../pdf";
import { formatDate } from "../date";

const pacchettoTitle = "Il pacchetto include:";
const pacchettoText = `- Trasporto come da punto di partenza, con durata, orari e trasferimenti come da bando
- Trattamento di pensione completa con dieta bilanciata,, comprese diete speciali,
con pulizia almeno settimanale compresa di sostituzione biancheria.
- 15 ore di attività/corso/corso di lingua certificata a settimana.
Trasporti locali
- Social program con escursioni incluse
- Assistenza con personale italiano h24 con rapporto come da bando
- Assicurazione medico/bagaglio e annullamento
- Informazioni dettagliate così come da bando`;


const noteExtraPolicyText = `La polizza verrà emessa contestualmente alla prenotazione o conferma documentata dei servizi o, al più tardi, entro le ore 24:00 del giorno feriale 
(sabato incluso) successivo.`;

export const generateContractPDF = ({bookingInfo, userInfo,travelerInfo, travelPackageInfo}: {bookingInfo: any, userInfo: any,travelerInfo: any, travelPackageInfo: any}) => {
    // WIDTH: 210 mm
    // HEIGHT: 297 mm
    var doc = new jsPDF();
    const noteExtraAllergieText = [`Allergie alimentari e/o a medicinali`,`Specificare: ${travelerInfo.allergies ?? "________________________"}`];

    const noteExtraAccompagnatoriText = `Presenza accompagnatori:${travelerInfo.disability && bookingInfo.caregivers?.length > 0 ? ' ' + bookingInfo.caregivers.map((c:any) => `${c.name ?? ''} ${c.surname ?? ''} - ${c.fiscalCode ?? ''}`).join('; ') : ''}`;
    
    const dettagliAlloggioText = [`Dettagli alloggio:`,`${travelPackageInfo.roomDescription ?? ''}`,
    `Denominazione:`,`   ${travelPackageInfo.stayName ?? ''}`,
    `Localitá (indirizzo):`,`   ${travelPackageInfo.stayAddress ?? ''}`,
    `Recapiti telefonici:`,`   ${travelPackageInfo.stayPhone ?? ''}`,
    `Indirizzo e-mail:`,`   ${travelPackageInfo.stayEmail ?? ''}`];
    const startDate = new Date(bookingInfo.travel.start);
    const endDate = new Date(bookingInfo.travel.end);

    // Calcola la differenza in millisecondi
    //@ts-ignore
    const differenceInMilliseconds = endDate - startDate;

    // Converti i millisecondi in giorni
    const differenceInDays = (differenceInMilliseconds / (1000 * 60 * 60 * 24)) +1;

  let documentType = "Carta d'identitá";

  switch(bookingInfo?.traveler?.identityDocument?.identityDocumentType){
    case "passport":
        documentType = "Passaporto";
        break;
    default:
        break;
  }

  const img = new Image();
  img.onload = function () {
    doc.setPage(1);
    //@ts-ignore
    doc.addImage(this, 10, 5, 33, 10, "", "FAST");
    doc.setPage(2);
    //@ts-ignore
    doc.addImage(this, 10, 5, 33, 10, "", "FAST");
    doc.setPage(3);
    //@ts-ignore
    doc.addImage(this, 10, 5, 33, 10, "", "FAST");
    doc.setPage(2);
    doc.html(
      `<div style="width: 189px; margin-left:1px;font-family: 'Arial', sans-serif;">
    <p style="font-size: 2px;line-height:1.2">INFORMATIVA AI SENSI DEL NUOVO CODICE PER LA TUTELA DELLA PRIVACY Reg Eur 679/2018 e succ mod Il cliente dichiara di aver preso visione dell’informativa ai sensi del nuovo
    codice per la tutela della privacy. I dati raccolti verranno utilizzati al solo scopo di gestire la procedura e informare il cliente di iniziative simili. Il trattamento avviene da parte di Saled
    srl. L’informativa completa è presente sul sito www.salescuolaviaggi.com. Firmando si fornisce espressamente il consenso al trattamento dei propri dati personali, direttamente da
    parte di Sale Scuola Viaggi o anche attraverso terzi, oltre che per l’integrale esecuzione del servizio o per ottemperare gli obblighi previsti dalla legge, da un regolamento o dalla
    normativa comunitaria, anche per le seguenti finalità: elaborare studi e ricerche statistiche di mercato, inviare materiale pubblicitario ed informativo anche tramite sms o mail,
    compiere attività di vendita o di collocazione di prodotti o di servizi, inviare informazioni commerciali, effettuare comunicazioni commerciali interattive. Il cliente potrà in ogni
    momento esercitare i diritti contattando: Sale Srl - Via Grassi 13, 47922 Rimini (RN), Indirizzo privacy@salescuolaviaggi.com</p><div/>`,
      {
        callback: function (doc) {
          doc.html(
            `<div class="container" style="display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 3px;font-family: 'Arial', sans-serif;">
              <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
              <p style="font-size: 3px;">
              1 – FONTI LEGISLATIVE
              La vendita di pacchetti turistici, che
              abbiano ad oggetto servizi da fornire in
              territorio sia nazionale che estero, è
              disciplinata dalla L. 27/12/1977 n° 1084
              di ratifica ed esecuzione della
              Convenzione Internazionale relativa al
              contratto di viaggio (CCV) firmata a
              Bruxelles il 23.4.1970 - in quanto
              applicabile - nonché dal Codice del
              Turismo (Cod. Tur.) di cui all’allegato 1
              del D. Lgs n. 79 del 23 maggio 2011 (e
              sue successive modificazioni) e, per
              quanto ivi non specificamente previsto,
              dalle disposizioni del Codice del
              Consumo (Cod. Cons., di cui al D. Lgs.
              206/2005 e successive modificazioni).
              i pacchetti venduti lo sono nell’ambito
              della disciplina dei pacchetti turistici.
              4 - PRENOTAZIONI
              Le condizioni generali allegate al
              contratto, infatti, costituiranno parte
              integrante e sostanziale del contratto
              medesimo. Le indicazioni relative al
              pacchetto turistico non contenute nei
              suddetti documenti contrattuali, negli
              opuscoli ovvero in altri mezzi di
              comunicazione scritta, saranno fornite
              dall’organizzatore, in regolare
              adempimento degli obblighi previsti a
              proprio carico dall’art. 37, comma 2,
              Cod. Tur., prima dell’inizio del viaggio,
              generalmente in formato digitale. Nei
              contratti conclusi a distanza o al di fuori
              dei locali commerciali (come
              rispettivamente definiti dagli artt. 50 e
              45 del D. Lgs. 206/2005) è escluso il
              diritto di recesso previsto dagli artt. 64
              e ss. del D. Lgs. 206/2005.
              6 - PREZZO
              Il prezzo del pacchetto turistico è
              determinato nel contratto, con
              riferimento a quanto indicato nei
              programmi pubblicati sul sito web
              dell’organizzatore ed agli eventuali
              aggiornamenti successivamente
              intervenuti. Le variazioni rimangono a
              norma di legge - Codice del Turismo.
              7 – PAGAMENTO
              si fa riferimento alla scelta presente su
              sito, se immediata, tramite acconto e
              rate, o tramite intermediario finanziario.
              In fase di scelta viene rilasciato
              documento di pagamento, al quale si fa
              riferimento.
              Resta inteso che l’inoperatività e/o
              inefficacia e/o, comunque, il mancato
              buon fine, anche parziali, delle
              delegazioni di pagamento suddette
              risultanti imputabili a fatto e/o colpa del
              consumatore/contraente,
              comporteranno che quest’ultimo
              resterà tenuto a provvedere al
              pagamento dell’importo totale del
              contratto dovuto a Saled Srl ed entro
              la data riportata. Fermo quanto sopra,
              </p>
              </div>
              <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
              <p style="font-size: 3px;">
              il mancato e/o ritardato pagamento,
              anche parziale, degli importi dovuti nei
              termini sopra convenuti darà facoltà
              all’organizzatore di ritenere
              immediatamente risolto di diritto il
              contratto..
              I pagamenti potranno essere effettuati
              come sopra specificato. Per i
              beneficiari del bando di concorso
              Estate INPSieme tutti i pagamenti
              dovranno essere effettuati
              preferibilmente a nome del
              RICHIEDENTE LA PRESTAZIONE,
              così da avere ogni fattura intestata
              allo stesso. Per dubbi o chiarimenti
              rivolgersi agli uffici di Sale Scuola
              Viaggi.
              8 - MODIFICA O ANNULLAMENTO
              PRIMA DELLA PARTENZA
              Prima della partenza l’organizzatore
              che abbia necessità di modificare in
              modo significativo uno o più elementi
              del contratto, ne dà immediato avviso
              in forma scritta al consumatore.
              Ove il consumatore non accetti la
              proposta di modifica di cui al comma 1,
              potrà recedere senza pagamento di
              penali, comunicandolo entro 2 giorni,
              ed avrà diritto di usufruire di un altro
              pacchetto turistico, ove l’organizzatore
              sia in grado di offrirglielo, oppure gli
              sarà rimborsata, nei termini di legge, la
              somma di danaro già corrisposta.
              In difetto di comunicazione entro il
              termine suddetto, la proposta formulata
              dall’organizzatore si intenderà
              accettata.
              Se l’Organizzatore dovesse annullare il
              pacchetto turistico prima della partenza
              per qualsiasi motivo, tranne che per
              colpa del viaggiatore, dovrà rimborsare
              a quest’ultimo, nei termini di legge,
              l’importo pagato per l’acquisto del
              pacchetto turistico e corrispondergli un
              indennizzo per la mancata esecuzione
              del contratto, tranne nei casi di seguito
              indicati.
              Non è previsto alcun indennizzo e/o
              risarcimento derivante
              dall’annullamento del pacchetto
              turistico quando la cancellazione dello
              stesso dipende dal mancato
              raggiungimento del numero minimo di
              partecipanti eventualmente richiesto
              oppure da causa di forza maggiore e
              caso fortuito.
              9 - RECESSO DEL CONSUMATORE
              a) Il consumatore potrà recedere dal
              contratto senza pagare penali nelle
              seguenti ipotesi:
              - aumento del prezzo in misura
              eccedente il 10%;
              - modifica in modo significativo di uno o
              più elementi del contratto
              oggettivamente configurabili come
              fondamentali ai fini della fruizione del
              </p>
              </div>
              <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
              <p style="font-size: 3px;">
              pacchetto turistico complessivamente
              considerato e proposta
              dall’organizzatore dopo la conclusione
              del contratto stesso ma prima della
              partenza e non accettata dal
              consumatore.
              Nei casi di cui sopra, il consumatore ha
              alternativamente diritto:
              - ad usufruire di un pacchetto turistico
              alternativo, di qualità equivalente o
              superiore qualora l’organizzatore possa
              proporglielo. Se il servizio tutto
              compreso è di qualità inferiore,
              l’organizzatore deve rimborsare al
              consumatore la differenza di prezzo;
              oppure
              - alla restituzione delle somme già
              corrisposte. Tale restituzione dovrà
              essere effettuata nei termini di legge.
              b). Al turista che receda dal
              contratto prima della partenza al di
              fuori delle ipotesi elencate al punto a),
              saranno addebitati, indipendentemente
              dal pagamento dell’acconto, il costo
              individuale della PENALE (il calcolo dei
              giorni non include quello del recesso)
              pari a
              → 50% del prezzo del pacchetto,
              sino a 30 (trenta) giorni lavorativi
              prima della partenza;
              → 80% del prezzo del pacchetto,
              sino a 21 (ventuno) giorni lavorativi
              prima della partenza;
              → nessun rimborso è previsto
              dopo tale ultimo termine;
              nonché l’eventuale corrispettivo di
              coperture assicurative già attivate al
              momento del recesso dal contratto e di
              eventuali servizi all’epoca già resi.
              Per tutte le combinazioni nessun
              rimborso sarà accordato a chi non si
              presenterà alla partenza o rinuncerà
              durante lo svolgimento del viaggio
              stesso.
              La comunicazione della cancellazione
              della vacanza studio dovrà essere
              inviata esclusivamente a mezzo
              raccomandata A/R o pec agli uffici di
              Saled srl con firma del sottoscrittore in
              originale.
              c) In caso di viaggi che includono
              l’utilizzo dei voli di linea con tariffe
              speciali le condizioni relative alle
              penalità di cancellazione di tali voli
              saranno aggiunte a quelle come sopra
              disciplinate e saranno quelle imposte
              dalla Compagnia Aerea di riferimento
              (generalmente molto più restrittive).
              10 - MODIFICHE DOPO LA
              PARTENZA
              L’organizzatore, qualora dopo la
              partenza si trovi nell’impossibilità di
              fornire per qualsiasi ragione, tranne
              che per un fatto proprio del
              consumatore, una parte essenziale dei
              servizi contemplati in contratto, dovrà
              </p>
              </div>
              <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
              <p style="font-size: 3px;">
              predisporre soluzioni alternative, senza
              supplementi di prezzo a carico del
              contraente e qualora le prestazioni
              fornite siano di valore inferiore rispetto
              a quelle previste, rimborsarlo in misura
              pari a tale differenza. Qualora non
              risulti possibile alcuna soluzione
              alternativa, ovvero la soluzione
              predisposta dall’organizzatore venga
              rifiutata dal consumatore per
              comprovati e giustificati motivi,
              l’organizzatore fornirà senza
              supplemento di prezzo, un mezzo di
              trasporto equivalente a quello originario
              previsto per il ritorno al luogo di
              partenza o al diverso luogo
              eventualmente pattuito,
              compatibilmente alle disponibilità dei
              mezzi e di posti, e lo rimborserà nella
              misura della differenza tra il costo delle
              prestazioni previste e quello delle
              prestazioni effettuate fino al momento
              del rientro anticipato.
              11 - CANCELLAZIONE E/O
              VARIAZIONE DEL VIAGGIO PER
              MANCATO RAGGIUNGIMENTO DEL
              NUMERO MINIMO DEI
              PARTECIPANTI
              In caso di mancato raggiungimento del
              numero minimo di partecipanti
              comunicato (10 partecipanti per turno,
              Sale Scuola Viaggi si riserva il diritto di
              variare la destinazione del viaggio e/o
              di accorpare gruppi tra di loro,.
              12 – INFORMATIVE AI
              CONSUMATORI ED OBBLIGAZIONI
              DI QUESTI ULTIMI
              Si raccomanda di controllare presso la
              Questura la validità del proprio
              documento.
              Per le norme relative all’espatrio dei
              minori si rimanda espressamente a
              quanto indicato nel sito della Polizia di
              Stato. Si precisa comunque che i
              minori devono essere in possesso di un
              documento personale valido per
              l’espatrio ovvero passaporto, o per i
              Paesi UE, anche di carta di identità
              valida per l’espatrio. Per quanto
              riguarda l’espatrio dei minori di anni 14
              e l’espatrio di minori per i quali è
              necessaria l’Autorizzazione emessa
              dalla Autorità Giudiziaria, dovranno
              essere seguite le prescrizioni indicate
              sul sito della Polizia di Stato
              http://www.poliziadistato.it/articolo/191/.
              I cittadini stranieri dovranno reperire le
              corrispondenti informazioni attraverso
              le loro rappresentanze diplomatiche
              presenti in Italia e/o i rispettivi canali
              informativi governativi ufficiali.
              In ogni caso, i consumatori, prima della
              partenza, dovranno provvedere a
              verificare l’aggiornamento delle
              informazioni prima ricevute presso le
              competenti autorità (per i cittadini
                </p>
              </div>
            </div>`,
            {
              callback: function (doc) {
                doc.html(
                  `<div class="container" style="display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 3px;font-family: 'Arial', sans-serif;">
                  <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
                  <p style="font-size: 3px;">
                      italiani le locali Questure ovvero il
Ministero degli Affari Esteri tramite il
sito www.viaggiaresicuri.it ovvero la
Centrale Operativa Telefonica al
numero 06.491115) adeguandovisi
prima del viaggio. In assenza di tale
verifica, nessuna responsabilità per la
mancata partenza potrà essere
imputata all’organizzatore.
I consumatori dovranno in ogni caso
informarsi riguardo ai documenti
necessari. Il turista avrà l’onere di
assumere le informazioni ufficiali di
carattere generale presso il Ministero
Affari Esteri e divulgate attraverso il sito
istituzionale della Farnesina
www.viaggiaresicuri.it.
In nessun caso Sale Scuola Viaggi
sarà responsabile per problemi
causati da documenti non in regola
al momento della partenza.
Il consumatore è sempre tenuto ad
informare l’organizzatore di eventuali
sue esigenze o condizioni particolari
(gravidanza, allergie, intolleranze
alimentari, disabilità, ecc. ) e a
specificare esplicitamente la richiesta di
relativi servizi personalizzati.
Sale Scuola Viaggi si riserva in ogni
momento del soggiorno di rimpatriare il
partecipante per motivi disciplinari,
previa regolare comunicazione ai
genitori. Tutti i costi relativi e/o
conseguenti al rimpatrio saranno a
carico del partecipante e nessun
rimborso sarà previsto per l’interruzione
</p>
                      </div>
                      <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
                  <p style="font-size: 3px;">
                      del soggiorno.
13 - LIMITI DEL RISARCIMENTO
I risarcimenti di cui agli artt. 44, 45 e 47
del Cod. Tur. e relativi termini di
prescrizione sono disciplinati da quanto
ivi previsto e comunque nei limiti
stabiliti dalla legge, dalle Convenzioni
Internazionali che disciplinano le
prestazioni che formano oggetto del
pacchetto turistico nonché dagli articoli
1783 e 1784 del codice civile, ad
eccezione dei danni alla persona, non
soggetti a limite prefissato.
15 - OBBLIGO DI ASSISTENZA
L’organizzatore è tenuto a prestare le
misure di assistenza al consumatore
secondo il criterio di diligenza
professionale con esclusivo riferimento
agli obblighi a proprio carico per
disposizione di legge o di contratto.
L’organizzatore è esonerato dalle
rispettive responsabilità (anche ex artt.
11 e 12 delle presenti Condizioni
Generali), quando la mancata od
inesatta esecuzione del contratto è
imputabile al consumatore o è dipesa
dal fatto di un terzo a carattere
imprevedibile o inevitabile, ovvero è
stata causata da un caso fortuito o di
forza maggiore.
16 - RECLAMI E DENUNCE
Ogni mancanza nell’esecuzione del
contratto deve essere contestata dal
consumatore durante la fruizione del
pacchetto mediante tempestiva
</p>
                      </div>
                      <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
                  <p style="font-size: 3px;">
                      presentazione di reclamo scritto
affinché l’organizzatore o il suo
rappresentante locale vi pongano
tempestivamente rimedio. In caso
contrario il risarcimento del danno sarà
diminuito o escluso ai sensi dell’art.
1227 c.c.. Il turista dovrà altresì – a
pena di decadenza – sporgere reclamo
mediante l’invio di una raccomandata
con avviso di ricevimento, o altro
mezzo che garantisca la prova
dell’avvenuto ricevimento,
all’organizzatore entro e non oltre dieci
giorni lavorativi successivi alla data di
rientro nel luogo di partenza.
Qualora il consumatore non si presenti
alla partenza o rientri anticipatamente
dal soggiorno e/o, comunque, fruisca
solo in parte del pacchetto turistico per
fatto non imputabile all’organizzatore,
sarà in ogni caso tenuto a provvedere
al pagamento dell’intero corrispettivo
del pacchetto turistico direttamente in
favore dell’organizzatore medesimo,
senza diritto a rimborsi e/o indennizzi di
sorta.
19 - MATERIALE FOTOGRAFICO E
VIDEO
Accettando le presenti condizioni di
contratto i genitori del minore o che ne
fa le veci, o il partecipante, se
maggiorenne, autorizzano
espressamente Sale Scuola Viaggi ad
utilizzare gratuitamente e senza limiti
spaziali e/o temporali eventuali foto o
video realizzati nel corso dei soggiorni
</p>
                      </div>
                      <div class="column" style="width: 48px;text-align:justify;line-height:1.2">
                  <p style="font-size: 3px;">
                      per la riproduzione su proprio materiale promozionale a stampa o in digitale o su qualsivoglia altro supporto tecnologico. 20)FORO COMPETENTE/CLAUSOLA COMPROMISSORIA. Per ogni controversia dipendente dal presente contratto sarà competente esclusivamente il Foro di Rimini. 21)COMUNICAZIONE OBBLIGATORIA AI SENSI DELL’ART. 17 DELLA LEGGE 06/02/2006 N. 38: la Legge italiana punisce con la pena della reclusione i reati inerenti alla prostituzione o alla pornografia minorile, anche se gli stessi sono commessi all’estero. PRIVACY: si informa che tutti i dati personali verranno trattati nel pieno rispetto delle disposizioni della legge 196/2003 e che il trattamento dei dati personali è diretto all’espletamento da parte della Società delle prestazioni che formano oggetto del pacchetto turistico. I dati personali in ogni caso non saranno trasmessi ai terzi e in ogni momento potranno essere cancellati a richiesta del consumatore. Per la Scheda tecnica del Viaggio, si fa riferimento a quanto riportato sul 
                      www.salescuolaviaggi.com/scheda tecnica
                      </p>
                      </div>
                    </div>`,
                  {
                    callback: function (doc) {
                        doc.setPage(3);
        
                        const imgTimbro = new Image();
                        imgTimbro.onload = function() {
                        //@ts-ignore
                        doc.addImage(this, 130, 190, 51, 17, "", "FAST"); 
                        //@ts-ignore
                        doc.addImage(this, 130, 230, 51, 17, "", "FAST"); 
                        
                          let pdfName = bookingInfo.contractNumber ?? '';
                        doc.save(`Contratto-${pdfName}`);
                        };
                        imgTimbro.crossOrigin = "";
                        imgTimbro.src = "/saledtimbro.png";
                    },
                    x: 4,
                    y: 620,
                    windowWidth: 500,
                  }
                );
              },
              x: 4,
              y: 320,
              windowWidth: 500,
            }
          );
        },
        x: 10,
        y: 223,
        windowWidth: 500,
      }
    );
  };
  // TODO: check if we need to put 'anonymous'
  img.crossOrigin = ""; // for demo as we are at different origin than image
  img.src = "/saleLogo.png";

  function addFooter(page: number) {
    doc.text(`Pag. ${page} di 3`, 180, 290);

    doc.text(
      "Sale Scuola Viaggi di Saled srl–s.o. Viale Principe Amedeo 7. Rimini(RN) – Tel:0541/1741120, Fax:0541/792982",
      30,
      285
    );
    doc.text(
      "P.IVA e C/F.02814460412– inps@salescuolaviaggi.com, www.salescuolaviaggi.com",
      45,
      290
    );
  }

  function addHeader() {
    doc.setFont(font, "bold");
    doc.setFontSize(13);
    doc.text("CONTRATTO DI VIAGGIO", 200, 10, {
      align: "right",
    });
    doc.setTextColor(orangeText);
    doc.text("INPSieme - Sale Scuola Viaggi", 200, 18, {
      align: "right",
    });
    doc.setTextColor(black);
    resetFontSize(doc);
  }
  function addNumberContract(contractNumber: number) {
    doc.setFontSize(11);
    doc.setFont(font, "bold");
    doc.text(`Numero pratica: ${contractNumber}`, 10, 27);
    resetFontSize(doc);
  }

  function addPacchettoCell(
    textTitle: string,
    textValues: string,
    x: number,
    y: number
  ) {
    doc.setFontSize(10);
    doc.setFont(font, "bold");

    doc.text(textTitle, x + 1, y + 4);

    resetFontSize(doc);

    doc.text(textValues, x + 1, y + 8);
  }

  function addNoteExtraText(
    textAllergie: string[],
    textAccompagnatori: string,
    textSecond: string,
    x: number,
    y: number
  ) {
    doc.setFontSize(10);
    doc.setFont(font, "bold");

    doc.text(textAllergie[0], x + 1, y + 4);
    if(travelerInfo.allergies){
        doc.text(textAllergie[1], x + 1, y + 8, {maxWidth:185});
    } else {
        doc.line(x+1, y+3, x+58, y+3)
    }

    doc.text(textAccompagnatori, x + 1, y + 12, {maxWidth:185});
    if(travelerInfo.disability && bookingInfo.caregivers?.length <= 0){
        doc.line(x+1, y+11, x+45, y+11)
    }

    resetFontSize(doc);
    doc.setFont(font, "italic");

    doc.text(textSecond, x + 1, y + 20);
    resetFontSize(doc);
  }

  function addDocumentiNecessariText(x: number, y: number) {
    //@ts-ignore
    doc.cell(x, y, 70, 35, " ", undefined);
    doc.setFontSize(10);
    doc.setFont(font, "bold");

    doc.text("DOCUMENTI NECESSARI", x + 1, y + 4);

    doc.text("1. Carta d’identità in corso di validità di:", x + 2, y + 8);
    doc.setFont(font, "normal");
    doc.text(
      [
        `- Partecipante al viaggio`,
        `- Genitore richiedente il contributo`,
        `- Eventuali accompagnatori`,
        `- Passaporto se extra -UE`,
      ],
      x + 4,
      y + 12
    );
    doc.setFont(font, "bold");
    doc.text("2. Tessera sanitaria di:", x + 2, y + 28);
    doc.setFont(font, "normal");
    doc.text(`- Partecipante/i al viaggio`, x + 4, y + 32);

    resetFontSize(doc);
  }

  function addBonificoText(x: number, y: number) {
    //@ts-ignore
    doc.cell(x, y, 100, 25, " ", undefined);
    doc.setFontSize(9);
    doc.setFont(font, "bold");

    doc.text("COORDINATE BANCARIE PER BONIFICO:", x + 1, y + 4);

    doc.text(
      ["Intestato a: Saled Srl", "Banca: Banca Malatestiana"],
      x + 1,
      y + 8
    );
    doc.setFont(font, "normal");
    doc.text(`Codice IBAN: IT63N0709024213027010218799`, x + 1, y + 16);
    doc.text(
      [
        "CAUSALE: Nella causale si prega di specificare nome e cognome",
        "del partecipante, numero pratica",
      ],
      x + 1,
      y + 20
    );

    resetFontSize(doc);
  }

  function addInformativaHTML(x: number, y: number) {
    //@ts-ignore
    doc.cell(x, y, 190, 20, " ", undefined);
  }

  function addFirmaFirst(x: number, y: number) {
    //@ts-ignore
    doc.cell(x, y, 190, 20, " ", undefined);
    doc.setFontSize(10);
    doc.setFont(font, "bold");
    doc.text("_____________", 10 + 3, y + 10);
    doc.text("______________________________", 120 + 1, y + 10);

    doc.text("Luogo e data", 10 + 3, y + 16);
    doc.text(
      "Firma del genitore o di chi ne esercita la potestà genitoriale",
      100 + 1,
      y + 16
    );

    resetFontSize(doc);
  }

  resetFontSize(doc);

  addHeader();
  addNumberContract(bookingInfo.contractNumber);

  addTableHeader(
    doc,
    "A. DATI PERSONALI DEL RICHIEDENTE (genitore o legale tutore)",
    30
  );
  addTableCell(doc, "Nome e cognome", `${userInfo.name} ${userInfo.surname}`, 10, 35, 30, 80);
  addTableCell(doc, "Cellulare", userInfo.phoneNumber ?? '', 120, 35, 20, 60);
  addTableCell(doc, "E-mail", userInfo.email ?? '', 10, 40, 30, 80);
  addTableCell(doc, "Cod fiscale", userInfo.fiscalCode ?? '', 120, 40, 20, 60);
  addTableCell(doc, "Indirizzo", `${userInfo.address ?? ''} ${userInfo.province ?? ''} ${userInfo.city ?? ''}`, 10, 45, 30, 160);

  addTableHeader(
    doc,
    "IN NOME E PER CONTO DELLA PERSONA DI SEGUITO ELENCATA E DENOMINATA “PARTECIPANTE AL VIAGGIO”:",
    50
  );
  addTableCell(doc, "Nome e cognome", `${bookingInfo.traveler.name} ${bookingInfo.traveler.surname}`, 10, 55, 30, 45);
  addTableCell(doc, "Nato a", travelerInfo.birthPlace ?? '', 85, 55, 20, 40);
  addTableCell(doc, "Il", travelerInfo.dateOfBirth ?? '', 145, 55, 15, 40);
  addTableCell(doc, "Nazionalità", travelerInfo.country ?? '', 10, 60, 30, 45);
  addTableCell(doc, "Cod fiscale", travelerInfo.fiscalCode ?? '', 85, 60, 20, 40);
  addTableCell(doc, "Cellulare", travelerInfo.phoneNumber ?? '', 145, 60, 15, 40);
  addTableCell(doc, "Tipo di documento", documentType, 10, 65, 30, 45);
  addTableCell(doc, "Numero", travelerInfo.identityDocuments?.identityDocumentId ?? '', 85, 65, 20, 40);
  addTableCell(doc, "Scadenza", travelerInfo.identityDocuments?.validUntilDate ?? '', 145, 65, 15, 40);
  addTableCell(doc, "Vorrei partire con", bookingInfo.startWith ?? '', 10, 70, 30, 160);

  addTableHeader(doc, "DATI TECNICO ORGANIZZATIVI", 75);
  addSimpleCell(doc, `${bookingInfo.travelPackageName} - ${differenceInDays} giorni / ${differenceInDays-1} notti`, 10, 80, 190);
  
  addTableCell(doc, "Data soggiorno dal", formatDate(bookingInfo.travel.start), 10, 85, 30, 20);
  addTableCell(doc, "Al", formatDate(bookingInfo.travel.end), 60, 85, 5, 20);
  addTableCell(doc, "Localita di partenza", bookingInfo.travel.from ?? '', 85, 85, 30, 85);
  addTableCell(doc, "Nome del pacchetto", bookingInfo.travelPackageName ?? '', 10, 90, 30, 160);

  addTableHeader(doc, "IL PACCHETTO", 90);
  //@ts-ignore
  doc.cell(10, 95, 115, 40, " ", undefined);
  addPacchettoCell(pacchettoTitle, pacchettoText, 10, 95);
  //@ts-ignore
  doc.cell(125, 95, 75, 40, " ", undefined);
  doc.text(dettagliAlloggioText,126, 98, {maxWidth:75});

  resetFontSize(doc);

  addTableHeader(
    doc,
    "NOTE E SERVIZI EXTRA FACOLTATIVI A CARICO DEL RICHIEDENTE (DA SOMMARE ALLA QUOTA DEL PACCHETTO)",
    135
  );

  let extraCosts = 0;

  bookingInfo.selectedOptions.forEach((item:any) => {
      if (item.options && item.options.length > 0) {
          const optionsCost = item.options.reduce((acc:number, currentOption:any) => acc + currentOption.cost, 0);
          extraCosts += optionsCost;
      }
  });
  
  //@ts-ignore
  doc.cell(10, 140, 190, 25, " ", undefined);
  addNoteExtraText(noteExtraAllergieText, noteExtraAccompagnatoriText, noteExtraPolicyText, 10, 140);

  addDocumentiNecessariText(130, 165);
  doc.setFont(font, "bold");
  addSimpleCell(doc, "SERVIZI RICHIESTI:", 10, 165, 120);
  addSimpleCell(doc, "Il Pacchetto", 10, 170, 90);
  addSimpleCell(doc, `€${travelPackageInfo.discountedCost + bookingInfo.travel.cost}`, 100, 170, 30);
  addSimpleCell(doc, "Costo Accompagnatori", 10, 175, 90);
  addSimpleCell(doc, `€${bookingInfo.totalCost / ((bookingInfo.caregivers ? bookingInfo.caregivers?.length : 0) +1)}`, 100, 175, 30);
  addSimpleCell(doc, "Servizi facoltativi extra", 10, 180, 90);
  addSimpleCell(doc, `€${extraCosts}`, 100, 180, 30);
  //@ts-ignore
  doc.cell(10, 185, 90, 15, " ", undefined);
  //@ts-ignore
  doc.cell(100, 185, 30, 15, " ", undefined);
  doc.text("Prezzo complessivo", 10 + 1, 185 + 4);
  doc.text(`€${bookingInfo.totalCost}`, 100 + 1, 185 + 4);
  resetFontSize(doc);

  //@ts-ignore
  doc.cell(10, 200, 90, 10, " ", undefined);
  //@ts-ignore
  doc.cell(10, 210, 90, 15, " ", undefined);

  addBonificoText(100, 200);

  addInformativaHTML(10, 225);
  addFirmaFirst(10, 245);

  addFooter(1);
  doc.addPage();

  addHeader();
  addFooter(2);
  doc.addPage();

  addHeader();
  addFooter(3);

  doc.setFont(font, "bold");
  doc.setFontSize(11);
  doc.text(
    "Per conferma del pacchetto viaggio e accettazione delle suddette condizioni generali di contratto.",
    10,
    180
  );
  doc.text("Luogo/Data _________________________________", 10, 190);
  doc.text("FIRMA DEL GENITORE O DEL TUTORE LEGALE", 10, 198);
  doc.setFontSize(8);
  doc.text(
    "(intestatario del contratto e richiedente la prestazione)",
    10,
    206
  );
  doc.text("_____________________________________", 10, 214);
  doc.setFontSize(9);
  doc.text(
    [
        "Dichiaro/iamo di aver attentamente letto e di approvare specificamente, l’informativa Reg Eur 679/2018 e succ mod riguardo",
        "la tutela della privacy. La versione completa ed estesa è presente su www.salescuolaviaggi.com/privacy",
    ],
    10,
    222
  );
  doc.setFontSize(11);
  doc.text("Luogo/Data _________________________________", 10, 232);
  doc.text("FIRMA DEL GENITORE O DEL TUTORE LEGALE", 10, 240);
  doc.setFontSize(8);
  doc.text(
    "(intestatario del contratto e/o richiedente la prestazione)",
    10,
    248
  );
  doc.text("_____________________________________________________", 10, 256);
  doc.setFontSize(10);
  doc.text(
    [
      "NB: IL PRESENTE CONTRATTO CONTROFIRMATO DEVE ESSERE CARICATO ENTRO 48H NELLA PROPRIA AREA",
      "RISERVATA DEL SITO INTERNET DI SALE SCUOLA VIAGGI. UNA VOLTA SCELTO IL PAGAMENTO; CARICATO IL",
      "CONTRATTO, SI RICEVERA’ NELLA MEDESIMA AREA LA FATTURA.",
    ],
    10,
    264
  );
};
