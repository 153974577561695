import { customFetch } from "../api/axios";

// Define the type for the contact form data
type ContactFormData = {
  fullName: string;           // required
  email: string;              // required
  reference?: string;         // optional
  phoneNumber?: string;       // optional
  areaOfInterest?: string;    // optional
  message?: string;           // optional
  destination?: string;
};

// Function to submit contact form data
export const submitContactForm = async (formData: ContactFormData) => {
  try {
    const response = await customFetch.post('/contact', {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      areaOfInterest: formData.destination,
      reference: "",
      message: ""
    });
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error; // Re-throw the error to be handled by the calling code
  }
};


// Add more API functions as needed
