import { AiOutlineCheck, AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle, AiOutlinePullRequest } from "react-icons/ai";
import './BookingStatus.css';
import { useTranslation } from "react-i18next";

interface BookingStatusProps {
    onCancelBooking: () => void;
    onConfirmBooking: () => void;
    size?: number;
    isCanceled?: boolean;
    isConfirmed?: boolean;
    isDisabled?: boolean;
}

const BookingStatus = ({onCancelBooking, onConfirmBooking, size = 20, isCanceled, isConfirmed, isDisabled}: BookingStatusProps) => {
    const {t: translate} = useTranslation();

    const sizeFormatted = `${size}px`;

    if (isDisabled) {
        return (
            <div className="booking-status row flex-end">
                <button className="status-icon confirm-icon row">
                    <h3 className="status-text">{translate('privateArea.bookingDetails.requested')}</h3>
                    <AiOutlinePullRequest className="action-icon" style={{ height: sizeFormatted, width: sizeFormatted, fill: 'green', strokeWidth: 10}}/>
                </button>
            </div>
        )
    }

    if (isCanceled) {
        return (
            <div className="booking-status row flex-end">
                <button className="status-icon cancel-icon row">
                    <h3 className="status-text">{translate('privateArea.bookingDetails.canceled')}</h3>
                    <AiOutlineCloseCircle className="action-icon" style={{ height: sizeFormatted, width: sizeFormatted, fill: 'red', strokeWidth: 10}}/>
                </button>
            </div>
        )
    }

    if (isConfirmed) {
        return (
            <div className="booking-status row flex-end">
                <button className="status-icon confirm-icon row">
                    <h3 className="status-text">{translate('privateArea.bookingDetails.confirmed')}</h3>
                    <AiOutlineCheckCircle className="action-icon" style={{ height: sizeFormatted, width: sizeFormatted, fill: 'green', strokeWidth: 10}}/>
                </button>
            </div>
        )
    }

    return (
        <div className="booking-status row flex-end">
            <button type="button" className="status-icon cancel-icon row cursor-pointer" onClick={() => onCancelBooking()}>
                <h3 className="status-text">{translate('privateArea.bookingDetails.cancel')}</h3>
                <AiOutlineClose className="action-icon" style={{ height: sizeFormatted, width: sizeFormatted, fill: 'red', strokeWidth: 10}}/>
            </button>
            <button type="button" className="status-icon confirm-icon row cursor-pointer" onClick={() => onConfirmBooking()}>
                <h3 className="status-text">{translate('privateArea.bookingDetails.confirm')}</h3>
                <AiOutlineCheck className="action-icon" 
                    style={{ height: sizeFormatted, width: sizeFormatted, fill: 'green', strokeWidth: 10}}/>
            </button>
        </div>
    )
}

export default BookingStatus;
