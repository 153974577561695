import { SVGProps } from "react"

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
    <path
        fill={props?.fill ?? "#0E0F1D"}
        d="M9.88 19.761V13.13H8.076v-2.387H9.88v-2.04c0-1.602 1.066-3.074 3.521-3.074.994 0 1.73.093 1.73.093l-.059 2.23s-.75-.008-1.567-.008c-.886 0-1.028.397-1.028 1.055v1.744h2.666l-.116 2.387h-2.55v6.632H9.88Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props?.fill ?? "#fff"} d="M8.076 5.628h7.067V19.76H8.076z" />
      </clipPath>
    </defs>
  </svg>
)

export default FacebookIcon
