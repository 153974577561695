import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignupPage.css';
import { registerUser } from '../../services/authService';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { ICON_SIZE } from '../LoginPage/LoginPage';
import { toast } from 'react-toastify';

const SignupPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [signUpError, setSignUpError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
       username: '',
       firstName: '',
       lastName: '',
       email: '',
       phoneNumber: '',
       password: '',
       passwordConfirm: '',
   });

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       const { name, value } = event.target;
       setFormData((prevFormData) => ({
           ...prevFormData,
           [name]: value,
       }));
   };

   const handlePrivacyPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setIsPrivacyPolicyChecked(event.target.checked);
   };

   useEffect(() => {
        if (isSubmiting) {
            registerUser({
                ...formData,
                username: formData.email,
                middleNames: null,
            }).then((response: any) => {
                setIsSubmitting(false);
                setIsLoading(false)
                
                if (response && response.status === 200) {
                    setSignUpError('')
                    navigate('/');
                } else {
                    toast.error(t('signup.userExistsError'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    setSignUpError('signup.userExistsError')
                }
            }).catch((e) => {
                console.log(e, 'Error status')
            });
        }
   }, [isSubmiting, formData, navigate, t])

   const handleSubmit = async () => {
        if (formData.password === formData.passwordConfirm) {
            setPasswordMismatch(false);
            setIsLoading(true);
            setSignUpError('');
            setIsSubmitting(true);
        }  else {
            setPasswordMismatch(true);
        }
   };

   const getIsButtonEnabled = useMemo(() =>  {
        return isPrivacyPolicyChecked && formData.email &&
            formData.firstName && formData.lastName &&
            formData.password && formData.passwordConfirm;
   }, [formData.email, formData.firstName, formData.lastName,
        formData.password, formData.passwordConfirm, isPrivacyPolicyChecked
    ]);

    return (
        <>
            <NavigationBar alternative={true} isSearchShown={false}/>
            <div className="signup-page">
                <h2>{t("menu.fourthItem")}</h2>
                <input value={formData.email} type="email" placeholder={t("signup.email")+' *'} name="email" onChange={handleChange} required />
                <input value={formData.firstName} type="firstName" placeholder={t("signup.firstName")+' *'} name="firstName" onChange={handleChange} required />
                <input value={formData.lastName} type="lastName" placeholder={t("signup.lastName")+' *'} name="lastName" onChange={handleChange} required />
                <input value={formData.phoneNumber} type="text" placeholder={t("signup.phone")} name="phoneNumber" onChange={handleChange} required />
                <div className="password-input">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder={t("signup.password")+' *'}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="password-input">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder={t("signup.passwordConfirm")+' *'}
                        name="passwordConfirm"
                        value={formData.passwordConfirm}
                        onChange={handleChange}
                        required
                    />
                    <span
                        className="show-password-icon"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <AiFillEyeInvisible style={{ ...ICON_SIZE, fill: '#ff5d10'}}/> : <AiFillEye style={ICON_SIZE}/>}
                    </span>
                </div>
                { passwordMismatch ? <p className="signup-password-error">{t("signup.passwordDontMatch")}</p> : null}
                <label className="privacy-policy">
                    <input
                    type="checkbox" required
                    checked={isPrivacyPolicyChecked}
                    onChange={handlePrivacyPolicyChange}
                    />
                    {t("signup.privacyPolicyStart")}
                    <>&nbsp;</>
                    <a href="https://www.salescuolaviaggi.com/privacy/" target="_blank" rel="noopener noreferrer" className='account-links'>{t("signup.privacyPolicyLink")}</a>
                </label>
                <button type="submit" disabled={!getIsButtonEnabled} className={!getIsButtonEnabled ? "signup-button-not-checked": "signup-button"} onClick={handleSubmit}>{t("menu.fourthItem")}</button>
                {signUpError ? <p className="error-text">{t(signUpError)}</p> : <></>}
                {isLoading ? <p>{t("signup.signUpLoading")}</p> : <></>}
                <p className="signup-text">{t("signup.signUpQuestion")} <a className='account-links' href="/login">{t("menu.thirdItem")}</a></p>
            </div>
        </>
    );
};

export default SignupPage;
