import { useCallback, useEffect, useState } from 'react';
import './payment.css';
import { useTranslation } from 'react-i18next';
import { createPaymentMethod } from '../../../../services/bookingService';
import { getInstallmentsCalculation } from '../../../../services/instalments';
import { getDatabaseFormatedDate } from '../../../../utils/date';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/Spinner/spinner';
import { proceedPayWithHeidi } from '../../../../services/payment';
import { BookingType } from '../../BookingDetails';
import { AiOutlineArrowRight } from 'react-icons/ai';

export type PaymentMethod = 'heidipay' | 'banktransfer';
type PaymentBodyParams = {
    bookingId: string;
    paymentMethod?: PaymentMethod
    totalCost: number | undefined;
    startDate?: string;
    country?: string;
    installmentNumber?: number;
    isPaymentModalOpen?: boolean;
    downloadPayment: (bInfo: BookingType) => Promise<void>;
    downloadContract: (bInfo: BookingType) => Promise<void>;
    updateBookingInfo: (booking: BookingType) => void;
    closeModal: () => void;
    isConfirmed: boolean;
}

type InstallmentInfo = {
    installmentNumber: number;
    amount: string;
    deadline: string;
}

const PaymentBody = ({ bookingId, paymentMethod, totalCost, closeModal, isPaymentModalOpen,
    installmentNumber = 0, downloadPayment, downloadContract, updateBookingInfo, isConfirmed,
    startDate, country = 'italia' }: PaymentBodyParams
) => {
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | undefined>(paymentMethod);
    const [installmentsNumber, setInstallmentsNumber] = useState(installmentNumber);
    const [isMethodChanged, setIsMethodChanged] = useState<boolean>(false);
    const [isCalculationLoading, setIsCalculationLoading] = useState<boolean>(false);
    const [isMethodLoading, setIsMethodLoading] = useState<boolean>(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
    const [isInstalmentShown, setIsInstalmentShown] = useState<boolean>(false);
    const [installmentInfo, setInstallmentInfo] = useState<InstallmentInfo[] | undefined>();
    const {t: translate} = useTranslation();

    const handleMethodSelect = (method: any) => {
        setSelectedMethod(method);
        setIsMethodLoading(true);
        setIsMethodChanged(true);
    };

    useEffect(() => {
        setSelectedMethod(paymentMethod);
    }, [paymentMethod])

    useEffect(() => {
        setInstallmentsNumber(installmentNumber);
        setIsInstalmentShown(installmentNumber? true: false);
    }, [installmentNumber])

    const addPaymentMethod = useCallback(() => {
        createPaymentMethod({
            bookingId, paymentMethod: selectedMethod, installmentsNumber
        }).then(async (response) => {
            if (response.paymentMethod) {
                setSelectedMethod(response.paymentMethod);
                setIsMethodChanged(false)
                updateBookingInfo(response);

                if (isConfirmed) {
                    await downloadContract(response);
                    await downloadPayment(response);
                }

                if (response.paymentMethod === 'banktransfer') {
                    closeModal()
                }
            }
            setIsPaymentLoading(false);
        }
        ).catch((error) => {
            setIsPaymentLoading(false);
            setIsMethodChanged(false);
        })
    }, [bookingId, downloadPayment, installmentsNumber, selectedMethod, translate, updateBookingInfo, closeModal, downloadContract])

    useEffect(() => {
        let installmentTimeout: string | number | NodeJS.Timeout | undefined;
        if (selectedMethod && isMethodChanged) {
            installmentTimeout = setTimeout(
                () => {
                    setIsInstalmentShown(true);
                    setIsMethodLoading(false);

                    if (selectedMethod === 'heidipay') {
                        addPaymentMethod();
                    }
                }
            , 1000)
        }

        return () => {
            clearTimeout(installmentTimeout)
        }
    }, [selectedMethod, addPaymentMethod, isMethodChanged])

    useEffect(() => {
        if (isPaymentModalOpen && selectedMethod === 'banktransfer') {
            setIsCalculationLoading(true);
            getInstallmentsCalculation({
                installmentsNumber,
                country,
                totalCost,
                startDate: getDatabaseFormatedDate(startDate ?? '')
            }).then((response) => {
                setInstallmentInfo(response);
                setIsCalculationLoading(false);
            }
            ).catch((error) => {
                setIsCalculationLoading(false);
                console.log(error)
            })
        }
    }, [country, startDate, totalCost, installmentsNumber])

    const onValueChange = (value: string) => {
        const newValue = parseInt(value);
        setInstallmentsNumber(newValue);
    }

    const payWithHeidi = () => {
        proceedPayWithHeidi({bookingId})
            .then((response) => {
                if (response?.data && response?.data?.redirectUrl) {
                    window.location.replace(response?.data?.redirectUrl);
                } else {
                    toast.error(translate('privateArea.payment.heidiErrorMessage'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                }
                closeModal();
                setIsPaymentLoading(false);
            }).catch((error) => {
                console.log(error);
                setIsPaymentLoading(false);
                closeModal()
            })
    }

    const onProceedClick = () => {
        setIsPaymentLoading(true);
        if (selectedMethod === 'heidipay') {
            payWithHeidi();
        } else {
            addPaymentMethod();
        }
    }

    const onChangeMethodClick = () => {
        setSelectedMethod(undefined);
        setIsMethodChanged(false);
    }

    return (
        <div className="payment-method-container">
            <div className='methods-header'><h2>{translate("privateArea.payment.title")}</h2></div>
            <div className="payment-methods">
                {
                    isInstalmentShown && selectedMethod?
                        <div className='form-page'>
                            <div className='column'>
                                <p className='row'><b className='method-label'>{translate('privateArea.payment.selectedLabel')}:</b>{translate(`privateArea.payment.${selectedMethod}`)}</p>
                                <p onClick={() => onChangeMethodClick()} className='change-method'>{translate('privateArea.payment.changeMethod')}
                                    <AiOutlineArrowRight style={{marginBottom: '-2px'}} />
                                </p>
                            </div>
                            {
                                selectedMethod !== 'heidipay' &&
                                <>
                                    <div id='instalments' className='column'>
                                        <p className='form-label d-flex px-8'>{translate("privateArea.payment.title")}</p>
                                        <select name="instalments" value={installmentsNumber.toString()} className='field input-field'
                                            onChange={({target: {value}}) => onValueChange(value)} style={{width: "100%"}}>
                                            <option value={'0'}>{translate("privateArea.payment.selectOption")}</option>
                                            <option value={'1'}>{translate("privateArea.payment.oneInstalment")}</option>
                                            <option value={'2'}>{translate("privateArea.payment.twoInstalment")}</option>
                                            <option value={'3'}>{translate("privateArea.payment.threeInstalment")}</option>
                                            <option value={'4'}>{translate("privateArea.payment.fourInstalment")}</option>
                                        </select>
                                    </div>
                                    {
                                        installmentsNumber? installmentInfo?.map((item) => (
                                            <div key={item.installmentNumber} className='installments-info column'>
                                                <p className='installments-header'>{translate(`privateArea.payment.instalment${item.installmentNumber}`)}</p>
                                                <p className='installment-info'><b>{translate("privateArea.payment.amount")}:</b> {item.amount}</p>
                                                <p className='installment-info'><b>{translate("privateArea.payment.paymentDeadline")}:</b> {getDatabaseFormatedDate(item.deadline.split('T')[0])}</p>
                                            </div>
                                        )) : null
                                    }
                                </>
                            }

                            {
                                selectedMethod &&
                                <div className='action-container row flex-end'>
                                    <button className="button primary-button"
                                        onClick={() => onProceedClick()} 
                                        disabled={isCalculationLoading || (installmentsNumber === 0 && selectedMethod !== 'heidipay')}>
                                        <Spinner size={10} isLoading={isPaymentLoading}/>
                                        {translate('privateArea.payment.proceed')}
                                    </button>
                                </div>
                            }
                        </div>
                    :
                    <>
                        <div
                            className={`method ${selectedMethod === 'heidipay' ? 'selected' : ''}`}
                            onClick={() => handleMethodSelect('heidipay')}
                            >
                            <Spinner size={10} isLoading={isMethodLoading && selectedMethod === 'heidipay'}/>
                            {translate('privateArea.payment.heidipay')}
                        </div>
                        <div
                            className={`method ${selectedMethod === 'banktransfer' ? 'selected' : ''}`}
                            onClick={() => handleMethodSelect('banktransfer')}
                            >
                            <Spinner size={10} isLoading={isMethodLoading && selectedMethod === 'banktransfer'}/>
                            {translate('privateArea.payment.banktransfer')}
                        </div>
                    </>
                }
            </div>
      </div>
    )
}

export default PaymentBody;
