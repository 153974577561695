import { customFetch } from '../api/axios';

export interface UserData{
  username: string | null;
  email: string | null;
  name: string;
  surname: string;
  gender: number;
  dateOfBirth?: string;
  birthPlace?: string;
  address: string;
  city: string;
  province: string;
  fiscalCode?: string;
  startWith?: string;
  allergies?: string;
  additionalInfo?: string;
  disability?: number;
  phoneNumber?: string; 
  children?: any;

  identityDocuments: [{
    identityDocumentType: string;
    identityDocumentId: string;
    releasedDate?: string;
    releasedBy?: string;
    validUntilDate: string;
    nameOnDocument?: string;
    surnameOnDocument?: string;
  }] | [];
}

export const createUser = async (userData: UserData) => {
  try {
    const response = await customFetch.post('/users', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUser = async (userData: UserData) => {
  try {
    const response = await customFetch.put('/users', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const getUserById = async (id: string) => {
  try {
    const response = await customFetch.get('/users', {
      params: { id },
    });
    const responseData = await response.data;

    return responseData;
  } catch (error) {
  }
};