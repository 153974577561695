import React from 'react';
import './PaymentMethodsPage.css';
import { useTranslation } from 'react-i18next';

type PaymentMethodsProps = {
  information: string
};

const PaymentMethodsPage: React.FC<PaymentMethodsProps> = ({information}) => {
    const { t } = useTranslation()
    
    const normalizedString = information.replace(/\r\n/g, '\n').replace(/(\d),/g, '$1.');

    // Split the string into an array of items
    const benefitsList = normalizedString.split('\n').map(item => item.trim());

    return (
        <div className="payment-methods-page">
            <h3 className='text-primary px-2rem d-flex'>{t("paymentMethods.title")}</h3>
            <ul>
              {benefitsList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
        </div>
    );
};

export default PaymentMethodsPage;
