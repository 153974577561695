import React, { useEffect, useMemo } from 'react';
import './DestinationCard.css';
import { useNavigate } from 'react-router-dom';
import { TravelPackage } from '../../views/TravelDestinations/TravelDestinations';
import { cleanDate } from '../../utils/date';
import { useTranslation } from 'react-i18next';

type DestinationCardProps = {
    dest: TravelPackage
    image: string;
};

export type TravelType = { start?: string; end?: string, openToSubscription?: boolean, from: string, cost?: string, id?: string; };

const DestinationCard: React.FC<DestinationCardProps> = ({dest, image }) => {
    const navigate = useNavigate();
    const { t: translate} = useTranslation();

    const handleBookingClick = (dest: TravelPackage) => {
        if(dest.travels) {
            localStorage.setItem('dest', JSON.stringify(dest))
        }
        // You can pass along any booking-related data as state or params
        navigate(`/booking/${dest.name.replace(/\s+/g, '')}`);
    };

    const uniqueStartEndValues = dest.travels.reduce((acc: { start: string; end: string, openToSubscription?: boolean }[], item: { start: string; end: string, openToSubscription?: boolean }) => {
        const exists = acc.some((value) => cleanDate(value.start) === cleanDate(item.start) && cleanDate(value.end) === cleanDate(item.end));
        if (!exists) {
            acc.push({ start: item.start, end: item.end });
        }
        return acc;
    }, []);

    return (
        <div className="destination-card" onClick={() => handleBookingClick(dest)}>
            <div className="destination-image-container">
                <img src={image} alt={`clicca qui e scopri tutti i dettagli di questo pacchetto su ${dest?.destination}`} className="destination-image" />
            </div>
            <div className="destination-content">
                <h2>{dest?.name}</h2>
                {/* <h3>{dest.city}</h3> */}
                <h3 style={{visibility: 'hidden', margin: 0, lineHeight: 0}}>{dest?.country}</h3>
                <h3>{dest?.destination}</h3>
                <p>Turni - 2 settimane</p>
                <ul className="date-list">
                {uniqueStartEndValues?.length? uniqueStartEndValues.map ((date: {start: string, end: string}) => {
                    return(
                        <li key={date.start}>{new Date(date.start.slice(0,10)).toLocaleDateString('en-GB')} - {new Date(date.end.slice(0,10)).toLocaleDateString('en-GB')} <span className="promo-tag">PROMO</span></li>
                    )
                }) : 
                    <p className='sold-out'>{translate('booking.soldOut')}</p>
                }
                </ul>
                <div className="booking-section">
                    <div className="price-info">
                        <span className="full-price">{`€${dest?.totalCost} `} Sconto preiscritti</span>
                        <span className="discounted-price">{`€${dest?.discountedCost} `} </span>
                    </div>
                    {
                        uniqueStartEndValues?.length < 1 ? "" : 
                        <>
                           <button disabled={uniqueStartEndValues?.length < 1} className="book-button" onClick={() => handleBookingClick(dest)}>Pre Iscriviti ORA</button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default DestinationCard;
