import React, { useState, useEffect, useRef } from 'react';
import './NavigationBar.css';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMobileSize } from '../../hooks/useMobileSize';
import Drawer from '@mui/material/Drawer';
import { AiOutlineBook, AiOutlineForm, AiOutlineLogin, AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';

type NavigationBarProps = {
    transparent?: boolean;
    alternative?: boolean;
    isSearchShown?: boolean;
};

const NavigationBar: React.FC<NavigationBarProps> = ({transparent = true, alternative = false, isSearchShown = true}) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const mobileSize = useMobileSize()
    const token = localStorage.getItem("accessToken")

    const handleSearch = (event: React.FormEvent) => {
        event.preventDefault();
        navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleScroll = () => {
           const offset = window.pageYOffset;
           setIsScrolled(offset > 0);
       };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    const handleLogoutClick = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        setIsMenuOpen(false);
        navigate('/login')
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
           window.addEventListener('scroll', handleScroll);
           return () => window.removeEventListener('scroll', handleScroll);
       }, []);
    return (
        <nav className='navigation-bar scrolled'>
            <img src={logo} alt="Logo" className="main-logo" onClick={handleHomeClick}/>
            <div className='navbar-right-wrapper'>
                {
                    !mobileSize &&
                    (token === null ?
                        <>
                            <p className='menu-item'>
                                <Link to="/login">
                                    <AiOutlineLogin style={{margin: '0 4px', marginBottom: '-2px'}}/>
                                    {t('menu.thirdItem')}
                                </Link>
                            </p>
                            <p className='menu-item'>
                                <Link to="/signup">
                                    <AiOutlineForm style={{margin: '0 4px', marginBottom: '-2px'}}/>
                                    {t('menu.fourthItem')}
                                </Link></p>
                        </>
                        :
                        <>
                            <p className='menu-item'><Link to="/booking/list">
                                <AiOutlineBook style={{margin: '0 4px', marginBottom: '-2px'}}/>
                                {t('privateArea.bookingList')}
                                </Link>
                            </p>
                            <p className='menu-item' onClick={() => handleLogoutClick()}>
                                <AiOutlineLogout style={{margin: '0 4px', marginBottom: '-2px'}}/>
                                {t('menu.fifthItem')}
                            </p>
                        </>)
                }

                <LanguageSwitcher switchColor={alternative && !isScrolled} />
                {
                    mobileSize && 
                      <React.Fragment key='left'>
                        <button onClick={toggleMenu} className="hamburger-menu">☰</button>
                            <Drawer
                                anchor='top'
                                open={isMenuOpen}
                                onClose={toggleMenu}
                            >
                                <div className='menu-drawer'>
                                    <div className='drawer-logo'>
                                        <img src={logo} alt="Logo" className="main-logo" onClick={handleHomeClick}/>
                                    </div>
                                    <div className='drawer-container column align-center'>
                                        {token === null ?
                                                <div>
                                                <p className='drawer-item'>
                                                    <Link to="/login">
                                                        <AiOutlineLogin style={{margin: '0 4px'}}/>
                                                        {t('menu.thirdItem')}
                                                    </Link>
                                                </p>
                                                <p className='drawer-item'>
                                                    <Link to="/signup">
                                                        <AiOutlineForm style={{margin: '0 4px'}}/>
                                                        {t('menu.fourthItem')}
                                                    </Link>
                                                </p>
                                                </div> :
                                                <div>
                                                <p className='drawer-item'>
                                                    <Link to="/booking/list">
                                                        <AiOutlineBook style={{margin: '0 4px'}}/>
                                                        {t('privateArea.bookingList')}
                                                    </Link>
                                                </p>
                                                <p className='drawer-item' onClick={() => handleLogoutClick()}>
                                                    <AiOutlineLogout style={{margin: '0 4px'}}/>
                                                    {t('menu.fifthItem')}
                                                </p>
                                                </div>
                                            }
                                    </div>
                                </div>
                            </Drawer>
                        </React.Fragment>
                }
            </div>
        </nav>
    );
};

export default NavigationBar;
