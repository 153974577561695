const toLowerCase = (value: string) => {
    if (!value || typeof value !== 'string') return;

    return value.toLowerCase();
}

const isStringDefined = (value: string | undefined) => {
    if ((typeof value === "string" && value.trim().length === 0) || value === null || value === undefined) {
        return false;
    }

    return value;
}

export {
    toLowerCase,
    isStringDefined
}
