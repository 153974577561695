import jsPDF from "jspdf";
import { resetFontSize } from "../pdf";

export const generateInvoicePDF = async ({bookingInfo,userInfo,travelerInfo, travelPackageInfo, invoiceNumber}: {bookingInfo:any,userInfo:any,travelerInfo:any, travelPackageInfo:any, invoiceNumber:string}) => {
    // WIDTH: 210 mm
    // HEIGHT: 297 mm
  var doc = new jsPDF();

  const startDate = new Date(bookingInfo.travel.start);
  const endDate = new Date(bookingInfo.travel.end);

  //@ts-ignore
  const differenceInMilliseconds = endDate - startDate;


  const differenceInDays = (differenceInMilliseconds / (1000 * 60 * 60 * 24)) +1;

  const currentDate = new Date();

  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const year = currentDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

//   const img = new Image();
//   img.onload = async function () {
//     //@ts-ignore
//     doc.addImage(this, 10, 5, 33, 10, "", "FAST");
//     // doc.save("invoice_sale_scuola_viaggi");

//     return doc;
//   };

  await new Promise<void>((resolve, reject) => {
    var img = new Image();
    // TODO: check if we need to put 'anonymous'
    img.crossOrigin = ""; // for demo as we are at different origin than image
    img.src = "/saleLogo.png";
    img.onload = function () {
        //@ts-ignore
        doc.addImage(this, 10, 5, 33, 10, "", "FAST");
        resolve();
    };
    img.onerror = function() {
        reject(new Error("Failed to load image"));
    };
  });

  function addFooter(page: number) {
    doc.setFontSize(8)
    doc.text(`Pag. ${page} di 1`, 180, 290);

    doc.text(
      "Sale Scuola Viaggi di Saled srl–s.o. Viale Principe Amedeo 7. Rimini(RN) – Tel:0541/1741120, Fax:0541/792982",
      30,
      285
    );
    doc.text(
      "P.IVA e C/F.02814460412– inps@salescuolaviaggi.com, www.salescuolaviaggi.com",
      45,
      290
    );
    resetFontSize(doc)
  }

  function addTableCell(
    doc: jsPDF,
    text: string,
    x: number,
    y: number,
    width: number,
    height: number,
    al?: string
  ) {
    //@ts-ignore
    doc.cell(x, y, width, height, " ", undefined);
    //@ts-ignore
    doc.text(text, x + 1, y + height-4, {align:al});
  }
  
  doc.setTextColor("#666666")

  doc.setFontSize(11)

  doc.text([`${userInfo.name ?? ""} ${userInfo.surname ?? ""}`,
  ``,
  `${userInfo.address ?? ""}`,
  ``,
  `${userInfo.city ?? ""} ${userInfo.province ?? ""}`,
  ``,
  `CF: ${userInfo.fiscalCode ?? ""}`
], 120, 40)
  doc.text([`Fattura ${invoiceNumber} DEL ${formattedDate}`,
  ``,
  `Rif. Pratica: ${bookingInfo.contractNumber ?? ""}`,
  ``,
  `Importo soggiorno Estate INPSieme 2024 per Soggiorni Studio all'Estero e Vacanze Tematiche in Italia`,
], 15, 80)

//@ts-ignore
doc.cell(15, 100, 130, 10, " ", undefined);
doc.text("Descrizione", 80, 100 + 6, {align:"center"});

//@ts-ignore
doc.cell(145, 100, 40, 10, " ", undefined);
doc.text("Importo totale", 165, 100 + 6, {align:"center"});

addTableCell(doc, "", 15, 110, 130, 10)
addTableCell(doc, "", 145, 110, 40, 10)
//@ts-ignore
doc.cell(15, 120, 130, 100, " ", undefined)
//@ts-ignore
doc.cell(145, 120, 40, 100, " ", undefined)

doc.text([`Beneficiario: ${travelerInfo.name ?? ""} ${travelerInfo.surname ?? ""}`,
`C.F.: ${travelerInfo.fiscalCode ?? ""}`], 25, 125, {maxWidth:115});

doc.text([`Soggiorno a ${travelPackageInfo.destination ?? ""} ${differenceInDays} giorni / ${differenceInDays-1} notti. ${travelPackageInfo.stayName ?? ""}`,
`€ ${bookingInfo.totalCost / ((bookingInfo.caregivers ? bookingInfo.caregivers?.length : 0) +1)}`,
`viaggio andata e ritorno`,
`pensione completa`,
`assistenza e accompagnamento`,
`pulizia settimanale`,
`assicurazione medico - bagaglio e annullamento`], 25, 150, {maxWidth:115});

if(travelerInfo.disability && bookingInfo.caregivers?.length > 0)
doc.text([`Numero accompagnatori: ${bookingInfo.caregivers?.length}`,
`${bookingInfo.caregivers?.map((c: any) => `${c.name} ${c.surname}`).join(', ')}`], 25, 190, {maxWidth:115});

doc.text([`€ ${new Intl.NumberFormat('it', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).format(bookingInfo.totalCost)}`], 155, 150);

  doc.setFontSize(11)
  doc.text("Fattura non imponibile IVA emessa ai sensi dell’art. 74ter DPR 633/72 e successive modifiche.", 100, 260, {align: "center"})

  addFooter(1);

  return doc.output("datauristring").split("data:application/pdf;filename=generated.pdf;base64,")[1];
};
