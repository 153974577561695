import { toast } from 'react-toastify';
import { API_BASE_URL, customFetch } from "../api/axios";

interface ResetData {
  email: string;
}

interface ResetConfirmData {
  token: string;
  password: string;
}

export const resetPassword = async (data: ResetData): Promise<any> => {
  try {
    const response = await customFetch.post(`${API_BASE_URL}/api/auth/passwordrecovery`,
      data
  );

    toast.success("Ti abbiamo inviato una email con il link per creare un'altra password.", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return true
  } catch (error) {
    console.error('There was an error logging in:', error);
  }
};

export const resetPasswordConfirm = async (data: ResetConfirmData): Promise<any> => {
  try {
    const response = await customFetch.post(`${API_BASE_URL}/api/auth/passwordreset`,
      data
  );
    toast.success("La tua password è stata aggiornata", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return true
  } catch (error) {
    console.error('There was an error logging in:', error);
  }
};
