import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserData, updateUser } from "../../../services/userService";
import { getDatabaseFormatedDate } from "../../../utils/date";
import { toast } from "react-toastify";

type UserFormData = {
    name: string;
    surname: string;
    gender: string;
    address: string;
    province: string;
    city: string;
    phoneNumber: string;
    email: string;
    destination: string;
    fiscalCode: string;
    identityDocumentId?: string;
    validUntilDate?: string;
    children?: any
}

interface UserFormProps {
    userInfo: UserFormData;
    isEditForm?: boolean;
    onEditClick?: () => void;
    onNextClick?: () => void;
    onCurrentUserChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const UserForm = ({userInfo, isEditForm, onEditClick, onCurrentUserChange, onNextClick}: UserFormProps) => {
    const { t: translate } = useTranslation();
    const userData = useRef<UserFormData>(userInfo)
    const [areDataChanged, setAreDataChanged] = useState(false);

    useEffect(() => {
        userData.current = userInfo;
    }, [userInfo])

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event && event.target) {
            setAreDataChanged(true);
            const inputName = event.target.name as keyof UserFormData;
            userData.current[inputName] = event.target.value;
            
            onCurrentUserChange(event)
        }
    }

    const onNextStepClick = (event: FormEvent) => {
        event.preventDefault();
        if (areDataChanged) {
            const updatedUser: UserData = {
                name: userData.current.name,
                surname: userData.current.surname,
                phoneNumber: userData.current.phoneNumber,
                city: userData.current.city,
                province: userData.current.province,
                address: userData.current.address,
                email: userData.current.email,
                fiscalCode: userData.current.fiscalCode,
                username: userData.current.email,
                gender: userData.current.gender === "F" ? 2 : 1,
                identityDocuments: [{
                    identityDocumentId: userData.current.identityDocumentId ?? '',
                    validUntilDate: getDatabaseFormatedDate(userData.current.validUntilDate ?? ''),
                    identityDocumentType: 'idcard'
            }]};
            updateUser(updatedUser).then((response) => {
                if (response) {
                    const currentUser = JSON.parse(localStorage.getItem('userInfo') ?? '');
                    localStorage.setItem('userInfo', JSON.stringify({currentUser, ...updatedUser}));
                    onNextClick && onNextClick();
                    onEditClick && onEditClick();
                    toast.success(translate('shared.updateMessage'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch((error) => {
                console.log(error)
            })
        } else {
            onNextClick && onNextClick();
            onEditClick && onEditClick();
        }
    }

    return (
        <form onSubmit={(event) => onNextStepClick(event)}>
            <div className="personal-information-form">
                {!isEditForm && 
                    <h3>{translate("form.firstStepForm.title")}</h3>
                }

                <div className="user-form row">
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.name")}*</label>
                            <input className="field input-field" type="text" name="name"
                                placeholder={translate("form.firstStepForm.name")} required
                                value={userData.current.name} onChange={(event) => onValueChange(event)} />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.surname")}*</label>
                            <input className="field input-field" type="text" name="surname"
                                placeholder={translate("form.firstStepForm.surname")} required
                                value={userData.current.surname} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.address")}*</label>
                            <input className="field input-field" type="text" name="address"
                                placeholder={translate("form.firstStepForm.address")} required
                                value={userData.current.address  || ""} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.mobile")}</label>
                            <input className="field input-field" type="text" name="phoneNumber"
                                placeholder={translate("form.firstStepForm.mobile")}
                                value={userData.current.phoneNumber ?? ''} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                     <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.city")}</label>
                             <input className="field input-field" type="text" name="city" placeholder={translate("form.firstStepForm.city")}
                                value={userData.current.city  || ""} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                     <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.province")}</label>
                            <input className="field input-field" type="text" name="province" placeholder={translate("form.firstStepForm.province")}
                                value={userData.current.province || ""} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.email")}*</label>
                            <input className="field input-field" type="email" name="email" placeholder={translate("form.firstStepForm.email")}
                                value={userData.current.email} onChange={(event) => onValueChange(event)} required />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.fiscalCode")}*</label>
                            <input className="field input-field" type="text" required
                                name="fiscalCode" placeholder={translate("form.firstStepForm.fiscalCode")}
                                value={userData.current.fiscalCode} onChange={(event) => onValueChange(event)} />
                        </div>
                    </div>

                    <div className="col-12 d-flex mx-8 pb-6">
                        <h4 className="identity-card">{translate('form.firstStepForm.idCard')}</h4>
                    </div>
                    
                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.identityDocumentId")}*</label>
                            <input className="field input-field" type="text" name="identityDocumentId"
                                placeholder={translate("form.firstStepForm.identityDocumentId")} required
                                value={userData.current.identityDocumentId  || ""} onChange={(event) => onValueChange(event)}  />
                        </div>
                    </div>

                    <div className="col-6 pb-6">
                        <div className="column">
                            <label className="input-label">{translate("form.firstStepForm.validUntilDate")}*</label>
                            <input
                                type="date"
                                className="field input-field"
                                name="validUntilDate" required
                                placeholder={translate("form.firstStepForm.validUntilDate")}
                                value={userData.current.validUntilDate}
                                onChange={onValueChange}
                            />
                        </div>
                    </div>

                    {
                        !isEditForm &&
                        <div className="col-12">
                            <div className="column">
                                <label className="input-label">{translate("form.firstStepForm.destination")}</label>
                                <input className="field input-field" type="text" name="destination" placeholder={translate("form.firstStepForm.destination")}
                                    value={userData.current.destination} onChange={(event) => onValueChange(event)} readOnly  />
                            </div>
                        </div>
                    }

                    <div className="personal-information-footer row col-12">
                        {
                            isEditForm ?
                                <button style={{ width: '48%' }} className="button primary-button"
                                    onClick={() => onEditClick && onEditClick()}>{translate("form.previous")}</button>
                                : null
                        }
                        <button style={{ width: '48%'}} className="button primary-button"
                            type="submit" id={isEditForm ? "" : "FirstButton"}>{translate(isEditForm ? "form.save" : "form.send")}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default UserForm;
