import React from 'react';
import './Stepper.css';

type StepperProps = {
    steps: string[];
    currentStep: number;
};

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
    return (
        <div className="stepper-container">
            {steps.map((step, index) => (
                <div key={step} className={`step ${currentStep === index ? 'active' : ''}`}>
                    {step}
                </div>
            ))}
        </div>
    );
};

export default Stepper;
