import React from 'react';
import './InformationBlock.css';
import { useTranslation } from 'react-i18next';

const InformationBlock: React.FC = () => {
    const {t} = useTranslation()
    return (
        <div className="information-block">
            <p className="info-text">
                {t("explore.info.text")}
            </p>
            <p className="info-subtext">
                {t("explore.info.subtext")}
            </p>
            <h2 className="info-header">
                {t("explore.info.header")}
            </h2>
            <p className="info-text">
                {t("explore.info.secondText")}
            </p>
        </div>
    );
};

export default InformationBlock;
