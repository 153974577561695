import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './LoginPage.css';
import GoogleIcon from '../../assets/google-icon.png';
import { loginUser, signInWithGoogle } from '../../services/authService';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import NavigationBar from '../../components/NavigationBar/NavigationBar';

export const ICON_SIZE = {
    width: '20px',
    height: '20px'
}

const LoginPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const { state } = useLocation();

    const restorePathHistory = (path: string) => {
        navigate(path, {
            state: {
                redirectUrl: state?.redirectUrl,
                params: state?.params
            }
        })
    }

    const handleLogin = async () => {
        setIsLoading(true);
        setLoginError(false);
        try {
            await loginUser({
                username: email,
                password: password,
            });

            setIsLoading(false);
            navigate(state?.redirectUrl ?? '/', {
                state: state?.params ?? null
            });
        } catch (error) {
            console.error('There was an error logging in:', error);
            setLoginError(true);
            setIsLoading(false);
        }
    };

    const handleCallBack = useCallback(async (event: CredentialResponse) => {
        setIsLoading(true);
        if (event.credential) {
            try {
                await signInWithGoogle({token: event.credential});
                    setIsLoading(false);
                    setLoginError(false);
                    navigate(state?.redirectUrl ?? '/', {
                    state: state?.params ?? null
                });
            } catch (error) {
                console.error('There was an error logging in:', error);
                setLoginError(true);
                setIsLoading(false);
            }
        }
    }, [navigate])

    useEffect(() => {
        const initializeGsi = () => {
          if (!window.google || gsiScriptLoaded) return
    
          setGsiScriptLoaded(true)
          window.google.accounts.id.initialize({
            client_id: '835417009004-gd7hoelg6ejhnpbvu1kd2kgtst1mcog7.apps.googleusercontent.com',
            callback: handleCallBack,
          })
        }
    
        const script = document.createElement("script")
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body")?.appendChild(script)
    
        return () => {
          window.google?.accounts.id.cancel()
          document.getElementById("google-client-script")?.remove()
        }
      }, [gsiScriptLoaded, handleCallBack])
    return (
        <>
            <NavigationBar alternative={true} isSearchShown={false}/>
            <div className="login-page">
                <h2>{t("menu.thirdItem")}</h2>
                <div className="social-login-buttons">
                    <button className={"g_id_signin social-login google"}>
                        <img src={GoogleIcon} alt="Google" />
                        {t("login.google")}
                    </button>
                </div>
                <input
                    type="email"
                    placeholder={t("login.email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <div className="password-input">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder={t("login.password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span
                        className="show-password-icon"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <AiFillEyeInvisible style={{ ...ICON_SIZE, fill: '#ff5d10'}}/> : <AiFillEye style={ICON_SIZE}/>}
                    </span>
                </div>
                <button className="login-button" onClick={handleLogin}>{t("menu.thirdItem")}</button>
                {loginError ? <p className="error-text">{t("login.error")}</p> : <></>}
                {isLoading ? <p>{t("login.loginLoading")}</p> : <></>}
                <p className="signup-text">{t("login.loginQuestion")} <a className="account-links" onClick={() => restorePathHistory('/signup')}>{t("menu.fourthItem")}</a></p>
                <p className="signup-text">{t("login.forgotQuestion")} <a className='account-links' onClick={() => restorePathHistory('/forgot-password')}>{t("menu.sixthItem")}</a></p>
            </div>
        </>
    );
};

export default LoginPage;
