import React, { useEffect, useMemo, useState } from "react";
import "./BookingPage.css";
import MediaPage from "../MediaPage";
import PaymentMethodsPage from "../PaymentMethodsPage";
import BookingContactForm from "../BookingContactForm";
import AccordionSection from "../AccordionSection";

import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Footer from "../../components/Footer/Footer";
import FeatureCard from "../../components/FeatureCard/";

import customerService from "../../assets/customer-service.png";
import placeholder from "../../assets/placeholder.png";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFilteredTravelPackages } from "../../services/travelService";
import LandingPageAcademy from "./LandingPageAcademy";
import Loading from "../../components/Loading/Loading";
import { TravelType } from "../../components/DestinationCard/DestinationCard";
import { formatDate } from "../../utils/date";
import DOMPurify from 'dompurify';


interface Travel {
  from: string;
  start: string;
  end: string;
  cost?: string;
}

interface TravelPackage {
  id: string;
  name: string;
  destination: string;
  country?: string;
  continent?: string;
  languages: string[];
  city: string;
  travelTheme?: string;
  schoolLevel?: string;
  start: string;
  end: string;
  subscriptionsStart: string;
  subscriptionsEnd: string;
  targetAgeMin?: number;
  targetAge?: number;
  claimText?: string;
  claimTimeLenght?: string;
  description: string;
  imageName: {
    localPath: string;
  };
  totalCost: number;
  stayName: string;
  stayDescription: string;
  roomDescription: string;
  extrasDescription: string;
  languageCourseDescription: string;
  discountedCost: number;
  excursionsDescription: string;
  financingDescription: string;
  travels: Travel[];
  images: {
    id: string;
    description: string;
    description2: string;
    localPath: string;
    title: string;
  }[];
  video: string;
}

type FilterState = {
  language: string;
  ageMin: string;
  age: string;
  continent: string;
  country: string;
  travelTheme: string;
  schoolLevel: string;
  sentToGroups: string;
  contentType: string;
};

// Initialize your filters with the expected structure
const initialFilterState: FilterState = {
  language: "",
  ageMin: "",
  age: "",
  continent: "",
  country: "",
  travelTheme: "",
  schoolLevel: "",
  sentToGroups: "",
  contentType: "",
};

const BookingPage: React.FC = () => {
  const { t } = useTranslation();
  const { destName } = useParams<{ destName: string }>();
  const [dest, setDest] = useState<TravelPackage | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const sanitizedHTML = dest ? DOMPurify.sanitize(dest?.excursionsDescription) : "";

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTravelPackages = async () => {
      try {
        setIsLoading(true);
        const data = await fetchFilteredTravelPackages(initialFilterState);
        setIsLoading(false);
        if (destName) {
          const foundPackage = data.find(
            (travelPackage: { name: string }) =>
              travelPackage.name.replace(/\s+/g, "") === destName
          );
          if (foundPackage) {
            setDest(foundPackage);
          }
        }
      } catch (error) {
        setIsLoading(false);
        navigate("/");
        throw error;
      }
    };

    fetchTravelPackages();
  }, []);

  const getDepartureBasedOnDates = useMemo(() => {
    let arrayBasedOnDates: {
      start: string;
      end: string;
      departures: { cost: string; subscription: boolean; from: string }[];
    }[] = [];
    if (dest) {
      const travels: TravelType[] = dest.travels;
      for (const travel of travels) {
        const startDate = formatDate(travel?.start ?? '');
        const endDate = formatDate(travel?.end ?? '');
        const existingIndex = arrayBasedOnDates.findIndex(
          (item) => item.start === startDate && item.end === endDate
        );
        if (existingIndex !== -1) {
          arrayBasedOnDates[existingIndex].departures.push({
            cost: travel.cost || "",
            subscription: !!travel.openToSubscription,
            from: travel.from || "",
          });
        } else {
          arrayBasedOnDates.push({
            start: startDate,
            end: endDate,
            departures: [
              {
                cost: travel.cost || "",
                subscription: !!travel.openToSubscription,
                from: travel.from || "",
              },
            ],
          });
        }
      }
    }

    return arrayBasedOnDates;
  }, [dest, dest?.travels]);

  const featureCardsData = [
    {
      icon: placeholder,
      title: dest ? dest.stayName : "",
      text: dest ? dest.stayDescription : "",
      linkText: dest ? dest.stayDescription : "",
    },
    {
      icon: customerService,
      title: t("booking.cards.fifthCard.title"),
      text: dest ? dest.extrasDescription : "",
      linkText: dest ? dest.extrasDescription : "",
    },
  ];

  const handleBookingClick = () => {
    if (token === null) {
      navigate("/login", {
        state: {
          redirectUrl: "/form",
        },
      });
    } else {
      navigate("/form");
    }
  };

  return (
    <div>
      <NavigationBar alternative={true} />
      {!dest ? (
        <Loading />
      ) : (
        <>
          <div className="col align-center">
            <div className="app-container">
              <LandingPageAcademy
                title={dest ? dest.destination : ""}
                description={dest ? dest.name : ""}
                img={dest ? dest.imageName.localPath : ""}
                images={dest ? dest.images : []}
                price={dest ? dest.discountedCost : ""}
                dates={getDepartureBasedOnDates}
                ageMin={dest ? dest.targetAgeMin : ""}
                language={dest ? dest.languageCourseDescription : ""}
                roomDescription={dest ? dest.roomDescription : ""}
              />

              <div className="extra-info px-2rem">
                <p className="availability-note">
                  {t("booking.availabilityNote")}
                </p>
                <p className="contact-note">
                  {t("booking.contactNoteFirstPart")}
                  <br />
                  {t("booking.contactNoteSecondPart")}
                </p>
              </div>

              <div className="features-container px-2rem">
                {featureCardsData?.map((featureCard, index) => (
                  <FeatureCard
                    key={index}
                    icon={featureCard.icon}
                    title={featureCard.title}
                    text={featureCard.text}
                  />
                ))}
              </div>


              {dest && dest?.excursionsDescription ? (
                <div className="description-label-container">
                  <h2>Escursioni e attività</h2>
                </div>
              ) : (
                null
              )}
              <div className="description-container">
                <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}/>
              </div>


              <button className="booking-btn" onClick={handleBookingClick}>
                {t("booking.preSignUp")}
              </button>
              <MediaPage
                images={dest ? dest.images : []}
                video={dest ? dest.video : ""}
              />
              <PaymentMethodsPage
                information={dest ? dest.financingDescription : ""}
              />
              <AccordionSection />
              <button className="booking-btn" onClick={handleBookingClick}>
                {t("booking.preSignUp")}
              </button>
              <BookingContactForm destination={dest ? dest.name : ""} />
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default BookingPage;
