import React from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname + location.search;
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: path,
      },
    });
  }, [location]);
};

const PageTracker = ({ children }: any) => {
  usePageTracking();
  return children;
};

export default PageTracker;
