import { useTranslation } from "react-i18next";
import formDestination from "../../assets/images/form-destination.png";
import { useFormLogic } from "./formUtils";
import UserForm from "./formSteps/userForm";
import ChildForm from "./formSteps/childForm";
import { useEffect, useMemo, useState } from "react";
import Spinner from "../Spinner/spinner";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { IoMdAirplane } from "react-icons/io";
import { TravelType } from "../DestinationCard/DestinationCard";
import { formatDate } from "../../utils/date";

export const RenderForm = (
  currentStep: number,
  setCurrentStep: (step: number) => void
) => {
  const { t } = useTranslation();
  const destdata = localStorage.getItem("dest");
  const destination = destdata ? JSON.parse(destdata) : null;
  const userInfoFromStorage = localStorage.getItem("userInfo");
  // const userInfo = userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null;
  const [voucherId, setVoucherId] = useState<string | undefined>();
  const [hasVoucher, setHasVoucher] = useState<boolean>();

  const {
    handleDateChange,
    handleDestChange,
    confirmBooking,
    onNextStepClick,
    onFirstFormNextClick,
    handleOptionChange,
    bookingError,
    bookingLoading,
    totalCost,
    optionGroups,
    selectedOptions,
    currentUserFormData,
    childFormData,
    onCurrentUserChange,
    onSecondFormNextClick,
    onSecondFormPreviousClick,
    setSelectedDate,
    selectedDate,
  } = useFormLogic(currentStep, setCurrentStep);
  const [selectedDeparture, setSelectedDeparture] = useState<string>();
  const getDepartureBasedOnDates = useMemo(() => {
    let arrayBasedOnDates: {
      start: string;
      end: string;
      departures: TravelType[];
    }[] = [];
    if (destination) {
      const travels: TravelType[] = destination.travels;
      for (const travel of travels) {
        const startDate = formatDate(travel?.start ?? "");
        const endDate = formatDate(travel?.end ?? "");
        const existingIndex = arrayBasedOnDates.findIndex(
          (item) => item.start === startDate && item.end === endDate
        );
        if (existingIndex !== -1) {
          arrayBasedOnDates[existingIndex].departures.push({
            cost: travel.cost || "",
            openToSubscription: !!travel.openToSubscription,
            from: travel.from || "",
            id: travel.id || "",
          });
        } else {
          arrayBasedOnDates.push({
            start: startDate,
            end: endDate,
            departures: [
              {
                cost: travel.cost || "",
                openToSubscription: !!travel.openToSubscription,
                from: travel.from || "",
                id: travel.id || "",
              },
            ],
          });
        }
      }
    }

    return arrayBasedOnDates;
  }, [destination, destination?.travels]);

  useEffect(() => {
    setSelectedDate(getDepartureBasedOnDates[0]?.start);
  }, []);

  useEffect(() => {
      getDepartureBasedOnDates &&
        getDepartureBasedOnDates?.filter((item, index) => {
          if (item?.start === selectedDate) {
            setSelectedDeparture(item?.departures[0]?.from);
          }
          return null;
        });
  },[selectedDate]);

  const handleChange = (value: any) => {
    setSelectedDeparture(value);
    handleDestChange(value);
  };

  const renderForm = () => {
    switch (currentStep) {
      case 0:
        return (
          <UserForm
            userInfo={currentUserFormData}
            onCurrentUserChange={(event) => onCurrentUserChange(event)}
            onNextClick={() => onFirstFormNextClick()}
          />
        );
      case 1:
        return (
          <ChildForm
            userInfo={currentUserFormData}
            childInfo={childFormData}
            onPreviousClick={(selectedChild) =>
              onSecondFormPreviousClick(selectedChild)
            }
            onNextClick={(selectedChild, caregivers) =>
              onSecondFormNextClick(selectedChild, caregivers)
            }
          />
        );
      case 2:
        return (
          <form
            onSubmit={onNextStepClick}
            className="destination-information-form"
          >
            <h3>{t("form.fourthStepForm.title")}</h3>
            <div className="form-field" id="formFieldRow">
              <img
                src={destination?.imageName?.localPath ?? formDestination}
                alt="Destination"
                className="form-destination-image"
              />
              <div className="destination-form-field" id="formFieldRow">
                <p>{t("form.fourthStepForm.selectDate")}</p>

                <div>
                  {getDepartureBasedOnDates?.map((item, index: number) => (
                    <div
                      key={index}
                      className={`col-6 mb-8 ${
                        item.departures[0]?.openToSubscription
                          ? ""
                          : "line-through"
                      }`}
                    >
                      <input
                        type="radio"
                        name="dateRange"
                        value={item?.start}
                        checked={selectedDate === item?.start}
                        onChange={() => handleDateChange(item?.start)}
                      />
                      <label>
                        {item?.start} - {item?.end}
                      </label>
                    </div>
                  ))}
                </div>

                <label htmlFor="startingPoint" className="startingPoint">
                  {t("form.fourthStepForm.selectPoint")}
                </label>

                <TextField
                  select
                  label=""
                  value={selectedDeparture ?? getDepartureBasedOnDates}
                  onChange={(e) => handleChange(e.target.value)}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <IoMdAirplane
                        style={{ color: "#ff5d10", paddingRight: "2px" }}
                      />
                    ),
                  }}
                >
                  {getDepartureBasedOnDates &&
                    getDepartureBasedOnDates?.map((item, index) => {
                      if (item.start === selectedDate) {
                        return item?.departures?.map((departure, idx) => (
                          <MenuItem
                            key={departure?.from}
                            value={departure?.from}
                          >
                            {departure?.from}
                            {departure?.cost ? ` + ${departure?.cost}€` : ""}
                          </MenuItem>
                        ));
                      }
                      return null;
                    })}
                </TextField>

                {getDepartureBasedOnDates &&
                  getDepartureBasedOnDates?.map((item, index) => {
                    if (item?.start === selectedDate) {
                      return item?.departures?.map((departure, idx) => {
                        if (departure?.from === selectedDeparture) {
                          return (
                            <span
                              key={idx}
                              className="discounted-price"
                              style={{ textAlign: "end" }}
                            >
                              {departure?.cost != null
                                ? `€${
                                    destination?.discountedCost + departure?.cost
                                  }`
                                : `€${destination?.discountedCost}`}
                            </span>
                          );
                        }
                        return null;
                      });
                    }
                    return null;
                  })}
              </div>
            </div>
            <div
              className="form-field"
              id="formFieldRow"
              style={{ flexDirection: "row" }}
            >
              <button
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                {t("form.previous")}
              </button>
              <button type="submit" id="third-step-confirm">{t("form.next")}</button>
            </div>
          </form>
        );
      case 3:
        return (
          <div className="step-space">
            <form
              onSubmit={onNextStepClick}
              className="personal-information-form"
            >
              <h3>{t("form.fifthStepForm.title")}</h3>
              <div className="two-option-cards">
                {optionGroups &&
                  optionGroups.length !== 0 &&
                  optionGroups[0].options.map(
                    (option: {
                      id: string;
                      name: string;
                      cost: number;
                      description: string;
                    }) => {
                      return (
                        <div className="option-card" key={option.id}>
                          <input
                            type="checkbox"
                            id={option.id}
                            name={option.name}
                            checked={selectedOptions[option.name] || false}
                            onChange={(e) => handleOptionChange(e, option)}
                          />
                          <div className="option-card-info">
                            <h4>{option.name}</h4>
                            <span>{option?.cost}</span>
                          </div>
                          <p>{option.description}</p>
                        </div>
                      );
                    }
                  )}
              </div>
            </form>
            <div className="summary-section row">
              <div className="col-4">
                <div className="column px-8">
                  <h4>{t("form.information_and_economic_summary")}</h4>
                  <p>
                    {t("form.selected_package")} {destination?.name || ""}
                  </p>
                  <p>
                    {t("form.package_cost")}: €{totalCost?.toFixed(2)}
                  </p>
                </div>
                <div className="voucher-section column">
                  <div className="disability-section">
                    <input
                      type="checkbox"
                      id="voucher-question"
                      name="voucher-question"
                      className="field"
                      onChange={() => setHasVoucher(!hasVoucher)}
                      checked={hasVoucher}
                    />
                    <label htmlFor="voucher-question">
                      {t("form.voucherQuestion")}
                    </label>
                    <br />
                  </div>
                  {hasVoucher && (
                    <input
                      className="field input-field"
                      type="text"
                      name="voucher"
                      placeholder="Voucher code"
                      value={voucherId}
                      onChange={({ target }) => setVoucherId(target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="form-field"
              id="formFieldRow"
              style={{ flexDirection: "row" }}
            >
              <button
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                {t("form.previous")}
              </button>
              <button
                id="confirm-booking-button"
                type="submit"
                onClick={() => confirmBooking(voucherId, destination?.name, selectedDeparture, destination?.discountedCost)}
                disabled={bookingLoading}
              >
                {t("form.next")}
                <Spinner size={10} isLoading={bookingLoading} />
              </button>
            </div>
            {bookingError && (
              <h4 className="error-text">
                {t("form.fifthStepForm.errorText")}
              </h4>
            )}
          </div>
        );
      default:
        return <div>{t("form.defaultForm.title")}</div>;
    }
  };

  return <div>{renderForm()}</div>;
};
