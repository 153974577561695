import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResetPage.css';
// Import your social media icons if you have them
import { resetPassword } from '../../services/resetPasswordService';
import { useTranslation } from 'react-i18next';


const ResetPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [resetError, setResetError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleReset = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        setResetError(false)
        try {
            const response = await resetPassword({
                email: email,
            });
            if (response) {
              setResetError(false)
              navigate('/login', {
                    state: {
                        redirectUrl: '/form'
                    }
                });
            }
        } catch (error) {
            console.error('There was an error logging in:', error);
            setResetError(true)
        }
        setIsLoading(false);
    };

    return (
        <div className="login-page">
            <h2>{t("passwordReset.title")}</h2>
            <input
                 type="email"
                 placeholder={t("login.email")}
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 required
             />
            <button className="login-button" onClick={handleReset}>{t("passwordReset.buttonText")}</button>
            {resetError ? <p className="error-text">{t("login.error")}</p> : <></>}
            {isLoading ? <p>{t("login.loginLoading")}</p> : <></>}
            <p className="signup-text">{t("passwordReset.resetQuestion")} <a href="/login">{t("passwordReset.backToLogin")}</a></p>
        </div>
    );
};

export default ResetPage;
