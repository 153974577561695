import { customFetch } from "../api/axios";

type IntstalmetCalculation = {
    installmentsNumber: number;
    country: string;
    totalCost: number | undefined;
    startDate: string;
}

export const getInstallmentsCalculation = async (parameters: IntstalmetCalculation) => {
    try {
      const response = await customFetch.get('/booking/calculate-installments', {
        params: { ...parameters },
      });
      const responseData = await response.data;
  
      return responseData;
    } catch (error) {
    }
};
