import { useMemo } from "react";
import { BookingType } from "../../views/BookingList/BookingList";
import './BookingCard.css';
import { useTranslation } from "react-i18next";
import { AiOutlineBook } from "react-icons/ai";
import { ICON_SIZE } from "../../views/LoginPage/LoginPage";
import { useNavigate } from "react-router-dom";

const BookingCard = ({
    id, travelCost, travelDescription,
    travelerName, travelStartDate, travelEndDate
}: BookingType) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    const getStartDate = useMemo(() => {
       return new Date(travelStartDate).toLocaleDateString('en-GB').split('/').join('/')
    }, [travelStartDate])

    const getEndDate = useMemo(() => {
        return new Date(travelEndDate).toLocaleDateString('en-GB').split('/').join('/')
    }, [travelEndDate])

    const onCardClick = () => {
        navigate('/booking/details', {state: {id}})
    }

    return (
        <>
            <div className="card">

                <div className="card-content">
                    <div className="card-header row space-between">
                        <AiOutlineBook style={{ ...ICON_SIZE, fill: '#a69891', marginTop: '10px'}}/>
                    </div>
                    <div className="card-body" onClick={() => onCardClick()}>
                        <div className="row space-between">
                            <h4 className="card-title">{travelerName}</h4>
                            <h4 className="card-title">{travelCost} €</h4>
                        </div>
                        <div className="row">
                            <p className="card-description">{travelDescription}</p>
                        </div>
                        <div className="card-footer row space-between">
                            <p className="content-footer"><b>{translate('privateArea.startDate')}: </b>{getStartDate}</p>
                            <p className="content-footer"><b>{translate('privateArea.endDate')}: </b>{getEndDate}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingCard;
