import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
    marginLeft: "8px",
    marginRight: "8px",
};

interface SpinnerProps {
    isLoading: boolean;
    size?: number;
    color?: string
}

const Spinner = ({isLoading, size = 24, color = 'white'}: SpinnerProps) => {
    return (
        <ClipLoader
            color={color}
            loading={isLoading}
            cssOverride={override}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}

export default Spinner;
