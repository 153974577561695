import { customFetch } from "../api/axios";

interface RegistrationData {
  username: string;
  firstName: string;
  lastName: string;
  middleNames?: string | null;
  email: string;
  password: string;
  phoneNumber?: string | null;
}

interface LoginData {
  username: string;
  password: string;
}

interface GoogleSignInData {
  token: string;
}

export const getUserInfo = async (id: string) => {
  try {
    const response = await customFetch.get('/users', {
      params: { id },
    });
    const responseData = await response.data;
    localStorage.setItem('userInfo', JSON.stringify(responseData));

    return responseData;
  } catch (error) {
  }
};

export const registerUser = async (data: RegistrationData): Promise<any> => {
  try {
    const response = await customFetch.post('/auth/registration', data);

    const responseBody = await response.data;
    const accessToken = responseBody.accessToken;
    const refreshToken = responseBody.refreshToken;

    localStorage.setItem("token", responseBody);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    const userInfoFromStorage = localStorage.getItem('userInfo');
    const userInfo = userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null;

    await getUserInfo("");

    return response;
  } catch (error) {
    console.error('There was an error registering the user:', error);
  }
};


export const loginUser = async (data: LoginData): Promise<any> => {
  try {
    const response = await customFetch.post('/auth/login', data);

    const responseBody = response.data;
    const accessToken = responseBody.accessToken;
    const refreshToken = responseBody.refreshToken;

    localStorage.setItem("token", JSON.stringify(responseBody));
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    await getUserInfo("");

    return responseBody;
  } catch (error) {
    console.error('There was an error logging in:', error);
    throw error; // Rethrowing the error so it can be handled by the component
  }
};

export const signInWithGoogle = async (data: GoogleSignInData): Promise<any> => {
  try {
    const response = await customFetch.post('/auth/googlesignin', data);

    const responseBody = response.data;
    const accessToken = responseBody.accessToken;
    const refreshToken = responseBody.refreshToken;

    localStorage.setItem("token", JSON.stringify(responseBody));
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    await getUserInfo("");

    return responseBody;
  } catch (error) {
    console.error('There was an error logging in:', error);
    throw error; // Rethrowing the error so it can be handled by the component
  }
};

export const getTravelInfo = async (id: string) => {
  try {
    const response = await customFetch.get(`/Travel?language=en&id=${id}`);
    const responseBody = await response.data;
    return responseBody;
  } catch (error) {
    throw error; // Rethrowing the error so it can be handled by the component
  }
};
