import React from "react";
import "./index.css";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useTranslation } from 'react-i18next';

const BookingConfirm = () => {
  const { t } = useTranslation()
  const destdata = localStorage.getItem("dest");
  const destination = destdata ? JSON.parse(destdata) : null;

  return (
    <>
      <NavigationBar transparent={false} />
      <div className="booking-page-wrapper"
        style={{ backgroundImage: `url("${destination?.imageName?.localPath ?? '../../assets/images/form-destination.png'}")` }}
        >
        <div className="text-wrapper">
          <span>{t("success.bookingSuccess")}</span>
          <p>
            {t("success.bookingSuccessMessage")}
          </p>
          <p>
            {t("success.bookingSuccessDescription")}
          </p>
        </div>
      </div>
    </>
  );
};

export default BookingConfirm;
