import axios from "axios";
import { toast } from 'react-toastify';

export const API_BASE_URL =
  'https://sambookwebapi-app-20240304.niceriver-aace1d11.westeurope.azurecontainerapps.io/api'
  // "https://sambook-test.yellowmeadow-a269f4a9.westeurope.azurecontainerapps.io/api";

export const customFetch = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-type": "application/json; charset=utf-8",
  },
});

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("accessToken")
}

customFetch.interceptors.request.use(
  async (config) => {
    const token = getTokenFromLocalStorage();
    if (token) {
      config.headers["Authorization"] = ` Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const resp = await refreshToken();
      if (resp && resp.accessToken) {
        const access_token = resp?.accessToken;

        addTokenToLocalStorage(access_token);
        customFetch.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        return customFetch(originalRequest);
      }
    }

    if (error?.response?.status !== 409) {
      toast.error(error.response.data || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
 
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await customFetch.post("/Auth/refresh-token", token);
    
    if (response.data.accessToken) {
      localStorage.setItem("token", JSON.stringify(response.data));
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }

    return response.data;
  
  } catch (e) {
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
};

const addTokenToLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  }
};
