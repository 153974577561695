import React from "react";
import "./MediaPage.css";
// Assume you have a video file and an image file in your assets directory

import ImageSlider from "../../components/ImagesSlider";

type MediaProps = {
  images: {
    id: string; // I assume ID is defined elsewhere
    description: string;
    description2: string;
    localPath: string;
    title: string;
  }[];
  video: string;
};

const MediaPage: React.FC<MediaProps> = ({ images, video }) => {
  function extractVideoId(url: string) {
    let videoId = null;
    if (url.includes("youtu.be/")) {
      videoId = url.split("youtu.be/")[1];
    } else if (url.includes("v=")) {
      videoId = url.split("v=")[1];
    }
    if (videoId) {
      const ampersandPosition = videoId.indexOf("&");
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      return videoId;
    }
    return null;
  }
  const videoId = extractVideoId(video);
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div className="media-page">
      <div className="video-container">
        <iframe src={videoSrc} title="YouTube video" allowFullScreen></iframe>
      </div>
      {/* <ImageSlider images={images} /> */}
    </div>
  );
};

export default MediaPage;
