import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en.json";
import translationIt from "./locales/it.json";
import { I18nextProvider } from 'react-i18next';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
  gtmId: 'GTM-N7LJ8WW6'
}

TagManager.initialize(tagManagerArgs)

const resources = {
  en: {
    translation: translationEn,
  },
  it: {
    translation: translationIt,
  },
};

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  lng: 'it',
  fallbackLng: "en",
  resources,
  detection: {
    order: [
      "querystring",
      "cookie",
      "localStorage",
      "navigator",
      "htmlTag",
      "path",
      "subdomain",
    ],
    caches: ["cookie"],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>

);
reportWebVitals();
