import { useEffect, useState } from "react";
import { getUserBookings } from "../../services/bookingService";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import BookingCard from "../../components/BookingCard/BookingCard";
import './BookingList.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading/Loading";
import Empty from "../../components/Empty/Empty";

export type BookingType = {
    id: string;
    travelCost: number;
    travelDescription: string;
    travelEndDate: string;
    travelStartDate: string;
    travelerName: string;
}

const BookingList = () => {
    const [bookingList, setBookingList] = useState<BookingType[]>([]);
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    const accessToken = localStorage.getItem("accessToken") ?? ''
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onButtonClick = () => {
        navigate('/');
    }

    useEffect(() => {
        if (!accessToken) {
            navigate('/login', {
        state: {
          redirectUrl: '/booking/list'
        }})
        }
    }, [navigate, accessToken])

    useEffect(() => {
        setIsLoading(true);
        getUserBookings().then((response) => {
            if (response) {
                setBookingList(response);
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.log(error);
        })
    }, [])

    return (
        <>
            <NavigationBar alternative={true} isSearchShown={false}/>
            <h2>{translate('privateArea.bookingList')}</h2>
            {bookingList?.length > 0 && <p className="bookingListTooltip">{translate('privateArea.bookingListHint')}</p>}
            <div className="booking-list">
                {
                    isLoading ? 
                        <Loading />
                    :
                        (bookingList?.length <=0 ?
                            <Empty title={translate('shared.emptyList')} buttonText={translate('shared.bookNow')} onButtonClick={() => onButtonClick() } /> :
                            bookingList.map((item: BookingType) => (
                            <div key={item.id} className="booking-item col-4">
                                <BookingCard  {...item}/>
                            </div>
                        )))
                    }
            </div>
        </>
    )
}

export default BookingList;
