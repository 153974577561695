import React from 'react';
import './SearchFilter.css';
import { useTranslation } from 'react-i18next';
// Import icons for each filter if available

// Define the structure of your filters
type FilterState = {
  language: string;
  ageMin: string;
  age: string;
  continent: string;
  country: string;
  travelTheme: string;
  schoolLevel: string;
  sentToGroups: string;
  contentType: string;
  // Add more filter keys as needed
};

type SearchFilterProps = {
  filters: FilterState;
  isAnyFilterSelected: boolean;
  handleFilterChange: (filterKey: keyof FilterState, filterValue: string) => void;
  clearAllFilters: () => void;
};


const SearchFilter: React.FC<SearchFilterProps> = ({
  filters,
  isAnyFilterSelected,
  handleFilterChange,
  clearAllFilters,
}) => {

  const { t } = useTranslation()

  return (
    <div className="main">
    <div className="filter-bar">

      {/* <div className="filter">
        <div className="select-container">
        <span className="icon"></span>
          <select onChange={(e) => handleFilterChange('language', e.target.value)}>
            <option value="">{t("explore.searchFilter.language")}</option>
            <option value="Inglese">{t("explore.searchFilter.languageOptions.firstItem")}</option>
            <option value="Francese">{t("explore.searchFilter.languageOptions.secondItem")}</option>
            <option value="Spagnolo">{t("explore.searchFilter.languageOptions.thirdItem")}</option>
            <option value="Cinese">{t("explore.searchFilter.languageOptions.fourthItem")}</option>
            <option value="Arabo">{t("explore.searchFilter.languageOptions.fifthItem")}</option>
            <option value="Giapponese">{t("explore.searchFilter.languageOptions.sixthItem")}</option>
          </select>
        </div>
      </div> */}

      {/* <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('ageMin', e.target.value)}>
            <option value="">{t("explore.searchFilter.minAge")}</option>
            {Array.from({ length: 17 }, (_, i) => i + 7).map((age) => (              
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
      </div> */}

      <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('age', e.target.value)}>
            <option value="">{t("explore.searchFilter.maxAge")}</option>
            {Array.from({ length: 17 }, (_, i) => i + 7).map((age) => (              
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('continent', e.target.value)}>
            <option value="">{t("explore.searchFilter.continent")}</option>
            <option value="eu">Europe</option>
            <option value="as">Asia</option>
            <option value="au">Australia</option>
          </select>
        </div>
      </div> */}

      </div>
      <div className="filter-bar">

      <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('country', e.target.value)}>
            <option value="">{t("explore.searchFilter.country")}</option>
            <option value="Italia">{t("explore.searchFilter.countryOptions.firstItem")}</option>
            <option value="Inghilterra">{t("explore.searchFilter.countryOptions.secondItem")}</option>
            <option value="Irlanda">{t("explore.searchFilter.countryOptions.thirdItem")}</option>
            <option value="Malta">{t("explore.searchFilter.countryOptions.fourthItem")}</option>
            <option value="Spagna">{t("explore.searchFilter.countryOptions.fifthItem")}</option>
            <option value="Francia">{t("explore.searchFilter.countryOptions.sixthItem")}</option>
            <option value="Cina">{t("explore.searchFilter.countryOptions.seventhItem")}</option>
            <option value="Thailandia">{t("explore.searchFilter.countryOptions.eighthItem")}</option>
            <option value="Dubai">{t("explore.searchFilter.countryOptions.ninthItem")}</option>
            <option value="Giappone">{t("explore.searchFilter.countryOptions.tenthItem")}</option>
            <option value="Grecia">{t("explore.searchFilter.countryOptions.eleventhItem")}</option>
            <option value="Indonesia">{t("explore.searchFilter.countryOptions.twelfthItem")}</option>
            <option value="Stati Uniti">{t("explore.searchFilter.countryOptions.thirteenthItem")}</option>
          </select>
        </div>
      </div>

      <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('travelTheme', e.target.value)}>
            <option value="">{t("explore.searchFilter.travelTheme")}</option>
            <option value="Sport">{t("explore.searchFilter.travelThemeOptions.firstItem")}</option>
            <option value="Lingua Straniera">{t("explore.searchFilter.travelThemeOptions.secondItem")}</option>
            <option value="Scienza e Tecnologia">{t("explore.searchFilter.travelThemeOptions.thirdItem")}</option>
            <option value="Spettacolo">{t("explore.searchFilter.travelThemeOptions.fourthItem")}</option>
            <option value="Arte">{t("explore.searchFilter.travelThemeOptions.fifthItem")}</option>
            <option value="Ambiente">{t("explore.searchFilter.travelThemeOptions.sixthItem")}</option>
            <option value="Misto">{t("explore.searchFilter.travelThemeOptions.seventhItem")}</option>
          </select>
        </div>
      </div>

      {/* <div className="filter">
        <div className="select-container">
          <select onChange={(e) => handleFilterChange('schoolLevel', e.target.value)}>
            <option value="">{t("explore.searchFilter.schoolLevel")}</option>
            <option value="junior">Junior</option>
            <option value="senior">Senior</option>
          </select>
        </div>
      </div> */}

    </div>



    {isAnyFilterSelected &&
      <div className="filter-bar-bottom">
      <hr className="divider" />
      <div className="filter-chips">

      {Object.entries(filters).map(([key, value]) =>
        value && (
          <div key={key} className="filter-chip">
            {`${key}: ${value}`}
            <button onClick={() => handleFilterChange(key as keyof FilterState, '')}>x</button>
          </div>
        )
      )}
      </div>

      <div className="filter action">
        <button onClick={clearAllFilters}>Clear All</button>
      </div>
      </div>
  }



    </div>
  );
};

export default SearchFilter;
