import { useMemo, useState } from "react";
import { OptionProps, TraveInfoProps, TravelProps } from "./interface";
import { getTravelInfo } from "../../services/authService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createBooking } from "../../services/bookingService";
import { ChildFormData, childInitialState, documentInitialState, initialCaregiverData } from "./formSteps/childForm";
import { cleanDate, formatDate, getFormatedDate } from "../../utils/date";
import { CareGiverType } from "./formSteps/careGiver";
import { TravelType } from "../DestinationCard/DestinationCard";
import TagManager from "react-gtm-module";


export const useFormLogic = (currentStep: number, setCurrentStep: (step: number) => void) => {
    const navigate = useNavigate();
    const destdata = localStorage.getItem("dest");
    const destination = destdata ? JSON.parse(destdata) : null;
    const userInfoFromStorage = localStorage.getItem('userInfo');
    const userInfo = userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null;
    const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([]);
    const [bookingError, setBookingError] = useState(false);
    const [bookingLoading, setBookingLoading] = useState(false);
    const [filteredDestinations, setFilteredDestinations] = useState<{from: string}[]>([]);
    const [startValues, setStartValues] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [getDest, setGetDest] = useState('');
    const [travelInfo, setTravelInfo] = useState<TraveInfoProps | null>(null);
    const [totalCost, setTotalCost] = useState<number | null>(null);
    const [optionGroups, setOptionGroups] = useState<OptionProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (destination?.travels?.length > 0) {
          travelSubmit(destination.travels[0]);
          handleDateChange(destination.travels[0].start)
          handleDestChange(destination.travels[0].from)

          const uniqueDestValues = destination.travels
          .map((item: { from: string, cost: number }) => ({ from: item.from, cost: item?.cost }))
          .filter((value: { from: string, cost: number }, index: number, self: { from: string, cost: number }[]) =>
            self.findIndex(t => t.from === value.from) === index
          );

          const uniqueStartValues = destination.travels
          .map((item: { start: string }) => cleanDate(item.start))
          .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

          setFilteredDestinations(uniqueDestValues)
          setStartValues(uniqueStartValues);
        }
    }, []);
    
    const [currentUserFormData, setCurrentUserFormData] = useState({
        id: userInfo?.id,
        name: userInfo?.name,
        surname: userInfo?.surname,
        gender: userInfo?.gender,
        address: userInfo?.address,
        province: userInfo?.province,
        city: userInfo?.city,
        phoneNumber: userInfo?.phoneNumber,
        email: userInfo?.email,
        destination: destination?.name,
        fiscalCode: userInfo?.fiscalCode,
        validUntilDate: getFormatedDate(userInfo?.identityDocuments[0]?.validUntilDate),
        identityDocumentId: userInfo?.identityDocuments[0]?.identityDocumentId,
        children: userInfo?.children ?? []
    });  

    const [childFormData, setChildFormData] = useState<ChildFormData>({
        ...childInitialState, identityDocuments: documentInitialState
    });
    
    const [caregiversData, setCaregiversData] = useState<CareGiverType[]>([initialCaregiverData])

    const travelSubmit = async (travel: TravelProps) => {
        if(travel !== undefined){
            setTravel(travel)
            getTravelInfo(travel.id)
            .then((travelInfo) => {
                setTravelInfo(travelInfo)
                setTotalCost(travelInfo?.cost)
                setOptionGroups(travelInfo.optionGroups)
            })
            .catch((error) => {
                console.error('Error fetching travel information:', error);
            });
        }
    }

    useEffect(() => {
        if (optionGroups && optionGroups[0] && optionGroups[0]?.options) {
            const initialOptions: Record<string, boolean> = {};
            optionGroups[0].options.forEach((option) => {
                initialOptions[option.name] = false;
            });
            setSelectedOptions(initialOptions);
        }
    }, [optionGroups]);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (!accessToken || !userInfo) {
            localStorage.setItem('redirectUrl', '/form')
            navigate('/login', {
                state: {
                    redirectUrl: '/form'
            }});
        }
    }, [navigate, userInfo])

    useEffect(() => {
        if ((!destination && !destination?.cost) || (destination && destination?.travels?.length < 1)) {
            navigate('/');
        }
    }, [destination, navigate])

    useEffect(() => {
        const handleStorage = (event: any) => {
            if (event.key === 'accessToken' && !event.newValue) {
                navigate('/login', {
                    state: {
                        redirectUrl: '/form'
                }});
            }
        }

        window.addEventListener('storage', handleStorage)
        return () => window.removeEventListener('storage', handleStorage)
    }, [navigate])

    useEffect(() => {
        let newTotalCost = travel?.cost + destination?.discountedCost; // Base package cost
        Object.keys(selectedOptions).forEach((key) => {
            if (selectedOptions[key]) {
                const selectedOption = optionGroups?.[0]?.options.find((opt) => opt.name === key);
                if (selectedOption && newTotalCost) {
                    newTotalCost += selectedOption?.cost;
                }
            }
        });

        setTotalCost(newTotalCost);
    }, [selectedOptions, optionGroups]);

    // useEffect(() => {
    //     if (destination?.travels?.length > 0) {
    //       travelSubmit(destination.travels[0]);
    //       handleDateChange(destination.travels[0].start)
    //       handleDestChange(destination.travels[0].from)
    //     }
    //   }, []);

    const handleDateChange = (date: string) => {
        setSelectedDate(date);
        travelSubmit(destination.travels.find((travel: TravelProps) => travel.from === getDest && formatDate(travel.start) === date))
    };

    const handleDestChange = (from: string) => {
        setGetDest(from)
        travelSubmit(destination.travels.find((travel: TravelProps) => travel.from === from && formatDate(travel.start) === selectedDate))
    }
    const [travel, setTravel] = useState(destination?.travels[0] || null)

    const onFirstFormNextClick = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'selectChildren',
                step: '2'
            }
        });
        onNextStepClick();
    }

    const onSecondFormNextClick = (value: ChildFormData, caregivers: CareGiverType[]) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'selectDeparturesAndDates',
                step: '2'
            }
        });
        setChildFormData(value);
        setCaregiversData(caregivers);
        onNextStepClick();
    }

    const onSecondFormPreviousClick = (value: ChildFormData) => {
        setChildFormData(value);
        setCurrentStep(currentStep - 1);
    }

    const onCurrentUserChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentUserFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const formattedDates = (date: string)  => {
        const formateDate = new Date(date);
        return formateDate.toLocaleDateString().replace(/-/g, '/');
    }

    const confirmBooking = async (voucherId?: string, description?: string, departure?: string, cost?:string) => {
        if (childFormData && childFormData.id) {
            try {
                setBookingLoading(true)
                setBookingError(false);
                const success = await createBooking(
                    destination.id,
                    travel.id,
                    selectedOptionIds,
                    childFormData.id,
                    caregiversData,
                    childFormData.startWith,
                    voucherId,
                );

                if (success) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'confirmBookingStep',
                            step: '4',
                            packetName: description,
                            departure: departure,
                            price: cost
                        }
                    });
                    navigate("/bookingConfirm");
                } else {
                    setBookingLoading(false);
                    setBookingError(true);
                }
            } catch (error) {
                setBookingLoading(false);
                setBookingError(true);
                console.error("Error creating booking:", error);
                throw error;
            }
            return true;
        } else {
            setBookingLoading(false);
            setBookingError(true);
            console.error('Network response was not ok');
            return false;
        }
    };

    const onNextStepClick = () => {
        if(currentStep === 2){
            TagManager.dataLayer({
                dataLayer: {
                  event: "start-confirmation",
                  step: "3",
                },
              });
            getDest !== '' && selectedDate !== '' && nextStep();
        } else {
            nextStep()
        }
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, option: {
        id: string;
        name: string;
        cost: number;
        description: string;
    }) => {
        const { name, checked } = event.target;
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [name]: checked
        }));
        setSelectedOptionIds((prevIds) => {
            if (checked) {
                return [...prevIds, option.id];
            } else {
                return prevIds.filter((id) => id !== option.id);
            }
        });
    };

    return {
        handleDateChange,
        handleDestChange,
        formattedDates,
        confirmBooking,
        onNextStepClick,
        handleOptionChange,
        destination,
        travel,
        bookingError,
        bookingLoading,
        filteredDestinations,
        startValues,
        selectedDate,
        totalCost,
        optionGroups,
        selectedOptions,
        currentUserFormData,
        onCurrentUserChange,
        setSelectedDate,
        childFormData,
        onFirstFormNextClick,
        onSecondFormNextClick,
        onSecondFormPreviousClick
    };
};