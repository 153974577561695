import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ResetConfirmPage.css';
// Import your social media icons if you have them
import { resetPasswordConfirm } from '../../services/resetPasswordService';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { ICON_SIZE } from '../LoginPage/LoginPage';

const ResetConfirmPage: React.FC = () => {
    const { token } = useParams();
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [resetError, setResetError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const handleReset = async (event: React.FormEvent) => {
      event.preventDefault();
      if (password === passwordConfirm && token) {
        setPasswordMismatch(false);
        setIsLoading(true);
        setResetError(false)
        try {
            const response = await resetPasswordConfirm({
                token: token,
                password: password
            });
            console.log(response)
            if (response) {
              setResetError(false)
                navigate('/login', {
                    state: {
                        redirectUrl: '/form'
                    }
                });
            }
        } catch (error) {
            console.error('There was an error logging in:', error);
            setResetError(true)
            // Handle login error (e.g., incorrect credentials message)
        }
        setIsLoading(false);
      }
      else {
        setPasswordMismatch(true);
      }
    };

    return (
        <div className="login-page">
            <h2>{t("passwordResetConfirm.title")}</h2>
            <div className="password-input">
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("signup.password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <div className="password-input">
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("signup.passwordConfirm")}
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    required
                />
                <span
                    className="show-password-icon"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? <AiFillEyeInvisible style={{ ...ICON_SIZE, fill: '#ff5d10'}}/> : <AiFillEye style={ICON_SIZE}/>}
                </span>
            </div>
            <button className="login-button" onClick={handleReset}>{t("passwordResetConfirm.buttonText")}</button>
            { passwordMismatch ? <p className="signup-password-error">{t("signup.passwordDontMatch")}</p> : null}
            {resetError ? <p className="error-text">{t("login.error")}</p> : <></>}
            {isLoading ? <p>{t("login.loginLoading")}</p> : <></>}
        </div>
    );
};

export default ResetConfirmPage;
