import React, {useState, useEffect} from 'react';
import './BookingContactForm.css';
import { useTranslation } from 'react-i18next';
import { submitContactForm } from '../../services/contactService';

type BookingContactFormProps = {
  destination: string
};

const BookingContactForm: React.FC<BookingContactFormProps> = ({destination}) => {
    const { t } = useTranslation()
    // Implement the form submission logic
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
         setIsSuccess(false);
     }, [firstName, lastName, email, phoneNumber]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true)
        // Combine name and surname for fullName, adjust as necessary
        const fullName = firstName + " " + lastName
        const formData = {
            fullName, // Assuming fullName combines name and surname
            email,
            phoneNumber,
            destination
        };
        try {
            const response = await submitContactForm(formData);
            setIsLoading(false);
            setIsSuccess(true);
            // Handle success (e.g., showing a success message)
        } catch (error) {
            setIsLoading(false);
            console.error('Error submitting form:', error);
            // Handle error (e.g., showing an error message)
        }
    };

    const handlePrivacyPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsPrivacyPolicyChecked(event.target.checked);
    };

    return (
        <form className="booking-contact-form" onSubmit={handleSubmit}>
            <p className='text-primary'>{t("booking.contactForm.title")}</p>
            <p>{t("booking.contactForm.description")}</p>
            <div className="form-group">
                <input type="text" id="name" placeholder={t("booking.contactForm.name")} required value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input type="text" id="surname" placeholder={t("booking.contactForm.surname")} required value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="email" id="email" placeholder={t("booking.contactForm.email")} required value={email} onChange={e => setEmail(e.target.value)} />
                <input type="tel" id="mobile" placeholder={t("booking.contactForm.mobile")} required value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            </div>
            <input type="text" readOnly className="booking-destination" placeholder={t("booking.contactForm.destination")} required value={destination} />
            { (!isSuccess) &&
              <label className="privacy-policy">
                <input
                  type="checkbox" required
                  checked={isPrivacyPolicyChecked}
                  onChange={handlePrivacyPolicyChange}
                />
                {t("booking.contactForm.privacyPolicyStart")}
                <>&nbsp;</> {/* This adds a space */}
                <a href="https://www.salescuolaviaggi.com/privacy/" target="_blank" rel="noopener noreferrer">{t("booking.contactForm.privacyPolicyLink")}</a>
                <>&nbsp;</> {/* This adds a space */}
                {t("booking.contactForm.privacyPolicyEnd")}
              </label>
            }
            { (!isSuccess) && <button type="submit" disabled={!isPrivacyPolicyChecked || isLoading} className={!isPrivacyPolicyChecked ? "not-checked-button": "booking-contact-form-button"}>{t("booking.contactForm.submit")}</button>}
            {isLoading ? <p>{t("login.loginLoading")}</p> : <></>}
            {isSuccess ? <p>{t("booking.contactForm.success")}</p> : <></>}
        </form>
    );
};

export default BookingContactForm;
