import React from "react";
import "./Footer.css";
import phone from "../../assets/phone.png";
import email from "../../assets/email.png";
import location from "../../assets/location.png";
import id from "../../assets/unique-code-icon.png";
import { useTranslation } from "react-i18next";
import FacebookIcon from "../icons/facebook";
import YoutubeIcon from "../icons/youtube";
import { AiFillContacts, AiFillPhone, AiOutlineContacts, AiOutlineMail, AiOutlinePhone, AiTwotoneContacts } from "react-icons/ai";
import { FaFileContract, FaMapMarker, FaMapPin, FaMarker, FaSearchLocation } from "react-icons/fa";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>{t("footer.travel.title")}</h4>
        <p>
          <a href="https://salescuolaviaggi.com" target="_blank" rel="noopener noreferrer">
            {t("footer.travel.content")}
          </a>
        </p>
      </div>
      <div className="footer-section">
        <h4>{t("footer.pages.title")}</h4>
        <ul>
          <li>
            <a
              href="https://www.salescuolaviaggi.com/viaggi-istruzione/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.pages.secondItem")}
            </a>
          </li>
          <li>
            <a
              href="https://www.salescuolaviaggi.com/bando-estate-inpsieme/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.pages.thirdItem")}
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>{t("footer.contact.title")}</h4>
        <div className="contact-section">
          <AiFillPhone size={18}/>
          <p>0541 1647691</p>
        </div>
        <div className="contact-section">
          <AiOutlineMail size={18}/>
          <p>info@salescuolaviaggi.com</p>
        </div>
        <div className="contact-section">
          <FaMapMarker size={18}/>
          <p>
            Viale Principe Amedeo, <br /> 7 Rimini 47922
          </p>
        </div>
        <div className="contact-section">
          <AiOutlineContacts size={18}/>
          <p> W7YVJK9</p>
        </div>
        <div className="contact-section">
          <p>PI and CF:</p>
          <p> 04518480407</p>
        </div>
      </div>
      <div className="footer-section">
        <h4>{t("footer.medias.title")}</h4>
        <div className="footer-icons-section">
          <a
            href="https://www.youtube.com/@SaleScuolaViaggiOfficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon fill="white"/>
          </a>
          <a
            href="https://www.youtube.com/@SaleScuolaViaggiOfficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon fill="#fff"/>
          </a>
          {/* <TwitterIcon /> */}
          {/* <LinkedinIcon /> */}
          {/* <InstagramIcon /> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
