import { AiOutlineArrowRight } from 'react-icons/ai';
import './Empty.css';

interface EmptyProps {
  title: string;
  buttonText?: string;
  onButtonClick: () => void;
}

const Empty = ({title, buttonText, onButtonClick}: EmptyProps) => {
  return (
    <div className="empty-message row">
      <p className='message'>{title}</p>
      {
        buttonText &&
        <p className='button' onClick={() => onButtonClick()}>
            {buttonText}
            <AiOutlineArrowRight style={{marginBottom: '-4px', strokeWidth: 5, marginLeft: '4px'}}/>
        </p>
      }
    </div>
  );
};

export default Empty;
