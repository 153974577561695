import "./index.css";
import { useState } from "react";
import { MdOutlineKeyboardArrowLeft , MdOutlineKeyboardArrowRight  } from "react-icons/md";

type ImageSliderProps = {
  images: {
    id: string;
    description: string;
    description2: string;
    localPath: string;
    title: string;
  }[]
};

const ImageSlider: React.FC<ImageSliderProps> = ({images}) => {
  const [current, setCurrent] = useState(0);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  return (
    <section className="slider-page">
      {images.length > 1 ? 
        <>
          <MdOutlineKeyboardArrowLeft  className="left-arrow" onClick={prevSlide} />
          <MdOutlineKeyboardArrowRight className="right-arrow" onClick={nextSlide} />
        </> : ""}
      <div className="w-full">
        {images.map((slide, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && (
                <div className="image-wrapper">
                  <img src={slide.localPath} alt="travelImage" className="image" />
                  </div>
              )}
            </div>
          );
        })}

      </div>
    </section>
  );
};

export default ImageSlider;
