// apiService.ts

import { customFetch } from "../api/axios";

type FilterState = {
  language: string | null;
  ageMin: string | null;
  age: string | null;
  continent: string | null;
  country: string | null;
  travelTheme: string | null;
  schoolLevel: string | null;
  sentToGroups: string | null;
  contentType: string | null;
  // Add more filter keys as needed
};


export const fetchFilteredTravelPackages = async (filters: FilterState) => {
  // Construct the query string based on filters
  const queryParams = new URLSearchParams();
  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      queryParams.append(key, value);
    }
  }

  try {
    const response = await customFetch.get(`/TravelPackages?${queryParams}`);

    const data = await response.data;
    return data.travelPackages;

  } catch (error) {
    throw error; // Re-throw the error to be handled by the calling code
  }
};
