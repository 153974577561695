import { useTranslation } from "react-i18next";
import Spinner from "../../../../components/Spinner/spinner";
import React, { useEffect, useState } from "react";
import UserForm from "../../../../components/BookingForm/formSteps/userForm";
import { getFormatedDate } from "../../../../utils/date";
import './edit.css';
import ChildForm, { ChildFormData, initialCaregiverData } from "../../../../components/BookingForm/formSteps/childForm";
import CareGiverForm, { CareGiverType } from "../../../../components/BookingForm/formSteps/careGiver";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { updateBookingCaregivers } from "../../../../services/bookingService";
import { toast } from "react-toastify";
import { BookingType } from "../../BookingDetails";

type EditType = 'user' | 'child' | 'departure' | 'additional' | 'caregivers';

interface EditProps {
    destinationName: string;
    bookingId: string;
    childData: any;
    onChildUpdate: (value: ChildFormData) => void;
    caregivers: CareGiverType[];
    updateCaregivers?: (data: BookingType) => void;
}

const Edit = ({destinationName, childData, onChildUpdate, caregivers, updateCaregivers, bookingId}: EditProps) => {
    const { t: translate } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<EditType>();
    const [caregiversData, setCaregiversData] = useState<CareGiverType[]>(caregivers);
    const [isOptionChanged, setIsOptionChanged] = useState<boolean>(false);
    const [areCaregiversLoading, setAreCaregiversLoading] = useState<boolean>(false);
    const [isOptionLoading, setIsOptionLoading] = useState<boolean>(false);
    const [isFormShown, setIsFormShown] = useState<boolean>(false);
    const userInfoFromStorage = localStorage.getItem('userInfo');
    const userInfo = userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null;

    useEffect(() => {
        setCaregiversData(caregivers);
    }, [caregivers]);

    const [currentUserFormData, setCurrentUserFormData] = useState({
        id: userInfo?.id,
        name: userInfo?.name,
        surname: userInfo?.surname,
        gender: userInfo?.gender,
        address: userInfo?.address,
        province: userInfo?.province,
        city: userInfo?.city,
        phoneNumber: userInfo?.phoneNumber,
        email: userInfo?.email,
        destination: destinationName,
        fiscalCode: userInfo?.fiscalCode,
        validUntilDate: getFormatedDate(userInfo?.identityDocuments[0]?.validUntilDate),
        identityDocumentId: userInfo?.identityDocuments[0]?.identityDocumentId,
        children: userInfo?.children ?? []
    });

    const [childFormData, setChildFormData] = useState<ChildFormData>({
        id: childData?.id,
        name: childData?.name,
        surname: childData?.surname,
        gender: childData?.gender,
        address: childData?.address,
        province: childData?.province,
        city: childData?.city,
        email: childData?.email,
        destination: destinationName,
        fiscalCode: childData?.fiscalCode,
        dateOfBirth: getFormatedDate(childData?.dateOfBirth),
        startWith: childData?.startWith,
        disability: childData?.disability,
        additionalInfo: childData?.additionalInfo,
        allergies: childData?.allergies,
        identityDocuments: {
            validUntilDate: getFormatedDate(childData?.identityDocuments[0]?.validUntilDate),
            identityDocumentId: childData?.identityDocuments[0]?.identityDocumentId,
            identityDocumentType: childData?.identityDocuments[0]?.identityDocumentType,
            releasedBy: childData?.identityDocuments[0]?.releasedBy,            
        }
    });

    useEffect(() => {
        setChildFormData({
            id: childData?.id,
            name: childData?.name,
            surname: childData?.surname,
            gender: childData?.gender,
            address: childData?.address,
            province: childData?.province,
            city: childData?.city,
            email: childData?.email,
            destination: destinationName,
            fiscalCode: childData?.fiscalCode,
            dateOfBirth: getFormatedDate(childData?.dateOfBirth),
            startWith: childData?.startWith,
            disability: childData?.disability,
            additionalInfo: childData?.additionalInfo,
            allergies: childData?.allergies,
            identityDocuments: {
                validUntilDate: getFormatedDate(childData?.identityDocuments?.validUntilDate),
                identityDocumentId: childData?.identityDocuments?.identityDocumentId,
                identityDocumentType: childData?.identityDocuments?.identityDocumentType,
                releasedBy: childData?.identityDocuments?.releasedBy,            
            }
        })
    }, [childData, childData?.id, destinationName]);

    const onCurrentUserChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentUserFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const onChildDataChange = (value: ChildFormData, caregivers: CareGiverType[]) => {
        setChildFormData(value);
        onChildUpdate(value);
        resetFormOptions();
    };

    useEffect(() => {
        let installmentTimeout: string | number | NodeJS.Timeout | undefined;
        if (selectedOption && isOptionChanged) {
            installmentTimeout = setTimeout(
                () => {
                    setIsFormShown(true);
                    setIsOptionLoading(false);
                }
            , 1000)
        }

        return () => {
            clearTimeout(installmentTimeout)
        }
    }, [selectedOption, isOptionChanged])
    
    const onOptionClick = (option: EditType) => {
        setSelectedOption(option);
        setIsOptionLoading(true);
        setIsOptionChanged(true);
    };

    const resetFormOptions = () => {
        setSelectedOption(undefined);
        setIsFormShown(false);
        setIsOptionChanged(false);
    }

    const addNewCaregiver = () => {
        let newArray = [...caregiversData];
        newArray.push(initialCaregiverData)
        setCaregiversData(newArray);
    }

    const deleteCaregiver = (index: number) => {
        setCaregiversData(caregivers => caregivers.filter((item, i) => (i != index)))
    }

    const onCaregiverDataChange = (object: CareGiverType, index: number) => {
        let newArray = [...caregiversData];
        newArray[index] = object; 

        setCaregiversData(newArray);
    }

    const onSaveCaregiverClick = () => {
        setAreCaregiversLoading(true);
            updateBookingCaregivers({
                bookingId,
                caregivers: caregiversData
            }).then(async (response) => {
                console.log(response);
                if (typeof response === 'object') {
                    toast.success(translate('shared.updateMessage'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    resetFormOptions();
                    updateCaregivers && updateCaregivers(response);
                }
                setAreCaregiversLoading(false);
            }).catch((error) => {
                setAreCaregiversLoading(false);
            })
    }

    if (isFormShown && selectedOption === 'user') {
        return (
            <div className="edit-booking-container">
                <div className='edit-booking-header'><h2>{translate("privateArea.edit.user")}</h2></div>
                <UserForm userInfo={currentUserFormData} onCurrentUserChange={onCurrentUserChange}
                    isEditForm={true} onEditClick={() => resetFormOptions()} />
            </div>
        );
    }

    if (isFormShown && selectedOption === 'child') {
        return (
            <div className="edit-booking-container">
                <div className='edit-booking-header'><h2>{translate("privateArea.edit.child")}</h2></div>
                <ChildForm userInfo={currentUserFormData} onNextClick={(item, data) => onChildDataChange(item, data)}
                    childInfo={childFormData} isEditForm={true}
                    onEditClick={() => resetFormOptions()} />
            </div>
        );
    }

    if (isFormShown && selectedOption === 'caregivers' && childFormData?.disability) {
        return (
            <div className="edit-booking-container">
                <div className='edit-booking-header'><h2>{translate("privateArea.edit.caregivers")}</h2></div>
                    {caregiversData && caregiversData.map((item, index) =>
                        <React.Fragment key={index} >
                            <div className="col-12 care-givers-container row">
                                <div className="col-12 px-8 pt-12 row space-between">
                                        <h4 className="identity-card">{translate(`form.secondStepForm.caregiver${index + 1}`)}</h4>
                                    <AiOutlineDelete size={20} style={{fill: '#ff5d10'}} onClick={() => deleteCaregiver(index)}/>
                                </div>
                                <CareGiverForm index={index} data={item} onCaregiverDataChange={(object) => onCaregiverDataChange(object, index)} />
                            </div>
                        </React.Fragment>
                    )}
                    {
                        caregiversData && caregiversData.length < 3 &&
                        <>
                            <div className="col-12 pt-24 pb-6 row justify-start">
                                <button type="button" onClick={() => addNewCaregiver()}
                                    className="button primary-button background-blue">
                                    <FaPlus strokeWidth={2} style={{marginBottom: '-2px', marginRight: '4px'}}/>
                                    {translate("form.secondStepForm.addCaregivers")}
                                </button>
                            </div>
                            <div className="col-12 pb-6">
                                <label className="input-label">{translate("form.secondStepForm.caregiversNote")}</label>
                            </div>
                        </>
                    }

                    <div className="row">
                        <div className="col-6">
                            <button type="button" style={{width: '97%'}} className="button primary-button mb-8"
                                onClick={() => resetFormOptions()}>{translate("form.previous")}</button>
                        </div>
                        <div className="col-6">
                        <button type="button" style={{ width: '97%' }} className="button primary-button mb-8"
                            onClick={() => onSaveCaregiverClick()} disabled={areCaregiversLoading}>
                                <Spinner size={10} isLoading={areCaregiversLoading} />{translate('form.save')}</button>
                        </div>
                    </div>
            </div>
        );
    }

    return (
        <div className="edit-booking-container">
            <div className='edit-booking-header'><h2>{translate("privateArea.bookingDetails.editBookingInfo")}</h2></div>
            <div className="edit-options">
                <div
                    className={`edit-option ${selectedOption === 'user' ? 'selected' : ''}`}
                    onClick={() => onOptionClick('user')}
                    >
                    <Spinner size={10} isLoading={isOptionLoading && selectedOption === 'user'}/>
                    {translate('privateArea.edit.user')}
                </div>
                <div
                    className={`edit-option ${selectedOption === 'child' ? 'selected' : ''}`}
                    onClick={() => onOptionClick('child')}
                    >
                    <Spinner size={10} isLoading={isOptionLoading && selectedOption === 'child'}/>
                    {translate('privateArea.edit.child')}
                </div>
                {Boolean(childData?.disability) && 
                    <div
                    className={`edit-option ${selectedOption === 'caregivers' ? 'selected' : ''}`}
                    onClick={() => onOptionClick('caregivers')}
                    >
                        <Spinner size={10} isLoading={isOptionLoading && selectedOption === 'caregivers'}/>
                        {translate('privateArea.edit.caregivers')}
                    </div>
                }
                {/* 
                <div
                    className={`edit-option ${selectedOption === 'additional' ? 'selected' : ''}`}
                    onClick={() => onOptionClick('additional')}
                    >
                    <Spinner size={10} isLoading={isOptionLoading && selectedOption === 'additional'}/>
                    {translate('privateArea.edit.additional')}
                </div> */}
            </div>
        </div>
    )
}

export default Edit;
