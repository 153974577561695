import { useTranslation } from 'react-i18next';
import './TextLabel.css';
import { isStringDefined } from '../../utils/string';

interface TextLabelProps {
    value?: string | number;
    label?: string;
}

const TextLabel = ({value, label}: TextLabelProps) => {
    const { t: translate } = useTranslation();

    return (
        <div className='col-6'>
            <p><b>{translate(label ?? '')}: </b> {isStringDefined(value?.toString()) ?? '-'}</p>
		</div>
    )
}

export default TextLabel;
