import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ImageSlider from "../../components/ImagesSlider";
import "../BookingPage/LandingPageAcademy.css";
import { CgCalendarDates } from "react-icons/cg";
import { FaArrowDown, FaArrowUp, FaDoorOpen } from "react-icons/fa";
import { FaLanguage } from "react-icons/fa6";
import { FaPerson } from "react-icons/fa6";
import { IoMdAirplane } from "react-icons/io";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import TagManager from "react-gtm-module";

type TravelInfoType = {
  start: string;
  end: string;
  departures: Departure[];
};

type Departure = {
  cost: string;
  subscription: boolean;
  from: string;
};

type AcademyLandingProps = {
  title?: string;
  description?: string;
  img?: string;
  price?: string | number;
  dates?: TravelInfoType[];
  ageMin?: string | number;
  language?: string;
  roomDescription?: string;
  fromLocations?: string[];
  images: {
    id: string;
    description: string;
    description2: string;
    localPath: string;
    title: string;
  }[];
};

const LandingPageAcademy: React.FC<AcademyLandingProps> = ({
  title,
  description,
  img,
  price,
  images,
  dates,
  ageMin,
  language,
  roomDescription,
}) => {
  const { t } = useTranslation();
  const [selectedDeparture, setSelectedDeparture] = useState<string>("0");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [carouselImages, setCarouselImages] = useState([
    {
      id: "mainImage",
      description: "",
      description2: "",
      localPath: img ?? "",
      title: img ?? "",
    },
    ...images,
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleDateSelect = (index: number) => {
    setSelectedIndex(index);
  };

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setCarouselImages([
      {
        id: "mainImage",
        description: "",
        description2: "",
        localPath: img ?? "",
        title: img ?? "",
      },
      ...images,
    ]);
  }, [images, img]);

  const handleBookingClick = () => {
    if (token === null) {
      navigate("/login", {
        state: {
          redirectUrl: "/form",
        },
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "beginCheckout",
          step: "1",
        },
      });
      navigate("/form");
    }
  };

  return (
    <div className="landing-academy-page">
      <div className="images">
        <ImageSlider images={carouselImages} />
      </div>

      <div className="booking-info-wrapper">
        <h1 className="description-wrapper">{description}</h1>
        <div className="destination-price row space-between">
          <h2 className="title">{title}</h2>
          {dates && dates?.length > 0 ? (
            <span className="title price">{`€${
              price +
              dates[selectedIndex]?.departures[Number(selectedDeparture)]?.cost
            }`}</span>
          ) : (
            <span className="title price">{price}€</span>
          )}
        </div>
        {dates && dates?.length > 0 ? (
          <div className="general-info">
            <span className="icons">
              <CgCalendarDates />
            </span>
            <div className="row">
              {dates?.map((item, index) => (
                <div
                  key={index}
                  className={`col-6 mb-8 ${
                    item.departures[0]?.subscription ? "" : "line-through"
                  }`}
                >
                  <input
                    type="radio"
                    name="dateRange"
                    value={index}
                    checked={selectedIndex === index}
                    onChange={() => handleDateSelect(index)}
                  />
                  <label>
                    {item?.start} - {item?.end}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div className="general-info">
          <span className="icons">
            <FaPerson />
          </span>
          <p>+{ageMin}</p>
        </div>
        <div className="general-info">
          <span className="icons">
            <FaLanguage />
          </span>
          <p className={expanded ? "expanded" : "collapsed"}>{language}</p>
          <p onClick={toggleExpanded}>
            {expanded ? (
              <FaArrowUp className="arrow-up" />
            ) : (
              <>
                <span className="read-more-icon">
                  <FaArrowDown className="arrow-down" />
                </span>
              </>
            )}
          </p>
        </div>

        <div className="general-info">
          <span className="icons">
            <FaDoorOpen />
          </span>
          <p>{roomDescription}</p>
        </div>

        {dates && dates?.length > 0 ? (
          <>
            <div className="select-div">
              <TextField
                select
                value={selectedDeparture}
                label="View departures"
                onChange={(event) => setSelectedDeparture(event.target.value)}
                fullWidth
                size="medium"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <IoMdAirplane
                      style={{ color: "#ff5d10", paddingRight: "2px" }}
                    />
                  ),
                }}
              >
                {dates &&
                  dates[selectedIndex]?.departures.map((departure, index) => (
                    <MenuItem key={index} value={index}>
                      {departure?.from}
                      {departure?.cost ? ` + ${departure?.cost}€` : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </>
        ) : null}

        {dates && dates?.length > 0 ? (
          <button
            id="begin-checkout"
            className="pre-signup-btn"
            onClick={handleBookingClick}
          >
            {t("booking.preSignUp")}
          </button>
        ) : (
          <button
            className="button danger-button sold-out-button"
            value={"Sold out"}
          >
            Sold out
          </button>
        )}
      </div>
    </div>
  );
};

export default LandingPageAcademy;
