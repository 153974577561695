import jsPDF from "jspdf";
import { resetFontSize } from "../pdf";

export const generateAutodichiarazionePDF = () => {
    // WIDTH: 210 mm
    // HEIGHT: 297 mm
  var doc = new jsPDF();

  const img = new Image();
  img.onload = function () {
    doc.setPage(1);
    //@ts-ignore
    doc.addImage(this, 25, 10, 33, 10, "", "FAST");
    doc.setPage(2);
    //@ts-ignore
    doc.addImage(this, 25, 10, 33, 10, "", "FAST");
    doc.setPage(3);
    //@ts-ignore
    doc.addImage(this, 25, 10, 33, 10, "", "FAST");
    doc.setPage(2);
    doc.html(
      `<div style="width: 160px; font-family: 'Arial', sans-serif; color:#555555">
    <p style="font-size: 4px;"><b style="white-space: pre-line">AUTOCERTIFICAZIONE POSSESSO REQUISITI ORGANIZZATORE VIAGGI E SOGGIORNI</b></p>
    <p style="font-size: 4px;">(rilasciata ai sensi del D.P.R. n. 445 del 28.12.2000 e ss.mm.ii.)</p>
    <p style="font-size: 4px;">Il/la sottoscritto/a Anna Weronika Malinowska</p>
    <p style="font-size: 4px;">nato/a il 06/04/1983</p>
    <p style="font-size: 4px;">a Pyskowice (PL), residente e/o domiciliato in Rimini</p>
    <p style="font-size: 4px;">(RN), via Abetti 15B c.f.MLNNWR83D46Z127L nella sua qualità di legale rappresentante della società Sale
    Scuola Viaggi di Saled srl con sede in monte cerignone via scalette 2 p.iva/c.f.02814460412
    sotto la propria responsabilità e consapevole di quanto disposto dall'art. 76 del D.P.R. 28.12.2000, n.445 e
    delle conseguenze di natura penale in caso di dichiarazioni mendaci</p>
    <p style="font-size: 4px;">DICHIARA</p>
    <p style="font-size: 4px;margin-top:7px">
    1. La società Sale Scuola Viaggi di Saled srl è in possesso di autorizzazione allo svolgimento dell’attività di
    “organizzatore di viaggi e soggiorni” ai sensi di Legge quadro sul turismo legge 29 marzo 2000 n. 135 e
    le relative deleghe alle Regioni, dalla Direttiva n. 90/314/CEE nelle parti in cui non abrogata dalla
    Direttiva UE 2015/2302 concernente i viaggi, le vacanze ed i circuiti “tutto compreso” per come
    recepita dalla legislazione nazionale attuali D.lgs. 79/2011 (Codice del turismo) per come modificato
    dal D.lgs. 62/2018;
    </p>
    <p style="font-size: 4px;">
    2. la società Sale Scuola Viaggi di Saled Srl è iscritta presso il Registro delle Imprese tenuto dalla CCIAA di
    Pesaro Urbino numero iscrizione RN - 435028 (o corrispondenti albi, registri, elenchi di stato, se
    stranieri) per le specifiche attività di organizzazione di soggiorni, viaggi e/o vacanze. In caso di
    richiedente straniero, l’operatore economico deve essere in possesso di autorizzazione o qualificazione
    equivalente rispetto alle disposizioni del Paese di appartenenza;
    </p>
    <p style="font-size: 4px;">
    3. la società Saled srl possiede apposita polizza assicurativa RC (numero 1/39383/319/190048388 con la
    compagnia UnipolSai ) a garanzia dell’esatto adempimento degli obblighi assunti verso i clienti con il
    contratto di viaggio e in relazione al costo complessivo dei servizi offerti, in osservanza delle
    disposizioni di cui all’art.47, comma 1 del D.lgs. 79/2011, per come modificato dal D.lgs. 62/2018. Per
    gli operatori stranieri fa fede la normativa del Paese d’appartenenza;    
    </p>
    <p style="font-size: 4px;">
    4. la società Sale Scuola Viaggi di Saled srl è in possesso di idonea copertura per il rischio insolvenza o
    fallimento (sottoscritta con Secure Travel), in ottemperanza alle disposizioni di cui ai commi 2 e 3
    dell’art. 47 del Codice del Turismo per come modificato dal D.lgs. 62/2018, laddove applicabile. Per gli
    operatori stranieri fa fede la normativa del Paese d’appartenenza;
    </p>
    <p style="font-size: 4px;">
    5. ove previsto dalla normativa locale, essere in regola con gli obblighi di versamento degli appositi
    depositi cauzionali previsti per l’esercizio della professione (o polizza fideiussoria</p><div/>`,
      {
        callback: function (doc) {
          doc.html(
            `<div style="width: 160px; font-family: 'Arial', sans-serif; color:#555555">
            <p style="font-size: 4px;">
            alternativa), in osservanza delle disposizioni previste in materia dalla convenzione internazionale relativa ai contratti di viaggio di cui alla Legge 1084/1977, nonché dal Decreto Legislativo 206/2005 e dal Decreto
legislativo 79/2011 riguardo ai circuiti «tutto compreso»;
            </p>
            <p style="font-size: 4px;">
            6. per i pacchetti Estate INPSieme Estero, la società Sale Scuola Viaggi di Saled srl possiede certificazione
            UNI EN 14804 certificato numero 3912367 del 26/10/2023 per l’organizzazione di soggiorni studio
            all’estero, rilasciata da soggetti accreditati ai sensi delle norme europee;
            </p>
            <p style="font-size: 4px;">
            7. per i pacchetti Estate INPSieme Italia, la società Sale Scuola Viaggi di Saled srl possiede certificazione
            UNI EN ISO 9001/2015 certificato numero N. 3912367 del 26/10/2023 rilasciata da soggetti accreditati
            ai sensi delle norme europee;
            </p>
            <p style="font-size: 4px;">
            8. la società Sale Scuola Viaggi di Saled srl possiede, regolarità ai sensi dell'art. 37 del D.L. n. 78 del 31
            maggio 2010, convertito con legge 30 luglio 2010 n. 122 e relative disposizioni di attuazione di cui al
            D.M. del 14 dicembre 2010, in tema di antiriciclaggio;
            </p>
            <p style="font-size: 4px;">
            9. non essere azienda o società sottoposta a sequestro o confisca ai sensi dell'articolo 12-sexies del
            decreto-legge 8 giugno 1992, n. 306, convertito, con modificazioni, dalla legge 7 agosto 1992, n. 356, o
            della legge 31 maggio 1965, n. 575, affidata ad un custode o amministratore giudiziario o finanziario;
            oppure essere azienda o società sottoposta a sequestro o confisca ai sensi dell'articolo 12- sexies del
            decreto-legge 8 giugno 1992, n. 306, convertito, con modificazioni, dalla legge 7 agosto 1992, n. 356, o
            della legge 31 maggio 1965, n. 575, ed affidata ad un custode o amministratore giudiziario o
            finanziario, indicando numero e anno del provvedimento di sequestro o di confisca, giudice emittente,
            natura del provvedimento, nominativo del custode o dell’amministratore giudiziario o finanziario;            
            </p>
            <p style="font-size: 4px;">
            10. non essere in stato di fallimento, di liquidazione coatta, di concordato preventivo, e non avere
            pendenti nei propri confronti procedimenti volti alla dichiarazione di tali stati;
            </p>
            <p style="font-size: 4px;">
            11. assenza di procedimenti pendenti per l'applicazione di una delle misure di prevenzione di cui
            all'articolo 3 della L. 1423 del 27 dicembre 1956, o di una delle cause ostative previste dall'art. 10 della
            L. 575 del 31 maggio 1965, nei confronti del titolare dell'impresa o del direttore tecnico, se si tratta di
            impresa individuale; nei confronti dei soci o del direttore tecnico, se si tratta di società in nome
            collettivo; nei confronti dei soci accomandatari o del direttore tecnico, se si tratta di società in
            accomandita semplice; nei confronti dei propri amministratori muniti di potere di rappresentanza, del
            proprio direttore tecnico, del socio unico (persona fisica) ovvero del socio di maggioranza (persona
            fisica) per le società con meno di quattro soci, se si tratta di altro tipo di società;
            </p>
            <p style="font-size: 4px;">
            12.  la società Sale Scuola Viaggi di Saled srl è in possesso della regolarità contributiva (DURC online in
                corso di validità per tutte le sedi operative nazionali del fornitore del servizio);
            </p>
            <p style="font-size: 4px;">
            13. la società Sale Scuola Viaggi di Saled srl è in possesso di regolarità rispetto alla normativa antimafia;
            </p>
            <p style="font-size: 4px;">
            14.  non essere stato dichiarato gravemente inadempiente negli ultimi due anni in rapporti precedenti
            contrattuali con INPS;
            </p>
              </div>
              `,
            {
                callback: function (doc) {
                    doc.html(
                      `<div style="width: 160px; font-family: 'Arial', sans-serif; color:#555555">
                      <p style="font-size: 4px;">
            15.  possesso regolarità ai sensi dell'art. 37 del D.L. n. 78 del 31 maggio 2010, convertito con legge 30
            luglio 2010 n. 122 e relative disposizioni di attuazione di cui al D.M. del 14 dicembre 2010, in tema di
            antiriciclaggio;
              </p>
        <p style="font-size: 4px;">
        16.  assenza di sentenze di condanna passate in giudicato, oppure decreto penale di condanna divenuto
        irrevocabile, oppure sentenza di applicazione della pena su richiesta, ai sensi dell'art. 444 del codice di
        procedura penale per tutti i reati di cui all’art.80, comma 1 del D.Lgs n.50/2016 nei confronti del
        titolare dell'impresa o del direttore tecnico, se si tratta di impresa individuale; nei confronti dei soci o
        del direttore tecnico, se si tratta di società in nome collettivo; nei confronti dei soci accomandatari o
        del direttore tecnico, se si tratta di società in accomandita semplice; nei confronti dei propri
        amministratori muniti di potere di rappresentanza, del proprio direttore tecnico, del socio unico
        (persona fisica) ovvero del socio di maggioranza (persona fisica) per le società con meno di quattro
        soci, se si tratta di altro tipo di società;
        </p>
        <p style="font-size: 4px;">
        17. insussistenza di condanne a carico del titolare dell'impresa o del direttore tecnico, se si tratta di
        impresa individuale; a carico dei soci o del direttore tecnico, se si tratta di società in nome collettivo; a
        carico dei soci accomandatari o del direttore tecnico, se si tratta di società in accomandita semplice; a
        carico dei propri amministratori muniti di potere di rappresentanza del proprio direttore tecnico, del
        socio unico (persona fisica) e del socio di maggioranza (persona fisica) in caso di società con meno di
        quattro soci, se si tratta di altro tipo di società, per le quali i medesimi abbiano beneficiato della non
        menzione;
        </p>
        <p style="font-size: 4px;">
        18. non aver commesso violazioni gravi, debitamente accertate, delle norme in materia di sicurezza e di
        ogni altro obbligo derivante dai rapporti di lavoro, risultanti dai dati in possesso dell'Osservatorio;
        </p>
        <p style="font-size: 4px;">
        19. non aver commesso gravi violazioni, definitivamente accertate, rispetto agli obblighi relativi al
        pagamento delle imposte e tasse, secondo la legislazione italiana o quella dello Stato in cui l'impresa è
        stabilita;
                      </p>
        <p style="font-size: 4px;">
        20. non aver commesso violazioni gravi, definitivamente accertate, delle norme in materia di contributi
        previdenziali e assistenziali, secondo la legislazione italiana o quella dello Stato in cui l'impresa è
        stabilita.
        </p>
        <p style="font-size: 4px;">
        Letto, confermato e sottoscritto il 11/04/2024
        </p>
        <p style="font-size: 4px;text-align: right">
        Il Dichiarante
        </p>
        <p style="font-size: 4px;text-align: right">
        Anna Weronika Malinowska
        </p>
                      </div>`,
                    {
                      callback: function (doc) {
                        doc.setPage(3);
                        const imgTimbro = new Image();
                        imgTimbro.onload = function() {
                            //@ts-ignore
                            doc.addImage(this, 135, 235, 51, 17, "", "FAST"); 
                            const imgTimbro = new Image();
                            doc.setPage(4);
                            imgTimbro.onload = function() {
                                //@ts-ignore
                                doc.addImage(this, 60, 50, 104, 137, "", "FAST"); 
                                doc.save("autochiarazione_sale_scuola_viaggi");
                            };
                            imgTimbro.crossOrigin = "";
                            imgTimbro.src = "/idlawyer.png";
                        };
                        imgTimbro.crossOrigin = "";
                        imgTimbro.src = "/saledtimbro.png";
                        },
                        x: 25,
                        y: (297*2)+25,
                        windowWidth: 100,
                    }
                );
              },
              x: 25,
              y: 297+25,
              windowWidth: 100,
            }
          );
        },
        x: 25,
        y: 25,
        windowWidth: 100,
      }
    );
  };
  // TODO: check if we need to put 'anonymous'
  img.crossOrigin = ""; // for demo as we are at different origin than image
  img.src = "/saleLogo.png";

  function addFooter(page: number) {
    doc.setFontSize(8)
    doc.text(`Pag. ${page} di 3`, 180, 290);

    doc.text(
      "Sede Legale Via Scalette 2 Monte Cerignone",
      105,
      280,{align:"center"}
    );
    doc.text(
      "Sede Operativa Viale Principe Amedeo, 7 Rimini 47922",
      105,
      285,{align:"center"}
    );
    doc.text(
      "Tel. 0541 1647691 PI e CF: .02814460412 - ISO 9001 e ISO 14034",
      105,
      290,{align:"center"}
    );
    resetFontSize(doc)
  }

  addFooter(1);
  doc.addPage();

  addFooter(2);
  doc.addPage();

  doc.setFontSize(11);

  addFooter(3);
  doc.addPage();
};
