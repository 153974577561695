import React, { useState, useEffect } from 'react';
import DestinationCard from '../../components/DestinationCard/DestinationCard';
import './TravelDestinations.css';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Empty from '../../components/Empty/Empty';
import { useTranslation } from 'react-i18next';

export interface TravelPackage {
  id: string;
  name: string;
  destination: string;
  city: string;
  country?: string;
  continent?: string;
  languages: string[];
  travelTheme?: string;
  schoolLevel?: string;
  start: string;
  end: string;
  subscriptionsStart?: string;
  subscriptionsEnd?: string;
  targetAgeMin?: number;
  targetAge?: number;
  claimText?: string;
  claimTimeLenght?: string;
  description: string;
  imageName: {
    localPath: string;
  };
  totalCost: number;
  discountedCost: number;
  travels: []
}


interface TravelDestinationsProps {
  travelPackages: TravelPackage[];
  isLoading: boolean;
}

const TravelDestinations: React.FC<TravelDestinationsProps> = ({ travelPackages, isLoading }) => {
  const location = useLocation();
  const { t: translate } = useTranslation();

  const queryParams = new URLSearchParams(location.search);
  const dest = queryParams.get("destination");

  const [filteredPackages, setFilteredPackages] = useState<TravelPackage[]>([]);

  useEffect(() => {
    let filtered = travelPackages;
    if (dest === "it") {
      filtered = travelPackages.filter(dest => dest.country === "Italia");
    } else if (dest === "other") {
      filtered = travelPackages.filter(dest => dest.country !== "Italia");
    } else if (dest === "fr") {
      filtered = travelPackages.filter(dest => dest.country === "Francia");
    }else{
      filtered = travelPackages;
    }
    setFilteredPackages(filtered);

  }, [dest, travelPackages]);

  if (isLoading) {
    return <Loading />;
  }

    return (
      <div className="travel-destinations row">
        {filteredPackages?.length > 0?  filteredPackages.map((dest, index) => {
          return <DestinationCard
              key={dest.id}
              dest={dest}
              image={dest.imageName && dest.imageName.localPath}
            />
        }) : 
          <Empty title={translate('Nessun pacchetto disponibile')} onButtonClick={() => null} />
        }
      </div>
    );
};

export default TravelDestinations;
