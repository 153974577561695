import React, { useState, useEffect, useRef } from "react";
import "./ExplorePage.css";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import InformationBlock from "../../components/InformationBlock/InformationBlock";
import Footer from "../../components/Footer/Footer";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import TravelDestinations from "../TravelDestinations";
import AccordionSection from "../AccordionSection/AccordionSection";
import { fetchFilteredTravelPackages } from "../../services/travelService";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type FilterState = {
  language: string;
  ageMin: string;
  age: string;
  continent: string;
  country: string;
  travelTheme: string;
  schoolLevel: string;
  sentToGroups: string;
  contentType: string;
};

const initialFilterState: FilterState = {
  language: "",
  ageMin: "",
  age: "",
  continent: "",
  country: "",
  travelTheme: "",
  schoolLevel: "",
  sentToGroups: "",
  contentType: "",
};

export interface TravelPackage {
  id: string;
  name: string;
  destination: string;
  country?: string;
  continent?: string;
  languages: string[];
  city: string;
  travelTheme?: string;
  schoolLevel?: string;
  start: string;
  end: string;
  subscriptionsStart?: string;
  subscriptionsEnd?: string;
  targetAgeMin?: number;
  targetAge?: number;
  claimText?: string;
  claimTimeLenght?: string;
  description: string;
  imageName: {
    localPath: string;
  };
  totalCost: number;
  discountedCost: number;
  travels: [];
}

const ExplorePage: React.FC = () => {
  const { t } = useTranslation();
  const [travelPackages, setTravelPackages] = useState<TravelPackage[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilterState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const informationBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchTravelPackages = async () => {
      setLoading(true);
      try {
        const data = await fetchFilteredTravelPackages(initialFilterState);
        setTravelPackages(data);
      } catch (error) {
        throw error;
      }
      setLoading(false);
    };

    fetchTravelPackages();
  }, []);

  const isAnyFilterSelected = Object.values(filters).some(
    (value) => value !== ""
  );

  const handleFilterChange = async (
    filterKey: keyof FilterState,
    filterValue: string
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: filterValue,
    }));

    if (filterKey === "country") {
      const newFilters = {
        ...filters,
        [filterKey]: filterValue,
      };

      queryParams.set("destination", filterValue);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, "", newUrl);

      // Update the filters only if they are different from the current state
      if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
        setFilters(newFilters);
      }
    }

    try {
      const filteredTravelPackages = await fetchFilteredTravelPackages({
        ...filters,
        [filterKey]: filterValue,
      });
      // Handle the response, update state, etc.
      setTravelPackages(filteredTravelPackages);
    } catch (error) {
      throw error;
    }
  };

  const clearAllFilters = async () => {
    queryParams.get("destination");
    queryParams.set("destination", "");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
    setFilters(initialFilterState);
    try {
      const travelPackages = await fetchFilteredTravelPackages(
        initialFilterState
      );
      // Update the state with the fetched data
      setTravelPackages(travelPackages);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <NavigationBar transparent={false} isSearchShown={false} />
      <div className="hero-section">
        <div className="overlay">
          <div className="overlay-content">
            <h1>{t("explore.title")}</h1>
            <p>{t("explore.description")}</p>
            <button
              className="learn-more-btn"
              onClick={() => {
                informationBlockRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {t("explore.learnMore")}
            </button>
          </div>
        </div>
        <div className="search-filter-container">
          <SearchFilter
            filters={filters}
            isAnyFilterSelected={isAnyFilterSelected}
            handleFilterChange={handleFilterChange}
            clearAllFilters={clearAllFilters}
          />
        </div>
      </div>
      <div className="explore-page">
        <div ref={informationBlockRef}>
          <TravelDestinations
            travelPackages={travelPackages}
            isLoading={loading}
          />
        </div>
        <InformationBlock />
        <AccordionSection />
      </div>
      <Footer />
    </>
  );
};

export default ExplorePage;
