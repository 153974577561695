import { useTranslation } from "react-i18next"
import "./index.css"

type NavigationBarProps = {
    switchColor?: boolean
}

const LanguageSwitcher: React.FC<NavigationBarProps> = ({switchColor}) => {
    const { i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
      };
    return(
        <div className="language_switcher">
            <select
                value={i18n.language}
                onChange={(e) => changeLanguage(e.target.value)}
                style={{color: switchColor ? "grey" : "white" }}
            >
                <option value="en">🇺🇸</option>
                <option value="it">🇮🇹</option>
            </select>
        </div>
    )
}
export default LanguageSwitcher;