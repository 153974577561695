import React, { useState } from "react";
import "./FeatureCard.css";
import { useTranslation } from "react-i18next";

type FeatureCardProps = {
  icon: string;
  title: string;
  text?: string | string[];
};

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, text }) => {
  return (
  <>
      <div className="feature-card w-full">
        <div className="feature-icon px-16">
          <img src={icon} alt="feature-icon" />
        </div>
        <div className="feature-text p-16">
          <h3 className="feature-title">{title}</h3>
          <p className="feature-description">
            {Array.isArray(text) ? 
              <div className="row justify-start">
                {
                  text?.map((item) => (
                    <div className="col-4">
                      <p>{item}</p>
                    </div>
                  ))
                }
              </div>
              :
              <>
                {text}
              </>
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default FeatureCard;
