import type jsPDF from "jspdf";

export const font = "helvetica";
export const orangeBG = "#F9CAAD";
export const orangeText = "#F17E37";
export const black = "#000000";

export function resetFontSize(doc: jsPDF) {
  doc.setFont(font, "normal");
  doc.setFontSize(8);
}
export function resetFillColor(doc: jsPDF) {
  doc.setFillColor(black);
}
export function setFillOrange(doc: jsPDF) {
  doc.setFillColor(orangeBG);
}
export function setFontSizeTitle(doc: jsPDF) {
  doc.setFont(font, "bold");
  doc.setFontSize(10);
}
export function setFontSizeKeyTable(doc: jsPDF) {
  doc.setFont(font, "bold");
}

export function addTableHeader(doc: jsPDF, text: string, y: number, x= 10,length = 190) {
  const yCoordinate = y;
  setFillOrange(doc);
  setFontSizeTitle(doc);

  doc.rect(x, yCoordinate, length, 5, "F");
  //@ts-ignore
  doc.cell(x, y, length, 5, " ", undefined);
  doc.setFontSize(9);
  doc.text(text, 105, y + 4, { align: "center" });
  resetFontSize(doc);
  resetFillColor(doc);
}

export function addTableCell(
  doc: jsPDF,
  textKey: string,
  textValue: string,
  x: number,
  y: number,
  widthKey: number,
  widthValue: number
) {
  setFontSizeKeyTable(doc);
  //@ts-ignore
  doc.cell(x, y, widthKey, 5, " ", undefined);
  doc.text(textKey, x + 1, y + 4);
  resetFontSize(doc);
  //@ts-ignore
  doc.cell(x + widthKey, y, widthValue, 5, " ", undefined);
  doc.text(textValue, x + widthKey + 1, y + 4);
}

export function addSimpleCell(
  doc: jsPDF,
  text: string,
  x: number,
  y: number,
  width: number
) {
  //@ts-ignore
  doc.cell(x, y, width, 5, " ", undefined);
  doc.text(text, x + 1, y + 4);
}
