import React, { useState } from 'react';
import Stepper from '../../components/Stepper';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import './FormPage.css';
import { useTranslation } from 'react-i18next';
import { RenderForm } from '../../components/BookingForm';

const FormPage: React.FC = () => {
    const { t } = useTranslation()
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        t("form.steps.firstStep"),
        t("form.steps.secondStep"),
        t("form.steps.fourthStep"),
        t("form.steps.fifthStep")];

    return (
        <div>
            <NavigationBar transparent={true} alternative={true}/>
            <div className="form-page">
                <Stepper steps={steps} currentStep={currentStep} />
                {RenderForm(currentStep, setCurrentStep)}
            </div>
        </div>
    );
};

export default FormPage;
