import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BookingStatus from '../../components/BookingStatus/BookingStatus';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import Loading from '../../components/Loading/Loading';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import ModalComponent from '../../components/Shared/Modal/Modal';
import TextLabel from '../../components/TextLabel/TextLabel';
import {
  BookingStatusType, getBookingById, getInvoiceNumber, getInvoicePDF,
  getSignedContract, updateBookingStatus, uploadContract, uploadInvoice
} from '../../services/bookingService';
import { fetchFilteredTravelPackages } from '../../services/travelService';
import { getUserById } from '../../services/userService';
import { generateAutodichiarazionePDF } from '../../utils/pdfGenerators/autodichiarazionePdf';
import { generateContractPDF } from '../../utils/pdfGenerators/contractPdf';
import { generateInvoicePDF } from '../../utils/pdfGenerators/invoicePdf';
import { generatePaymentPDF } from '../../utils/pdfGenerators/paymentInformationPdf';
import { toLowerCase } from '../../utils/string';
import './BookingDetails.css';
import PaymentBody, { PaymentMethod } from './components/payment/payment';
import Edit from './components/edit/edit';
import {
  ChildFormData, childInitialState, documentInitialState, initialCaregiverData
} from '../../components/BookingForm/formSteps/childForm';
import { CareGiverType } from '../../components/BookingForm/formSteps/careGiver';
import TagManager from 'react-gtm-module';

type BookedUser = {
	id: string;
	name: string;
	surname: string;
  email: string;
  disability: 0 | 1;
  country: string;
	identityDocument: {
		databaseId: string;
		identityDocumentType: string;
		identityDocumentId: string;
		validUntilDate: string;
		releasedDate: string;
		releasedBy: string;
		nameOnDocument: string;
    surnameOnDocument: string;
	}
}

export type BookingType = {
    id: string;
    caregivers: CareGiverType[];
    bookedBy: BookedUser;
    traveler?: BookedUser;
    startWith: string;
    selectedDocument: string;
    paymentMethod: PaymentMethod;
    signedContractUri?: string;
    invoiceUrl?: string;
    payment1Amount: number;
    payment1Date: string;
    payment1Deadline: string;
    payment2Amount: number;
    payment2Date: string;
    payment2Deadline: string;
    payment3Amount: number;
    payment3Date: string;
    payment3Deadline: string;
    payment4Amount: number;
    payment4Date: string;
    payment4Deadline: string;

    travel: {
        id: string;
        name: string;
        from: string;
        to: string;
        start: string;
        end: string;
        cost: number;
        travelersNum: number;
    },
    travelPackageId: string;
    travelPackageName: string;
    state: string;
    voucherId: string;
    voucherDiscount: string;
    totalCost: number;
    selectedOptions: []
}

const getFormattedDate = (date: string | undefined) => {
  if (!date) return null;

  return new Date(date).toLocaleDateString('en-GB').split('/').join('/')
}

const BookingDetails = () => {
  const {t: translate} = useTranslation();
  const { state: params } = useLocation();
  const navigate = useNavigate();
  const [bookingInfo, setBookingInfo] = useState<BookingType>();
  const [travelerInfo, setTravelerInfo] = useState<ChildFormData>({
    ...childInitialState, identityDocuments: documentInitialState
  });

  const [travelPackageInfo, setTravelPackageInfo] = useState<BookedUser>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<{ fileName?: string; file?: Blob }>({
    fileName: undefined,
    file: undefined
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [bookingActionInfo, setBookingActionInfo] = useState<BookingStatusType>('ApprovedByUser');

  const downloadContractPdf = async (bInfo: BookingType) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "downloaded contract",
        pageView: "confirm booking details"
      },
    });
    if(travelPackageInfo) {
      const userInfo = await getUserById(bInfo.bookedBy.id)
      const bookInfo = await getBookingById(bInfo.id)
      generateContractPDF({bookingInfo:bookInfo, userInfo, travelerInfo, travelPackageInfo: travelPackageInfo})
    }
  }

  const downloadUploadedContract = () => {
    getSignedContract({
        signedContractUri: bookingInfo?.signedContractUri ?? '',
        accessToken
    });
  }

  const downloadInvoicePDF = async(bInfo?: BookingType) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "downloaded invoice",
        pageView: "confirm booking details"
      },
    });
    if(travelPackageInfo && bInfo && travelerInfo) {
        let invoiceUrl = bInfo.invoiceUrl;
        const userInfo = await getUserById(bInfo.bookedBy.id)
            const bookInfo = await getBookingById(bInfo.id)
            const invoiceNumber = await getInvoiceNumber(bInfo.id)
            const pdf = await generateInvoicePDF({
            bookingInfo: bookInfo, userInfo,
            travelerInfo, travelPackageInfo: travelPackageInfo, invoiceNumber
            })

            invoiceUrl = await uploadInvoice({data:pdf, bookingId:bInfo.id, token: accessToken})
            setBookingInfo({...bookingInfo as BookingType, invoiceUrl})
        // }
        getInvoicePDF({
            invoiceUrl: invoiceUrl ?? '',
            accessToken
        })
    }
  }

  const downloadPaymentPdf = async(bInfo: BookingType) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "downloaded dettagli di pagamento",
        pageView: "confirm booking details"
      },
    });
    if(travelPackageInfo) {
      const bookInfo = await getBookingById(bInfo.id)
      generatePaymentPDF({bookingInfo:bookInfo,travelPackageInfo: travelPackageInfo})
    }
  }

  const downloadAutodichiarazione = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "downloaded autodichiarazione",
        pageView: "confirm booking details"
      },
    });
    generateAutodichiarazionePDF()
  }

  const accessToken = localStorage.getItem("accessToken") ?? ''

  const getTravelerInfo = (id: string) => {
    getUserById(id)
      .then((response) => {
        if (response && response.id) {
          const index = response?.identityDocuments &&
            response.identityDocuments.length ? response.identityDocuments.length : 0;

          const newIdentityDocument = index ?
            response.identityDocuments[index- 1] : documentInitialState;

          setTravelerInfo({...response, identityDocuments: newIdentityDocument});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getTravelPackageInfo = (id: string) => {
    const initialFilterState: any = {
        searchstring: id,
    };

    fetchFilteredTravelPackages(initialFilterState).then((response) => {
      if (response) {
        setTravelPackageInfo(response[0]);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (!accessToken) {
      navigate('/login', {
        state: {
          redirectUrl: '/booking/details',
          params: {
            id: params?.id
          }
      }})
    }
  }, [navigate, accessToken])

  useEffect(() => {
    if (!params?.id) {
      navigate('/booking/list')
    } else {
      setIsLoading(true)
      getBookingById(params?.id).then((response) => {
        if (response) {
          setBookingInfo(response)
          if (response.traveler && response.traveler.id) {
            getTravelerInfo(response.traveler.id);
          }

          if (response.travelPackageId) {
            getTravelPackageInfo(response.travelPackageId)
          }
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        navigate('/booking/list')
      })
    }
  }, [navigate, params])

  const getInstalmentNumber = useMemo(() => {
    if (bookingInfo?.payment4Amount) {
      return 4
    }

    if (bookingInfo?.payment3Amount) {
      return 3
    }

    if (bookingInfo?.payment2Amount) {
      return 2
    }

    if (bookingInfo?.payment1Amount) {
      return 1
    }

    return 0;
  }, [bookingInfo?.payment2Amount, bookingInfo?.payment3Amount,
      bookingInfo?.payment4Amount, bookingInfo?.payment1Amount])

  const onUpdateBookingStatus = (value: BookingStatusType) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "statusChanged",
        status: value
      },
    });
      setIsUploadLoading(true);
      updateBookingStatus({
        bookingId: params?.id,
        bookingState: value,
        caregivers: bookingInfo?.caregivers ?? []
      }).then(async(response) => {
        if (typeof response === 'object') {
          setIsModalOpen(false);
          setBookingInfo(response);
          setIsPaymentModalOpen(false);
          toast.success(translate('shared.updateMessage'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (value === 'ApprovedByUser') {
            await downloadContractPdf(response);
            await downloadPaymentPdf(response);
          }
        }
        setIsUploadLoading(false);
      }).catch((error) => {
        setIsModalOpen(false);
        setIsUploadLoading(false);
      })
  }

  const onConfirmBooking = () => {
     TagManager.dataLayer({
      dataLayer: {
        event: "started confirmation process",
        action: "selecting payment methods"
      },
    });
    setIsPaymentModalOpen(true);
  }

  const onConfirm = () => {
    onUpdateBookingStatus(bookingActionInfo)
  }

  const onCancelBooking = () => {
    setBookingActionInfo('Deleted')
    setIsModalOpen(true)
  }

  const onEditBookingClick = () => {
    setIsEditModalOpen(true);
  }

  const onPaymentClick = () => {
    setIsPaymentModalOpen(true);
  }

  const getIsCanceled = useMemo(() => {
    const stateToLowerCase = toLowerCase(bookingInfo?.state ?? '');
    const badStatuses = ['failed', 'deleted', 'refused'];

    return badStatuses.includes(stateToLowerCase ?? 'failed');
  }, [bookingInfo?.state]);

  const getIsConfirmed = useMemo(() => {
    const stateToLowerCase = toLowerCase(bookingInfo?.state ?? '');
    const completedStatuses = ['approvedbyuser', 'completed', 'approvedbyagency', 'paid'];

    return completedStatuses.includes(stateToLowerCase ?? 'failed');
  }, [bookingInfo?.state]);

  // add if user pays user cant confirm
  const getCanConfirm = useMemo(() => {
    return !(getIsCanceled || getIsConfirmed);

  }, [getIsCanceled, getIsConfirmed]);

  const onUploadContract = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files && event?.target?.files[0]) {
      setFileInfo({
        file: event?.target?.files[0],
        fileName: event?.target?.files[0].name
      });
    }
  }

  const onSaveUploadedContract = () => {
    const formData = new FormData();
    formData.append('file', fileInfo.file ?? '');
    formData.append('bookingId', bookingInfo?.id ?? '');

    setIsUploadLoading(true);
    uploadContract({
      formData,
      token: accessToken
    })
      .then((response: any) => {
        console.log(typeof response)
        if (response) {
          TagManager.dataLayer({
            dataLayer: {
              event: "downloaded contract",
              pageView: "confirm booking details"
            },
          });
          setBookingInfo({...bookingInfo as BookingType, signedContractUri: response})
          toast.success(translate('shared.updateMessage'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setFileInfo({
          file: undefined,
          fileName: undefined
        })
        setIsUploadLoading(false);
      }).catch((error) => {
        setIsUploadLoading(false);
        console.log(error);
      })
  }

  const updateCaregivers = (data: BookingType) => {
    setBookingInfo(data);
  }

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirm}
        message='shared.confirmMessage'
      />
      <ModalComponent
        onClose={() => setIsPaymentModalOpen(false)}
        isOpen={isPaymentModalOpen}
      >
        <PaymentBody totalCost={bookingInfo?.totalCost} installmentNumber={getInstalmentNumber}
          bookingId={params?.id} paymentMethod={bookingInfo?.paymentMethod}
          closeModal={() => setIsPaymentModalOpen(false)} country={travelPackageInfo?.country ?? undefined}
          downloadPayment={(bInfo) => downloadPaymentPdf(bInfo)}
          downloadContract={(bInfo) => downloadContractPdf(bInfo)}
          updateBookingInfo={() => getIsConfirmed ? null : onUpdateBookingStatus('ApprovedByUser')} isPaymentModalOpen={isPaymentModalOpen}
          startDate={getFormattedDate(bookingInfo?.travel?.start) ?? undefined} isConfirmed={getIsConfirmed} />
      </ModalComponent>

      <ModalComponent
        onClose={() => setIsEditModalOpen(false)}
        isOpen={isEditModalOpen}
      >
        <Edit destinationName={bookingInfo?.travelPackageName ?? ''} bookingId={bookingInfo?.id ?? ''}
          childData={travelerInfo} caregivers={bookingInfo?.caregivers ?? [initialCaregiverData]}
          onChildUpdate={(value) => setTravelerInfo(value)} updateCaregivers={(data) => updateCaregivers(data)}
        />
      </ModalComponent>

      <NavigationBar alternative={true} isSearchShown={false}/>
      {
        isLoading ? <Loading /> :
        (
          <div className="booking-details">
            <div className='row-reverse'>
                <div className='col-6'>
                  <BookingStatus onCancelBooking={onCancelBooking}
                    onConfirmBooking={() => onConfirmBooking()}
                    isCanceled={getIsCanceled} isConfirmed={getIsConfirmed}/>
              </div>
              <div className='col-6 row'>
                <h2>{translate('privateArea.bookingDetails.title')}</h2>
              </div>
            </div>
            <div className="booking-info">
              <div className="block-info row">
                <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.travelerInfo')}</h4></div>
                  <TextLabel value={`${travelerInfo?.name} ${travelerInfo?.surname}`}
                    label={'privateArea.bookingDetails.name'}/>
                  <TextLabel value={travelerInfo?.email} label={'privateArea.bookingDetails.email'}/>
                  <TextLabel value={travelerInfo?.identityDocuments?.identityDocumentId} label={'privateArea.bookingDetails.documentId'}/>
                  <TextLabel value={travelerInfo?.identityDocuments?.validUntilDate} label={'privateArea.bookingDetails.expiryDate'}/>
                  <TextLabel value={travelerInfo?.identityDocuments?.releasedBy} label={'privateArea.bookingDetails.issueContry'}/>
                </div>
                <div className="block-info row">
                  <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.travelInfo')}</h4></div>
                  <TextLabel value={bookingInfo?.travelPackageName} label={'privateArea.bookingDetails.name'}/>
                  <TextLabel value={getFormattedDate(bookingInfo?.travel?.start) ?? undefined} label={'privateArea.bookingDetails.startDate'}/>
                  <TextLabel value={bookingInfo?.travel?.from} label={'privateArea.bookingDetails.from'}/>
                  <TextLabel value={bookingInfo?.travel?.to} label={'privateArea.bookingDetails.to'}/>
                  <TextLabel value={bookingInfo?.totalCost} label={'privateArea.bookingDetails.totalCost'}/>
                </div>
                {
                  getIsConfirmed &&
                  <div className="block-info row">
                    <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.confirmationInfo')}</h4></div>
                    <div className='column'>
                      <ol className='info-list'>
                        <li className='list-item'>
                          <p>{translate('privateArea.bookingDetails.information.stepOne')}</p>
                        </li>
                        <li className='list-item'>
                          <p>{translate('privateArea.bookingDetails.information.stepTwo')}</p>
                        </li>
                        <li className='list-item'>
                          <p>{translate('privateArea.bookingDetails.information.stepThree')}</p>
                        </li>
                        <li className='list-item'>
                          <p>{translate('privateArea.bookingDetails.information.stepFour')}</p>
                        </li>
                      </ol>
                    </div>                      
                  </div>
                }
                {
                  travelerInfo?.disability?
                  <div className="block-info row">
                    <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.disableInfo')}</h4></div>
                  </div> : null
                }
                { getIsConfirmed && bookingInfo?.payment1Amount &&
                  <>
                    <div className="block-info row">
                      <div className='col-6'>
                          <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.myDocuments')}</h4></div>
                          <button className='col-12 d-flex download-button' onClick={() => downloadContractPdf(bookingInfo)}>
                            <AiOutlineDownload size={18} style={{marginRight: '4px', fill: '#ff5d10', strokeWidth: 5}}/>
                            {translate('privateArea.payment.contract')}
                          </button>
                          {
                            bookingInfo?.paymentMethod &&
                              <button className='col-12 d-flex download-button' onClick={() => downloadPaymentPdf(bookingInfo)}>
                                <AiOutlineDownload size={18} style={{marginRight: '4px', fill: '#ff5d10', strokeWidth: 5}}/>
                                  {translate('privateArea.payment.paymentContract')}
                              </button>
                          }
                          {
                            bookingInfo?.payment1Date &&
                            <>
                              <button className='col-12 d-flex download-button' onClick={() => downloadInvoicePDF(bookingInfo)}>
                                <AiOutlineDownload size={18} style={{marginRight: '4px', fill: '#ff5d10', strokeWidth: 5}}/>
                                  {translate('privateArea.payment.paymentInvoice')}
                              </button>
                            </>
                          }
                          
                          <button className='col-12 d-flex download-button' onClick={() => downloadAutodichiarazione()}>
                              <AiOutlineDownload size={18} style={{marginRight: '4px', fill: '#ff5d10', strokeWidth: 5}}/>
                              {translate('privateArea.payment.selfDeclaration')}
                          </button>
                    </div>
                    {
                      bookingInfo?.signedContractUri && 
                      <div className='col-6'>
                            <div className='col-12 d-flex'><h4>{translate('privateArea.bookingDetails.myUploadedDocuments')}</h4></div>
                            <button className='col-12 d-flex download-button' onClick={() => downloadUploadedContract()}>
                              <AiOutlineDownload size={18} style={{marginRight: '4px', fill: '#ff5d10', strokeWidth: 5}}/>
                              {translate('privateArea.payment.signedContract')}
                            </button>
                        </div>
                    }
                    </div>

                    <div className='block-info row'>
                      <label htmlFor='file-upload' className="custom-file-upload">
                        {translate('privateArea.bookingDetails.upload')}
                        <AiOutlineUpload size={18} style={{marginLeft: '4px', marginBottom: '-2px', fill: '#ff5d10', strokeWidth: 5}}/>
                        <input id='file-upload' type="file"
                          accept="image/png, image/jpeg, .pdf"
                          onChange={(event) => onUploadContract(event)} />
                      </label>
                      <p className='uploaded-filename'>{fileInfo.fileName}</p>
                      <button disabled={!fileInfo.fileName || isUploadLoading} className="button primary-button" onClick={() => onSaveUploadedContract()}>
                            {translate('privateArea.bookingDetails.uploadButton')}
                      </button>
                    </div>
                  </>
                }
                <div className={"row flex-end " + (getCanConfirm || (getIsConfirmed && bookingInfo?.paymentMethod !== 'banktransfer') ? 'booking-footer' : '')}>
                  {
                    getCanConfirm &&
                      <button className="button danger-button" onClick={() => onUpdateBookingStatus('Deleted')}>
                        {translate('privateArea.bookingDetails.delete')}
                      </button>
                  }
                  {
                    (getCanConfirm || getIsConfirmed) && 
                    <button className="button primary-button" onClick={() => onEditBookingClick()}>
                          {translate('privateArea.bookingDetails.editBookingInfo')}
                    </button>
                  }
                  {
                    getIsConfirmed && 
                      <button className="button success-button" onClick={() => onPaymentClick()}>
                        {translate('privateArea.bookingDetails.pay')}
                      </button>
                  }
                </div>
            </div>
          </div>
        )
      }
    </>
)}

export default BookingDetails;
