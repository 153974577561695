import { customFetch } from "../api/axios";

type HeidyPayProps = {
    bookingId: string;
}

export const proceedPayWithHeidi = async ({bookingId}: HeidyPayProps) => {
    try {
      const response = await customFetch.get(`/heidipay/get-payment-url?bookingId=${bookingId}`);
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
};
