import { ReactElement } from 'react';
import './Modal.css';

interface ConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    children?: ReactElement;
}

const ModalComponent = ({ isOpen, children, onClose }: ConfirmModalProps) => {
    if (!children) {
        return null;
    }

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
             <div className="modal-content" onClick={(event) => event.stopPropagation()}>
                {
                    children &&
                    <div className="modal-body">
                        {children}
                    </div>
                }
            </div>
        </div>
    );
};

export default ModalComponent;
