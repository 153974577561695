import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./views/LoginPage";
import SignupPage from "./views/SignupPage";
import ExplorePage from "./views/ExplorePage";
import BookingPage from "./views/BookingPage";
import FormPage from "./views/FormPage";
import BookingConfirm from "./views/BookingConfirm";
import ResetPage from "./views/ResetPage";
import ResetConfirmPage from "./views/ResetConfirmPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookingList from "./views/BookingList/BookingList";
import BookingDetails from "./views/BookingDetails/BookingDetails";
import PaymentStatus from "./views/PaymentStatus/PaymentStatus";
import usePageTracking from "./hooks/pageTracking";
import PageTracker from "./hooks/pageTracking";

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <PageTracker>
          <Routes>
            <Route path="/" element={<ExplorePage />} />
            <Route path="/booking/:destName" element={<BookingPage />} />
            <Route path="/booking/list" element={<BookingList />} />
            <Route path="/booking/details" element={<BookingDetails />} />
            <Route path="/bookingConfirm" element={<BookingConfirm />} />
            <Route path="/booking/success" element={<BookingConfirm />} />
            {/* <Route path="/payment/:status" element={<PaymentStatus />} /> */}
            <Route path="/form" element={<FormPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ResetPage />} />
            <Route
              path="/forgot-password-confirm/:token"
              element={<ResetConfirmPage />}
            />

            {/* Redirect to home if url deosn't exist */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </PageTracker>
      </Router>
    </div>
  );
};

export default App;
