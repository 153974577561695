import { isStringDefined } from "./string";

const getFormatedDate = (value: string | undefined = '') => {
    if (!isStringDefined(value)) {
        return '';
    }
    return value.split('/').reverse().join('-');
};

const getDatabaseFormatedDate = (value: string) => {
    if (!isStringDefined(value)) {
        return '';
    }

    return value.split('-').reverse().join('/');
}

// This clean dates on format 2024-08-22T00:00:00Z instead of 2024-08-22T00:00:00
// Delete this function once we have always the same structure for dates
const cleanDate = (date: string) => date.split('Z')[0];

// Transform this 2024-06-25T in this 25/06/2024
function formatDate(dateString: string) {
    const dateParts = dateString.split('T')[0].split('-'); // Dividi la stringa e prendi solo la parte della data
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Riorganizza i componenti nel nuovo formato
}

export {
    getFormatedDate,
    getDatabaseFormatedDate,
    cleanDate,
    formatDate
}
