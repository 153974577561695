import jsPDF from "jspdf";
import {
    addSimpleCell,
    addTableHeader,
    black,
    font,
    orangeText,
    resetFillColor,
    resetFontSize,
    setFillOrange
} from "../pdf";
import { formatDate } from "../date";

export const generatePaymentPDF = ({bookingInfo, travelPackageInfo} : {bookingInfo: any, travelPackageInfo:any}) => {
  // WIDTH: 210 mm
  // HEIGHT: 297 mm
  var doc = new jsPDF();

  const img = new Image();
  img.onload = function () {
    //@ts-ignore
    doc.addImage(this, 10, 5, 33, 10, "", "FAST");
  
    let pdfName = bookingInfo.contractNumber ?? '';
    doc.save(`Pagamento-Info${pdfName}`);
  };

  // TODO: check if we need to put 'anonymous'
  img.crossOrigin = ""; // for demo as we are at different origin than image
  img.src = "/saleLogo.png";

  function addFooter(page: number) {
    doc.text(`Pag. ${page} di 1`, 180, 290);

    doc.text(
      "Sale Scuola Viaggi di Saled srl–s.o. Viale Principe Amedeo 7. Rimini(RN) – Tel:0541/1741120, Fax:0541/792982",
      30,
      285
    );
    doc.text(
      "P.IVA e C/F.02814460412– inps@salescuolaviaggi.com, www.salescuolaviaggi.com",
      45,
      290
    );
  }

  function addHeader() {
    doc.setFont(font, "bold");
    doc.setFontSize(13);
    doc.text("CONTRATTO DI VIAGGIO", 200, 10, {
      align: "right",
    });
    doc.setTextColor(orangeText);
    doc.text("INPSieme - Sale Scuola Viaggi", 200, 18, {
      align: "right",
    });
    doc.setTextColor(black);
    resetFontSize(doc);
  }
  function addNumberContract(contractNumber: number) {
    doc.setFontSize(11);
    doc.setFont(font, "bold");
    doc.text(`Numero pratica: ${contractNumber}`, 10, 27);
    resetFontSize(doc);
  }

  function orangeCell(
    doc: jsPDF,
    text: string,
    x: number,
    y: number,
    width: number
  ) {
    setFillOrange(doc);
    doc.rect(x, y, width, 5, "F");
    addSimpleCell(doc, text, x, y, width);
    resetFillColor(doc);
  }

  resetFontSize(doc);

  addHeader();
  addNumberContract(bookingInfo.contractNumber);

let extraCosts = 0;

bookingInfo.selectedOptions.forEach((item:any) => {
    if (item.options && item.options.length > 0) {
        const optionsCost = item.options.reduce((acc:number, currentOption:any) => acc + currentOption.cost, 0);
        extraCosts += optionsCost;
    }
});

  // servizi richiesti

  orangeCell(doc, "Servizi richiesti:", 20, 40, 100);
  addSimpleCell(doc, "IL PACCHETTO ESTATE INPSieme 2023", 20, 45, 75);
  orangeCell(doc, `€${travelPackageInfo.discountedCost + bookingInfo.travel.cost}`, 95, 45, 25);
  addSimpleCell(doc, "Costo Accompagnatori/Assistenza", 20, 50, 75);
  orangeCell(doc, "", 95, 50, 25);
  addSimpleCell(doc, "Servizi facoltativi extra", 20, 55, 75);
  orangeCell(doc, `€${extraCosts}`, 95, 55, 25);
  orangeCell(doc, "Prezzo complessivo", 20, 60, 75);
  orangeCell(doc, `€${bookingInfo.totalCost}`, 95, 60, 25);
  orangeCell(doc, "", 20, 65, 75);
  orangeCell(doc, "", 95, 65, 25);
  
  // rate
  
  orangeCell(doc, `Prima rata: ${bookingInfo.payment1Amount ? `€${bookingInfo.payment1Amount}` : ''}`, 20, 75, 40);
  orangeCell(doc, `Da versare entro il: ${bookingInfo.payment1Deadline ? formatDate(bookingInfo.payment1Deadline) : ''}`, 60, 75, 60);
  orangeCell(doc, `Seconda rata: ${bookingInfo.payment2Amount ? `€${bookingInfo.payment2Amount}` : ''}`, 20, 80, 40);
  orangeCell(doc, `Da versare entro il: ${bookingInfo.payment2Deadline ? formatDate(bookingInfo.payment2Deadline) : ''}`, 60, 80, 60);
  orangeCell(doc, `Terza rata: ${bookingInfo.payment3Amount ? `€${bookingInfo.payment3Amount}` : ''}`, 20, 85, 40);
  orangeCell(doc, `Da versare entro il: ${bookingInfo.payment3Deadline ? formatDate(bookingInfo.payment3Deadline) : ''}`, 60, 85, 60);
  orangeCell(doc, `Quarta rata: ${bookingInfo.payment4Amount ? `€${bookingInfo.payment4Amount}` : ''}`, 20, 90, 40);
  orangeCell(doc, `Da versare entro il: ${bookingInfo.payment4Deadline ? formatDate(bookingInfo.payment4Deadline) : ''}`, 60, 90, 60);

  
  if(bookingInfo.paymentMethod === "heidipay"){
    setFillOrange(doc);
    doc.rect(125, 75, 60, 20, "F");
    //@ts-ignore
    doc.cell(125, 75, 60, 20, " ", undefined);
        doc.text([`Si prega di seguire il piano`,`di pagamento di Pagolight`],155, 85, {align:"center"});
        resetFillColor(doc);
    } else {
    setFillOrange(doc);
    doc.rect(125, 75, 60, 20, "F");
    //@ts-ignore
    doc.cell(125, 75, 60, 20, " ", undefined);
        doc.text(["Si prega di indicare la corretta","causale nei bonifici."],155, 85, {align:"center"});
        resetFillColor(doc);
    
      addTableHeader(
        doc,
        "COORDINATE BANCARIE PER BONIFICO:",
        100,
        20,
        165
      );
      //@ts-ignore
      doc.cell(20, 105, 165, 25, ' ', undefined)
    
        doc.text([`COORDINATE BANCARIE PER BONIFICO:`,
        `Intestato a: Saled Srl`,
        `Banca: Banca Malatestiana`,
        `Codice IBAN: IT63N0709024213027010218799`,
        `CAUSALE: Nella causale si prega di specificare nome e cognome del`,
        `partecipante, numero pratica,`],23, 110)
    }



  addTableHeader(
    doc,
    "DOCUMENTI NECESSARI",
    130,
    20,
    165
  );

  //@ts-ignore
  doc.cell(20, 135, 165, 30, ' ', undefined)

    doc.text([`1. Carta d’identità (passaporto in caso di viaggio Extra EU) in corso di validità di:`,
    `- Partecipante al viaggio`,
    `- Genitore titolare del diritto/richiedente il contributo`,
    `- Eventuali accompagnatori`,
    `2. Tessera sanitaria di:`,
    `- Partecipante/i al viaggio`],23, 140)

  addFooter(1);
};
